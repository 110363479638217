.dailog_content h2 {
  font-family: Gotham Medium;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-top: 10px;
}

.dailog_content p {
  font-family: Gotham light;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  outline: none;
  /* or 150% */

  color: #444444;
}
.question_row .col-lg-9 {
  padding-left: 0;
}
.css-1t4vnk2-MuiDialogContent-root {
  outline: none;
}
