@font-face {
  font-family: Gotham light;
  src: url('./assets/font/Gotham-Book.otf') format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: Gotham regular;
  src: url('./assets/font/Gotham-Book.otf') format('opentype');
  font-weight: 325;
}
@font-face {
  font-family: Gotham Medium;
  src: url('./assets/font/Gotham-Medium.otf') format('opentype');
  font-weight: 350;
}
@font-face {
  font-family: Gotham semi Bold;
  src: url('./assets/font/Gotham-Bold.otf') format('opentype');
  font-weight: 400;
}
@font-face {
  font-family: Gotham Bold;
  src: url('./assets/font/Gotham-Bold.otf') format('opentype');
  font-weight: 500;
}
@font-face {
  font-family: Imprint MT Shadow;
  src: url('./assets/font/imprint-mt-shadow/ImprintMTShadow.ttf') format('truetype');
  font-weight: 400;
}

body {
  scroll-behavior: smooth !important;
  margin: 0;
  font-family: Gotham light;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  padding-right: 0px !important;
  overflow: overlay;
}

:root {
  --primary_head_color: #000000;
  --secondary_head_color: #1a1a1a;
  --white_text_Color: #ffffff;
  --content_color: #111111;
  --para_banner: #4f4f4f;
  --button_color: #69cef5;
  --primary_para_color: #828282;
  --list_color: #333333;
  --background_light_grey: #f5f5f7;
  --border-grey-color: #d6d6d6;
  --black_background_color: #000000;
  --blue_button: #062136;
  --border-color: #000000;
  --white_background: #ffffff;
  --slide_content_color: #7b7b7b;
}

@media screen and (min-width: 768px) {
  body {
    /* overflow-y: auto !important; */
  }
}
