.drop_slider {
  display: none;
}
.just_droped .col-lg-3 {
  position: relative;
}
.just_droped img[alt='Bookmark'] {
  cursor: pointer;
  position: absolute;
  right: 18px;
  top: 3%;
  width: 28px;
  height: 23px;
}
.just_droped a:hover {
  text-decoration: none;
}
.just_droped .star_icon {
  position: absolute;
  width: 20px;
  top: 5%;
  left: 35px;
  cursor: pointer;
}
.no_justDropped {
  margin: 30px 0;
  width: 100%;
  text-align: center;
}
.sold_cart {
  opacity: 0.7;
  pointer-events: none;
}

.sold_cart .centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: Red;
  font-weight: bolder;
  border: 3px solid red;
  border-left: none;
  border-right: none;
  font-size: xx-large;
}
.card_hidden {
  display: none;
}
@media screen and (max-width: 767px) {
  .just_drop_mob_hide {
    display: none;
  }
  .drop_slider {
    display: block;
  }
  .no_justDropped {
    display: none !important;
  }
}
