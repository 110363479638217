.appraisel {
  margin: 15px 0;
}
.appraisel_top {
  align-items: center;
  align-items: baseline;
  display: flex;
  margin-bottom: 0;
}
.drag_zone .newDragDrop .dropzone {
  min-height: 200px;
}
.additinal_box .MuiAutocomplete-endAdornment {
  display: none;
}
.preview_img img {
  width: 49px;
  height: 50px;
  margin-bottom: 10px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}
.usa_input .sendOTP {
  position: absolute;
  height: 34px;
  top: 0;
  right: -150px;
  width: auto;
  border-radius: 25px;
  margin-top: 0;
  cursor: pointer;
  z-index: 1;
  font-size: 12px;
  padding: 10px 20px;
  color: #fff !important;
  pointer-events: auto !important;
  width: auto;
}
.usa_input .inputOTP {
  margin-top: 10px;
  width: 100%;
  text-indent: 10px !important;
}
.usa_input .verifyOTP {
  position: relative;
  width: 140px;
  border-radius: 25px;
}
.preview_img .cross_img {
  position: relative;
  top: -27px;
  right: 8px;
  width: 15px;
  height: 15px;
  background-color: #ccc;
  border-radius: 100px;
  padding: 2px;
  cursor: pointer;
}
.appraisel_top a,
.appraisel_top h6 {
  font-family: Gotham regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 325;
  line-height: 14px;
  color: #000000;
}
.appraisel_top img {
  margin: 0 10.5px;
  position: relative;
  top: 2px;
  margin-left: 0;
}
.appraisel_top h6 {
  color: #181818;
  margin-bottom: 0;
  position: relative;
  top: 1px;
}
.appraisel_banner {
  background-color: #000;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 25px 50px;
}
.appraisel_banner h1 {
  color: #fdac6c;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
}
.appraisel_banner p {
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
}

.additinal_box h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 42px;
  color: #000000;
  margin-bottom: 0;
}
.additinal_box h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 14px;
  color: #000000;
  margin-bottom: 0;
}
.additinal_box h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: #7b7b7b;
  margin-bottom: 16px;
}
.additinal_box h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #222222;
  margin: 12px 0;
}
.additinal_box p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 29px;
  color: #4f4f4f;
}
.additinal_box form {
  width: 416px;
}
.additinal_box form .MuiInputBase-adornedStart {
  width: 100%;
}
.additinal_box form label {
  color: #333;
  font-size: 12px;
  font-weight: 400;
  left: 15px;
  line-height: 13px;
  position: relative;
  top: 15px;
}
.additinal_box {
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 30px;
  margin-top: 45px;
}

.additinal_box input {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  height: 8px;
}
.additinal_box .Mui-focused {
}
section.additional {
  text-align: left;
}
.appraisal_radio {
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 6px;
  padding-top: 24px;
}
.appraisal_radio label {
  cursor: pointer;
  position: relative;
}
.appraisal_radio h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #333333;
}
.appraisal_radio label input {
  display: none;
}
.appraisal_radio label input:checked ~ span {
  border: 1px solid #181818;
  background-color: #181818;
  color: #fff;
}

.appraisal_radio label span {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 6px;
  background: #fff;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  color: #000;
  display: inline-block;
  height: 37px;
  margin-bottom: 0px;
  margin-right: 0;
  padding: 16px;
  position: relative;
  scroll-behavior: inherit;
  text-align: center;
  width: 106.5px;
}
.appraisal_radio .right {
  border-radius: 4px 0 0 4px;
  border-right: none;
}
.appraisal_radio .left {
  border-radius: 0 4px 4px 0;
}
.appraisal_radio .left_right {
  border-radius: 0;
  border-right: 0;
}
.appraisal_radio .right_left {
  border-radius: 0;
  border-right: 0;
}
.asking_price {
  display: flex;
  width: 427px;
  justify-content: space-between;
}
.asking_price h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #000000;
}
.asking_price h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #000000;
  margin-right: 9%;
}
.asking {
  padding: 26px 0;
  border-bottom: 1px solid #d6d6d6;
}
.asking .form-group {
  width: 427px;
  position: relative;
}
.asking .form-group input {
  height: 42px;
  box-shadow: none;
  outline: none;
}
.asking span {
  position: absolute;
  right: 0;
  top: 0;
  width: 105px;
  height: 42px;
  text-align: center;
  padding-top: 10px;
  border-left: 1px solid #ccc;
  cursor: pointer;
  text-transform: uppercase;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #828282;
}
/* .drop_box {
  width: 750px;
} */
.example_photo img {
  width: 100%;
  height: 220px;
  object-fit: cover;
}
.drag_drop {
  padding: 30px 0;
  border-bottom: 1px solid #d6d6d6;
}
.drag_drop .choose_file {
  position: relative;
}
.drag_drop .MuiDropzoneArea-text {
  position: absolute;
  margin: 0;
  top: 47%;
  left: 27%;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
.drag_drop .choose_file img {
  position: absolute;
  margin: 0;
  top: 30%;
  cursor: pointer;
}
.drag_drop .MuiDropzoneArea-icon path {
  display: none !important;
}
.drag_drop .MuiDropzoneArea-icon {
  color: rgba(0, 0, 0, 0.87);
  width: 51px;
  height: 51px;
  position: absolute;
  top: 25%;
  left: 47%;
  z-index: 999999;
}
.drag_drop h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #000000;
}
.drag_drop p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #828282;
  left: inherit;
}
.drag_drop .MuiGrid-spacing-xs-8 {
  width: calc(100% + 64px);
  margin: -32px;
  position: absolute;
  bottom: 35px;
  left: 32px;
  width: 100%;
  text-align: left;
}
.drag_drop .MuiGrid-spacing-xs-8 > .MuiGrid-item {
  padding: 32px;
  position: relative;
  left: 15px;
  padding: 20px;
  max-width: 10.333333% !important;
  margin-right: 15px;
}
.drag_drop .MuiGrid-spacing-xs-8 > .MuiGrid-item img {
  height: 100%;
  position: absolute;
  top: -11px;
  left: 0;
  width: 100%;
}
.drag_drop .MuiDropzonePreviewList-removeButton {
  top: -8px;
  right: 30px;
  width: 40px;
  height: 20px;
  opacity: 0;
  position: absolute;
  transition: 0.5s ease;
}
.example_photo h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #000000;
}
.example_photo {
  padding-top: 53px;
}
.usa_input {
  position: relative;
}
.usa_input img {
  position: absolute;
  left: 0;
  top: 1px;
  padding: 12px;
  border-right: 1px solid #d6d6d6;
}
.usa_input input {
  text-indent: 50px;
}
.pass_field {
  position: relative;
}

.pass_field img {
  position: absolute;
  top: 11px;
  right: 2%;
  cursor: pointer;
  width: 20px;
}

.form_contact .mb-3 {
  position: relative;
}
.form_contact {
  padding-top: 30px;
  padding-bottom: 50px;
  width: 50%;
}
.form_contact h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #000000;
}
.form_contact p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #828282;
  margin-bottom: 10px;
}
.form_contact p img {
  width: 14px;
  margin-right: 6px;
  position: relative;
  top: -2px;
}
.form_contact .input_field label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-transform: capitalize;
  color: #333333;
  margin-bottom: 3px;
}
.form_contact .input_field input,
.form_contact select {
  height: 38px;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  font-size: 12px;
}
.form_contact select {
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 22px;
  text-transform: capitalize;
  color: #828282;
  -webkit-appearance: none;
  /* border-color: #d6d6d6 !important; */
  box-shadow: none !important;
  outline: none !important;
  margin-top: 0 !important;
}

.form_contact .input_field label span {
  color: #eb5757;
}

.form_contact button {
  background: #000000;
  border-radius: 6px;
  height: 38px;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-transform: capitalize;
  margin-top: 28px;
  color: #ffffff !important;
  border: none;
}
.form_contact button:hover {
  background: #000000;
}

.form_contact .form_disable_button {
  background: #a7a7a7;
  border-radius: 6px;
  height: 34px;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  text-transform: capitalize;
  margin-top: 13px;
  color: #ffffff;
  border: none;
}
.form_contact .form_disable_button:hover {
  background: #a7a7a7;
}
.inputLabel {
  left: 12px;
  top: 18px;
  font-size: 12px !important;
  font-family: Gotham regular !important;
  color: #000 !important;
}
.newDragDrop .dropzone {
  width: 100%;
  border: dashed;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  min-height: 250px;
  border-color: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.newDragDrop p {
  /* position: absolute;
  margin: 0;
  top: 47%;
  left: 22%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; */
  color: #000000;
}
.asking .error {
  text-align: left;
}
.z_index {
  z-index: -1;
}
.form_contact button:hover {
  background: #000000;
}
.verified {
  margin-left: 4rem;
  height: 55px;
  margin-top: 37px !important;
  margin-top: 33px;
  border: none !important;
}
.name_Verified {
  margin-top: 15px;
}
.newDragDrop .container {
  padding: 0;
  outline: none;
}
@media screen and (max-width: 1280px) {
  .preview_img img {
    width: 45px;
    height: 50px;
    margin-bottom: 10px;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
  }
}
@media screen and (max-width: 1440px) {
  .form_contact {
    width: 70%;
  }
  .example_photo img {
    width: 100%;
    height: 175px;
    object-fit: cover;
  }
  .newDragDrop p {
    font-size: 12px;
  }
  .newDragDrop .dropzone {
    min-height: 205px;
  }
}
@media screen and (max-width: 1149px) {
  .appraisel_banner,
  .additinal_box,
  .appraisal_radio,
  .drag_drop,
  .form_contact {
    text-align: left;
  }
}
@media screen and (max-width: 991px) {
  .form_contact {
    width: 100%;
  }
  .drop_box {
    width: 100%;
  }
  .example_photo img {
    width: 110px;
    height: 110px;
  }
  .newDragDrop .dropzone {
    min-height: 150px !important;
  }
  .drag_drop p {
    font-size: 12px;
  }
}
@media screen and (max-width: 767px) {
  .additinal_box form {
    width: 426px;
  }
  .asking_price h5 {
    margin-right: 15%;
  }
  /* .example_photo img {
    width: 100%;
    height: inherit;
    object-fit: cover;
  } */
  .choose_file .dropzone {
    min-height: 150px;
  }
  .drag_drop .MuiDropzoneArea-icon {
    top: 5%;
    left: 40%;
  }
  .drag_drop .choose_file img {
    top: 20%;

  }
  .drag_drop .dropzone p {
    top: 48%;
    text-align: center;
  }
  .drag_drop .MuiGrid-spacing-xs-8 > .MuiGrid-item {
    margin-right: 8px;
  }
  .appraisel_banner {
    padding: 8px 0 0px;
  }
  .example_photo img {
    width: 75px;
    height: 75px;
  }
  .newDragDrop .dropzone {
    min-height: 116px !important;
  }
  .drag_drop p {
    font-size: 12px;
  }
  .drag_drop p br {
    display: none;
  }
  .example_photo {
    padding-top: 76px;
  }
  .appraisel_banner h1 {
    font-size: 18px;
    line-height: 37px;
  }
  .appraisel_banner p {
    font-size: 12px;
    line-height: 25px;
  }
  .additinal_box h2 {
    font-size: 16px;
  }
  .additinal_box p {
    font-size: 13px;
    line-height: 20px;
  }
  .additinal_box input {
    font-size: 12px;
    line-height: 22px;
    height: 12px;
  }
  .inputLabel,
  .appraisal_radio h5,
  .asking_price h4,
  .asking_price h5,
  .drag_drop h5 {
    font-size: 12px;
  }
  .appraisal_radio label span {
    font-size: 11px;
  }
  .drag_drop {
    padding: 15px 0px;
  }
  .asking {
    padding: 10px 0;
  }
  .usa_input .sendOTP {
    font-size: 11px;
    padding: 10px 19px;
    height: 30px;
    right: -138px;
    top: 5px;
  }
}

@media screen and (max-width: 575px) {
  .choose_file .MuiDropzoneArea-root {
    min-height: 160px;
    position: relative;
  }
  .usa_input .verifyOTP {
    position: absolute;

    right: -162px;
    height: 40px;
    top: 0;
  }
 

  .choose_file .dropzone {
    min-height: 150px !important;
  }
  .drag_drop .choose_file img {
    top: 30%;
  }
  .form_contact button {
    margin-top: 0px;
    margin-bottom: 15px;
}
}
.MuiButtonBase-root-MuiButton-root:hover {
  background-color: none !important;
}
@media screen and (max-width: 460px) {
  .additinal_box form {
    width: 100%;
  }
  .additinal_box {
    margin-top: 15px;
  }
  .asking_price,
  .asking .form-group {
    width: 100%;
  }
  .appraisal_radio label span {
    width: 95px;
    line-height: 15px;
    height: 45px;
  }

  .drag_drop .MuiGrid-spacing-xs-8 > .MuiGrid-item {
    max-width: 13.333333% !important;
  }
  .appraisal_radio {
    padding-top: 16px;
  }
 
  .usa_input .verifyOTP {
    width: 110px;
    right: -126px;
  }
}
.usa_input .sendOTP :hover {
  background: none;
}
.usa_input .sendOTP :disabled {
  color: #fff;
}
@media screen and (max-width: 399px) {
  .appraisal_radio label span {
    width: 80px;
  }
}
@media screen and (max-width: 375px) {
  .appraisal_radio label span {
    width: 80px;
    line-height: 7px;
    height: 40px;
    padding-left: 12px;
  }

  .usa_input .sendOTP {
    font-size: 11px;
    padding: 10px 16px;
    height: 30px;
    right: -130px;
    top: 5px;
}
}
@media screen and (max-width: 320px) {
  .appraisel_banner h1 {
    font-size: 26px;
    line-height: 32px;
  }
  .appraisel_banner p {
    font-size: 16px;
    line-height: 20px;
  }
  .appraisal_radio label span {
    width: 72px;
  }
 
  .additinal_box h2 {
    font-size: 22px;
    line-height: 40px;
  }
}
