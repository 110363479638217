.banner_landing .MuiAutocomplete-endAdornment {
  display: none;
}
.banner_landing .MuiAutocomplete-inputRoot {
  padding: 1px !important;
  padding-left: 10px !important;
  font-family: 'Gotham regular' !important;
}
/* .banner_landing .css-ai16re-MuiAutocomplete-root {
  margin-bottom: 20px;
} */
/* .banner_landing .button_form {
  margin-top: 30px;
} */
.banner_landing .MuiAutocomplete-root {
  margin-top: 20px !important;
}
.banner_form_mob .MuiTextField-root {
  background: #fff !important;
  border: 0px solid black !important;
  border-radius: 6px !important;
}

@media screen and (max-width: 767px) {
  .form_data .MuiTextField-root {
    width: 277px !important;
  }
}
