.like_view {
  /* margin-bottom: 50px; */
  margin-bottom: 40px;
}
.like_view .card {
  /* width: 96px !important; */
  border: none;
  cursor: pointer;
}
.like_view h2 {
  font-family: 'Gotham Medium';
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  margin-bottom: 18px;
  color: #181818;
}
.like_view .card .card_img .watch_img {
  height: 100%;
}

.like_view .slick-prev:before,
.like_view .slick-next:before {
  opacity: 1;
}
.like_view .slick-prev {
  left: -70px;
  background-color: #f3f2f1;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  top: 36%;
  z-index: 9;
}
.like_view .slick-prev:before {
  position: absolute;
  content: '';
  background-image: url('../../assets/images/icons/rightArrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg);
  background-position: center;
  height: 48px;
  width: 48px;
  top: -5px;
  left: -5px;
  background-size: 18%;
}
.like_view .slick-slide > div {
  margin: 0 10px;
}
.like_view .slick-list {
  margin: 0 -10px;
}
.like_view .slick-next {
  right: -70px;
  background-color: #f3f2f1;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  top: 36%;
}
.like_view .slick-next:before {
  position: absolute;
  content: '';
  background-image: url('../../assets/images/icons/rightArrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 48px;
  width: 48px;
  top: -5px;
  left: -2px;
  background-size: 18%;
}

.like_view .slick-dots li,
.like_view .slick-dots li button {
  width: 11px;
  height: 4px;
}

.like_view .slick-dots li button:before {
  content: '';
  width: 11px;
  height: 4px;
  background: #bfbfc7;
  border-radius: 100px;
}
.like_view .slick-dots li.slick-active button:before {
  background: #0d0b0b;
}

.like_view h5 {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 20px;
  color: #181818;
  text-decoration-line: underline;
  text-transform: uppercase;
  text-underline-offset: 2px;
  margin-top: 10px;
  margin-bottom: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}
.like_view h6 {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 14px;
  color: #333333;
}
.like_view p {
  font-family: 'Gotham Light';
  font-style: normal;
  font-weight: 325;
  font-size: 11px;
  line-height: 13px;
  color: #7b7b7b;
  margin-bottom: 10px;
}
.like_view span {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  font-size: 15px;
  line-height: 17px;
  color: #111111;
}
.like_view .card_img {
  align-items: center;
  background: transparent;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 270px;
  justify-content: center;
  margin-bottom: 9px;
  overflow: hidden;
  padding: 30px;
  position: relative;
  width: 100%;
}
.like_view .bookmark_img {
  position: absolute;
  top: 18px;
  right: 14px;
  cursor: pointer;
  height: 23px;
  width: 28px;
}

.like_view .card .card_body h3 {
  color: #7b7b7b;
  font-family: Gotham regular;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 2px;
}
@media screen and (max-width: 1440px) {
  .like_view h6 {
    font-size: 12px;
    line-height: 12px;
  }
  .like_view .card_img {
    height: 255px;
  }
}

@media screen and (max-width: 1366px) {
  .like_view .card_img {
    height: 213px;
  }
}

@media screen and (max-width: 1199px) {
  .like_view .slick-next,
  .like_view .slick-prev {
    top: 28%;
  }
  .like_view .slick-prev {
    left: -22px;
  }
  .like_view .slick-next {
    right: -22px;
  }
}
@media only screen and (max-width: 1024px) {
  .like_view .slick-slide {
  }
  .like_view .card_img {
    height: 210px;
  }
}

@media only screen and (max-width: 991px) {
  .like_view .card_img {
    height: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .like_view .card {
    width: 100% !important;
  }
  .like_view .card_img {
    height: 200px;
  }
  .like_view h5 {
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 575px) {
  .month_text {
    width: 70px;
  }
  .finance_calculator h3::before,
  .finance_calculator h3::after {
    width: 100px;
  }
  /* .offer_btn button {
    width: 170px;
  } */
  .like_view .card_img {
    height: 248px;
}
}
@media only screen and (max-width: 425px) {
  .like_view .bookmark_img {
    top: 10px;
    right: 10px;
  }
  .like_view .card_img {
    height: 173px;
}
}
@media only screen and (max-width: 399px) {
}
@media only screen and (max-width: 375px) {
  .month_text {
    width: 60px;
  }
    .like_view .card_img {
    height: 148px;
}
}

@media only screen and (max-width: 349px) {
  .offer_btn button {
    width: 145px;
  }
  .price {
    margin-left: 9px;
  }
  .line_chart ul {
    padding-left: 0px;
  }
  .line_chart ul li {
    margin-right: 10px;
  }
  /* .like_view .slick-slide {
    width: 210px !important;
  } */
  .finance_calculator h3::before,
  .finance_calculator h3::after {
    width: 60px;
  }
  .month_text {
    width: 50px;
    margin-right: 15px;
  }
  .moser h4 {
    font-size: 20px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1439px) {
  .just_droped .card .card_img {
    height: 240px;
  }
}
