.ProductViewSlider .card {
  /* width: 96px !important; */
  border: none;
}

.ProductViewSlider .card .card_img .watch_img {
  width: 100%;
  object-fit: cover;
}

.ProductViewSlider .slick-slide {
  /* padding: 0 18px;
  width: 99px !important; */
}
.ProductViewSlider .slick-prev:before,
.ProductViewSlider .slick-next:before {
  opacity: 1;
}
.ProductViewSlider .slick-prev {
  left: 18px;
  background-color: #fff;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  top: 46%;
  z-index: 9;
}
.ProductViewSlider .slick-prev:before {
  position: absolute;
  content: '';
  background-image: url('../../assets/images/icons/rightArrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg);
  background-position: center;
  height: 48px;
  width: 48px;
  top: -5px;
  left: -5px;
  background-size: 18%;
}

.ProductViewSlider .slick-next {
  right: 18px;
  background-color: #fff;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  top: 46%;
}
.ProductViewSlider .slick-next:before {
  position: absolute;
  content: '';
  background-image: url('../../assets/images/icons/rightArrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 48px;
  width: 48px;
  top: -5px;
  left: -2px;
  background-size: 18%;
}

.ProductViewSlider .slick-dots li,
.ProductViewSlider .slick-dots li button {
  width: 11px;
  height: 4px;
}

.ProductViewSlider .slick-dots li button:before {
  content: '';
  width: 11px;
  height: 4px;
  background: #bfbfc7;
  border-radius: 100px;
}
.ProductViewSlider .slick-dots li.slick-active button:before {
  background: #0d0b0b;
}
.pro_image {
  display: none !important;
}

@media screen and (max-width: 1199px) {
}
@media only screen and (max-width: 991px) {
  .vertical_slider .slick-slide > div {
    height: 70px !important;
  }
}
@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 575px) {
  .month_text {
    width: 70px;
  }
  .finance_calculator h3::before,
  .finance_calculator h3::after {
    width: 100px;
  }
  /* .offer_btn button {
    width: 170px;
  } */
}
@media only screen and (max-width: 425px) {
}
@media only screen and (max-width: 399px) {
}
@media only screen and (max-width: 375px) {
  .month_text {
    width: 60px;
  }
}

@media only screen and (max-width: 349px) {
  /* .offer_btn button {
    width: 145px;
  } */
  .price {
    margin-left: 9px;
  }
  .line_chart ul {
    padding-left: 54px;
  }
  .line_chart ul li {
    margin-right: 10px;
  }

  .finance_calculator h3::before,
  .finance_calculator h3::after {
    width: 60px;
  }
  .month_text {
    width: 50px;
    margin-right: 15px;
  }
  .moser h4 {
    font-size: 20px;
  }
}
