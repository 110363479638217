.notfound-container {
  margin: 0;
  background: #fafafa;
  text-align: center;
  padding: 50px 0;
}

.notfound-container h2 {
  margin-bottom: 0.5rem;
  font-size: 25px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 2px;
  font-family: 'Gotham Medium';
}
.notfound-container button {
  text-decoration: none;
  background: #000;
  color: #fff;
  border: 1px solid #000;
  padding: 12px 24px;
  display: inline-block;
  border-radius: 25px;
  font-size: 14px;
  text-transform: uppercase;
  transition: background 0.4s;
  font-family: 'Gotham Medium';
  margin-top: 20px;
}
.notfound-container h1 {
  font-size: 160px;
  margin: 0;
  font-weight: 500;
  letter-spacing: 20px;
  color: #000;
  font-family: 'Gotham Medium';
}
.notfound-container p {
  font-family: 'Gotham Medium';
  font-size: 20px;
  color: grey;
}
