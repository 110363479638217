.drop_slider {
  display: none;
}
.just_droped .col-lg-3 {
  position: relative;
}
.just_droped img[alt='Bookmark'] {
  position: absolute;
  top: 5%;
  right: 14%;
  cursor: pointer;
}
.just_droped a:hover {
  text-decoration: none;
}
.just_droped .star_icon {
  position: absolute;
  width: 20px;
  top: 5%;
  left: 35px;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .just_drop_mob_hide {
    display: none;
  }
  .drop_slider {
    display: block;
  }
}
