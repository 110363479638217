.vertical_slider .slick-prev:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #ccc;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.vertical_slider .slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #ccc;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.vertical_slider .slick-prev {
  left: 40% !important;
  top: 102% !important;
  transform: rotate(-90deg);
}
.vertical_slider .slick-next {
  right: 38% !important;
  top: -9% !important;
  transform: rotate(-90deg);
}
.vertical_slider .slick-slide img {
  width: 100% !important;
  border-radius: 10px;
  height: 57px;
  object-fit: cover;
  cursor: pointer;
}
.vertical_slider .slick-vertical .slick-slide {
  height: 57px !important;
  margin-bottom: 10px !important;
}
/* .vertical_slider .slick-slide h3 {
  margin-bottom: 15px;
} */

.vertical_slider .slick-slide > div {
  margin: 1px 10px;
  height: 135px;
}

@media screen and (max-width: 1699px) {
  .vertical_slider .slick-slide > div {
    margin: 1px 10px;
    height: 100%;
  }
}
@media screen and (max-width: 1440px) {
  .vertical_slider .slick-slide > div {
    height: 100%;
  }
}
@media screen and (max-width: 768px) {
  .vertical_slider .slick-slide > div {
    height: 72px !important;
  }
}
