.login_bcintent p {
  margin: 0px;
}
.login_bcintent {
  text-align: left;
}
.login_bcintent h1 {
  font-family: Gotham Bold;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  color: #fdac6c;
}

.login_bcintent p {
  font-family: Gotham light;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #ffffff;
}
.back_login:hover {
  color: #000;
}
.forgot_pass h2 {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 34px;
  padding-bottom: 20px;
}

section.forgot-form {
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: 60vh;
}

.toggle-password {
  float: right;
  cursor: pointer;
  margin-right: 10px;
  margin-top: -25px;
}

.forgot_pass input {
  height: 43px;
  border: 1px solid #d6d6d6;
}

button.forgotbtn {
  display: block;
  width: 100%;
  color: #fff;
  background: #000;
  border: none;
  height: 50px;
  border-radius: 6px;
  margin-top: 40px;
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.forgot_pass2 h2 {
  font-size: 33px;
  font-family: Gotham Bold;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 58px;
}

.forgot_pass2 p {
  margin-bottom: 35px;
  font-family: Gotham light;
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 26px;
  text-align: center;
}

.forgot_pass2 label {
  float: left;
  font-size: 14px;
  color: #000;
}

.forgot_pass2 input {
  height: 43px;
  border: 1px solid #d6d6d6;
  margin-bottom: 10px;
}

.back_login {
  display: block;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-decoration: underline;
  font-family: Gotham light;
  color: #000;
}
@media screen and (max-width: 767px) {
  .login_bcintent h1 {
    font-size: 28px;
  }
  .login_bcintent {
    text-align: center;
  }
  .login_bcintent p {
    font-size: 16px;
    line-height: 24px;
  }

  section.forgot-form {
    padding-top: 35px;
  }

  .forgot_pass2 h2 {
    font-size: 30px;
    margin: 0px;
  }

  .forgot_pass2 p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 30px;
  }

  .forgot_pass h2 {
    font-size: 30px;
    margin: 0px;
  }
}

@media screen and (max-width: 7680px) {
  .login_banner {
    padding: 25px 50px;
  }
  .login_bcintent h1 {
    font-size: 30px;
  }
  .login_bcintent p {
    font-size: 18px;
  }
  .login_form h2,
  .forgot_pass2 h2 {
    font-size: 28px;
    line-height: 35px;
  }
  .login_form .form-group label,
  .forgot_pass2 label {
    font-size: 12px;
  }
  section.login_form input,
  .forgot_pass2 input {
    font-size: 12px;
  }
  .login_form a,
  .back_login {
    font-size: 14px;
    line-height: 30px;
  }
  .login_form button,
  button.forgotbtn {
    line-height: 14px;
    padding: 0px 50px;
    height: 40px;
  }
  .forgot_pass2 p {
    font-size: 15px;
  }
}

@media screen and (max-width: 1528px) {
  section.forgot-form {
    padding-top: 50px;
    padding-bottom: 50px;
    min-height: 60vh;
  }
}
