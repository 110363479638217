.most_popular {
  position: relative;
}

.most_popular .drop_img {
  background-color: #f5f5f5;
  border-radius: 6px;
}
.most_popular a {
  text-align: left;
  margin-top: 16px;
  display: block;
}
.most_popular a h5 {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 12px;
  color: #181818;
  text-decoration-line: underline;
  text-transform: uppercase;
  text-underline-offset: 2px;
  margin-bottom: 2px;
}
.most_popular a h6 {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 14px;
  color: #333333;
}
.most_popular a p {
  font-family: 'Gotham Light';
  font-style: normal;
  font-weight: 325;
  font-size: 11px;
  line-height: 13px;
  color: #7b7b7b;
  margin-bottom: 10px;
}
.most_popular a h4 {
  font-family: Gotham regular;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #222222;
}
.most_popular span {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  font-size: 15px;
  line-height: 17px;
  color: #111111;
}
.most_popular h3 {
  font-family: Gotham regular;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #7b7b7b;
  margin-bottom: 0;
}
.most_popular .slick-list {
  margin: 0 0px;
}
@media screen and (max-width: 767px) {
  .most_popular .drop_img {
    width: 100%;
    height: 225px;
    object-fit: cover;
  }

  .most_popular .bookmark_img {
    position: absolute;
    top: 36px;
    right: 34px;
  }

  .drop_fix_div {
    width: 100%;
  }
  .most_popular .slick-slide {
    position: relative;
    padding: 0 0 !important;
  }
  .most_popular a h5 {
    line-height: 20px;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .most_popular img[alt='Bookmark'] {
    top: 4% !important;
    right: 10% !important;
  }
  .most_popular .slick-slide > div {
    margin: 0 8px;
  }
}
@media screen and (max-width: 575px) {
  .most_popular .slick-slide > div {
    margin: 0 0px;
  }

  .most_popular .slick-slide {
    padding: 0 10px !important;
  }
}
@media screen and (max-width: 480px) {
  .most_popular .slick-slide {
    padding: 0 0px !important;
  }

  .most_popular .slick-slide > div {
    margin: 0 8px;
  }

  .most_popular .star_icon {
    top: 4% !important;
    left: 25px !important;
  }
  .most_popular img[alt='Bookmark'] {
    top: 4% !important;
    right: 25px !important;
  }
}
@media screen and (max-width: 425px) {
  .most_popular .drop_img {
    height: 138px;
    margin: auto;
  }
}
@media screen and (max-width: 375px) {
  .most_popular a h6 {
    font-size: 10px;
  }
  .image_div_drop {
    height: 136px;
  }
  .most_popular .drop_img {
    height: 100% !important;
    width: 134px;
  }
}
@media screen and (max-width: 320px) {
  .most_popular a h6 {
    font-size: 9px;
  }
}
