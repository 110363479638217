.sec_mywatch_area {
  padding: 40px 0px;
  /* height: 100vh;
    margin-bottom: 120px; */
}

.sidebar a {
  text-align: left;
  display: block;
}
.right_content {
  min-height: 100vh;
}
.watch_box {
  text-align: left;
  /* border: 1px solid rgba(0,0,0,.125); */
  border-radius: 6px;
  transition: all 0.4s;
}
.watch_box_none {
  display: none;
}
/* .watch_box:hover{
    background: #fff;
    box-shadow: 0 15px 32px rgb(50 50 71 / 8%), 0 16px 16px rgb(50 50 71 / 8%);
} */

.sidebar li {
  padding: 10px 0 10px 10px;
  border-bottom: 1px solid #e6e8ed;
  list-style: none;
}
.sidebar a {
  text-decoration: none;
  /* color: #bfbfc7; */
  color: #333;
  font-size: 16px;
  font-weight: 400;
}
.sidebar ul {
  padding: 0px;
}
.watch_content h2 {
  font-family: Gotham Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  color: #181818;
  line-height: 12px;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  text-transform: uppercase;
  text-underline-offset: 2px;
  text-overflow: ellipsis;
  text-transform: uppercase;
  text-underline-offset: 2px;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
.cell_btn a {
  background: #000;
  border-radius: 100px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #fff;
  padding: 10px 22px;
  border: 1px solid transparent;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  float: right;
  margin-bottom: 15px;
}
.img_box img {
  height: 100%;
}
.right_content .col-lg-3 {
  padding-right: 8px;
  margin-bottom: 20px;
}
li.active a {
  color: #000;
}
.watch_content li {
  font-size: 13px;
  text-transform: uppercase;
  text-align: left;
  list-style: none;
  color: #333;
}
.watch_content ul {
  padding: 0px;
}

.watch_content > a {
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #fff;
  background: #000;
  border: 2px solid #000;
  /* box-sizing: border-box; */
  border-radius: 100px;
  padding: 13px 40px;
  display: block;
  margin: 40px auto 10px !important;
  text-align: center;
  width: 145px;
  text-decoration: none;
  transition: all 0.3s;
}
.watch_content > a:hover {
  color: #fff;
}

.sidebar li.active span.light {
  display: inline-flex;
  background-color: #000;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  vertical-align: middle;
}
.sidebar li span.dark {
  display: inline-block;
}

.sidebar li span.dark {
  display: none;
}

.sidebar li.active span.dark {
  display: none;
}

.sidebar li span.dark {
  display: inline-block;
}

.sidebar li span.light {
  display: none;
}

.sidebar li span.dark {
  display: inline-flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  align-items: center;
  margin-bottom: 4px;
}
.img_box {
  align-items: center;
  background: transparent;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 270px;
  justify-content: center;
  margin-bottom: 9px;
  overflow: hidden;
  padding: 30px;
  position: relative;
  width: 100%;
}
.sold_div {
  position: absolute;
  width: 100%;
  text-align: center;
  background-color: #0c0c0c80;
  padding: 20px 0;
  color: red;
  font-size: 20px;
  font-weight: 600;
}
.sold_cart {
  opacity: 0.7;
  pointer-events: none;
  margin-bottom: 30px;
}
.sold_cart_none {
  display: none;
}

.sold_cart .centered {
  position: absolute;
  top: 37%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ff0000;
  font-weight: 400;
  border: none;
  border-left: none;
  border-right: none;
  font-size: 21px;
  background: #000000bd;
  width: 92%;
  text-align: center;
  text-transform: capitalize;
  padding: 10px;
}
.sold_cart .centered_none {
  display: none;
}
.my_watchsave {
  float: inherit !important;
  margin: 0 auto !important;
}
@media screen and (max-width: 1440px) {
  .img_box {
    height: 263px;
  }
}
@media screen and (max-width: 1366px) {
  .img_box {
    height: 247px;
  }
}
@media screen and (max-width: 1280px) {
  .img_box {
    height: 230px;
  }
}

@media screen and (max-width: 1024px) {
  .watch_content > a {
    padding: 13px 20px;
    width: 115px;
  }
  .img_box {
    height: 163px;
  }
}

@media screen and (max-width: 991px) {
  .watch_content > a {
    padding: 13px 20px;
    width: 115px;
  }
  .watch_content > a {
    margin: 20px auto 10px !important;
  }
    .img_box {
    height: 216px;
  }
}

@media screen and (max-width: 768px) {
  .sidebar li {
    padding: 13px 0 13px 15px;
  }
  .watch_content > a {
    padding: 8px 10px;
    width: 90px;
  }
    .img_box {
    height: 160px;
  }
}

@media screen and (max-width: 767px) {
  .sidebar ul {
    border-bottom: 1px solid #e6e8ed;
  }
  .cell_btn a {
    font-size: 12px;
    padding: 5px 12px;
    float: left;
  }
  .sidebar li a {
    padding: 8px 0 8px 5px;
  }
  .sidebar li {
    padding: 0px;
  }
  .sidebar li.active {
    background: #f5f5f7;
    line-height: 27px;
    line-height: 35px;
  }
  .img_box {
    height: 232px;
  }

  .sec_mywatch_area {
    padding: 0px;
  }
  .sidebar li {
    display: inline-block;
    width: 50%;
    border: none;
  }
  .sidebar ul {
    margin: 0px;
  }
  .cell_btn {
    margin-top: 15px;
  }
  .watch_box {
    margin-bottom: 20px;
  }

  .watch_content > a {
    padding: 0;
    width: 185px;
    height: 47px;
    line-height: 47px;
  }

  .img_box img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
  .watch_content > a {
    padding: 5px 5px;
    width: 80px;
    line-height: 18px;
    font-size: 12px;
  }
}
@media screen and (max-width: 575px){
  .img_box {
    height: 264.5px;
  }
}
@media screen and (max-width: 425px){
  .img_box {
    height: 189.5px;
  }
}
@media screen and (max-width: 375px){
  .img_box {
    height: 164.5px;
  }
}
@media screen and (max-width: 320px){
  .img_box {
    height: 137.5px;
  }
}