/* .drawer_box input {
  height: 1rem !important;
  padding-top: 0 !important;
  background-color: transparent !important;
} */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
}
.send-code {
  position: absolute;
  right: 0top;
  right: 0;
  top: 12px;
}
.send-code .drawer_box button:hover {
  background: #000 !important;
}
.send-code button {
  height: 55px !important;
}
.verify-code {
  position: absolute;
  right: 0;
  right: 0;
  top: 88px;
}
.verify-code .drawer_box button:hover {
  background: #000 !important;
}
.verify-code button {
  height: 55px !important;
}
.verify-sucess {
  background: #41ba77 !important;
}
