.drop_slider .card {
  background-color: var(--white_background);
  border: none;
  border-radius: 6px;
  padding: 0px;
  transition: 0.5s ease-out;
}
/* .drop_slider .card:hover{
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
} */
.drop_slider .card a:hover {
  text-decoration: none;
}
.drop_slider .card .card_img {
  background: #f5f5f5;
  height: 270px;
  width: 100%;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5px 5px 0px 0px;
}
.drop_slider .card .card_img .watch_img {
  max-width: 100%;
}
.drop_slider .card .card_img .bookmark_img {
  position: absolute;
  top: 12px;
  right: 10px;
  cursor: pointer;
  z-index: 99;
}
.drop_slider .card .card_body {
  padding: 5px 0px;
}
.drop_slider .card .card_body h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  text-decoration-line: underline;
  margin: 8px 0px;
  color: var(--content_color);
}
.drop_slider .card .card_body h6 {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  margin: 5px 0px;
  color: var(--list_color);
}
.drop_slider .card .card_body p {
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  margin: 5px 0px;
  color: var(--slide_content_color);
}
.drop_slider .card .card_body span {
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
  margin: 8px 0px;
  color: var(--content_color);
}

.drop_slider .slick-slide {
  padding: 0 15px;
}
.drop_slider .slick-prev:before,
.drop_slider .slick-next:before {
  opacity: 1;
}
.drop_slider .slick-prev {
  left: -80px;
}
.drop_slider .slick-prev:before {
  position: absolute;
  content: '';
  background-image: url('../../assets/images/icons/left.png');
  background-repeat: no-repeat;
  height: 48px;
  width: 48px;
}

.drop_slider .slick-next {
  right: -30px;
}
.drop_slider .slick-next:before {
  position: absolute;
  content: '';
  background-image: url('../../assets/images/icons/right.png');
  background-repeat: no-repeat;
  height: 48px;
  width: 48px;
}

.drop_slider .slick-dots li,
.drop_slider .slick-dots li button {
  width: 11px;
  height: 4px;
}

.drop_slider .slick-dots li button:before {
  content: '';
  width: 11px;
  height: 4px;
  background: #bfbfc7;
  border-radius: 100px;
}
.drop_slider .slick-dots li.slick-active button:before {
  background: #0d0b0b;
}

@media screen and (max-width: 767px) {
  .drop_slider .card .card_img {
    height: 180px;
  }
}
