.login_form_container {
  display: flex;
  flex-direction: column;
}

.login_button {
  background: #000;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  height: 50px;
  line-height: 17px;
  margin-bottom: 20px;
  margin-top: 30px;
  width: 100%;
}
