.appraisel {
  margin: 15px 0;
}
.appraisel_top {
  align-items: center;
  align-items: baseline;
  display: flex;
  margin-bottom: 0;
}
.drag_zone .newDragDrop .dropzone {
  min-height: 200px;
}
.additinal_box .MuiAutocomplete-endAdornment {
  display: none;
}
.preview_img img {
  width: 50px;
  height: 35px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}
.usa_input .sendOTP {
  position: absolute;
  height: 38px;
  top: 0;
  right: -160px;
  width: 140px;
  border-radius: 25px;
  margin-top: 0;
  cursor: pointer;
  z-index: 1;
}
.usa_input .inputOTP {
  margin-top: 10px;
  width: 100%;
  text-indent: 10px !important;
}
.autocomplete .suggestions {
  padding: 0 !important;
  max-height: 200px;
  min-height: auto;
  overflow: scroll;
  overflow-x: auto;
  /* width: 277px; */
  width: 216px;
  margin-top: 3px;
  /* margin-top: 16px; */
  /* border: 1px solid #ccc; */
  padding-left: 22px !important;
  padding-top: 10px !important;
  border-radius: 5px;
  margin-bottom: 0;
}
.autocomplete .suggestions li {
  list-style-type: none;
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  text-transform: capitalize;
  cursor: pointer;
}
.autocomplete .css-1ufn0jl {
  font-family: Gotham light !important;
}
.usa_input .verifyOTP {
  position: relative;

  border-radius: 25px;
}
.preview_img .cross_img {
  position: relative;
  top: -22px;
  right: 14px;
  width: 15px;
  height: 15px;
  background-color: #ccc;
  border-radius: 100px;
  padding: 2px;
  cursor: pointer;
}
.appraisel_top a,
.appraisel_top h6 {
  font-family: Gotham regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 325;
  line-height: 14px;
  color: #000000;
}
.appraisel_top img {
  margin: 0 10.5px;
  position: relative;
  top: 2px;
  margin-left: 0;
}
.appraisel_top h6 {
  color: #181818;
  margin-bottom: 0;
  position: relative;
  top: 1px;
}
.appraisel_banner {
  background-color: #000;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px;
}
.appraisel_banner h1 {
  color: #fdac6c;
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 43px;
}
.appraisel_banner p {
  color: #fff;
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
}

.additinal_box h2 {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 42px;
  color: #000000;
  margin-bottom: 0;
}
.additinal_box h3 {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 14px;
  color: #000000;
  margin-bottom: 0;
}
.additinal_box h4 {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: #7b7b7b;
  margin-bottom: 16px;
}
.additinal_box h5 {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #222222;
  margin: 12px 0;
}
.additinal_box p {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  color: #4f4f4f;
}
.additinal_box form {
  width: 416px;
}
.additinal_box form .MuiInputBase-adornedStart {
  width: 100%;
}
.additinal_box form label {
  color: #333;
  font-size: 12px;
  font-weight: 400;
  left: 15px;
  line-height: 13px;
  position: relative;
  top: 15px;
}
.additinal_box {
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 30px;
  margin-top: 45px;
}

.additinal_box input {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
}
.additinal_box .Mui-focused {
}

.appraisal_radio {
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 6px;
  padding-top: 24px;
}
.appraisal_radio label {
  cursor: pointer;
  position: relative;
}
.appraisal_radio h5 {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #333333;
}
.appraisal_radio label input {
  display: none;
}
.appraisal_radio label input:checked ~ span {
  border: 1px solid #181818;
  background-color: #181818;
  color: #fff;
}

.appraisal_radio label span {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  background: #fff;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  color: #000;
  display: inline-block;
  height: 50px;
  margin-bottom: 0px;
  margin-right: 0;
  padding: 16px;
  position: relative;
  scroll-behavior: inherit;
  text-align: center;
  width: 106.5px;
}
.appraisal_radio .right {
  border-radius: 4px 0 0 4px;
  border-right: none;
}
.appraisal_radio .left {
  border-radius: 0 4px 4px 0;
}
.appraisal_radio .left_right {
  border-radius: 0;
  border-right: 0;
}
.appraisal_radio .right_left {
  border-radius: 0;
  border-right: 0;
}
.asking_price {
  display: flex;
  width: 427px;
  justify-content: space-between;
}
.asking_price h4 {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
}
.asking_price h5 {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  margin-right: 3%;
}
.asking {
  padding: 26px 0;
  border-bottom: 1px solid #d6d6d6;
}
.asking .form-group {
  width: 427px;
  position: relative;
}
.asking .form-group input {
  height: 42px;
  box-shadow: none;
  outline: none;
}
.asking span {
  position: absolute;
  right: 0;
  top: 0;
  width: 105px;
  height: 42px;
  text-align: center;
  padding-top: 10px;
  border-left: 1px solid #ccc;
  cursor: pointer;
  text-transform: uppercase;
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #828282;
}
/* .drop_box {
  width: 750px;
} */
.example_photo img {
  width: 100%;
  height: 220px;
  object-fit: cover;
}
.drag_drop {
  padding: 30px 0;
}
.drag_drop .choose_file {
  position: relative;
}
.drag_drop .MuiDropzoneArea-text {
  position: absolute;
  margin: 0;
  top: 47%;
  left: 27%;
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
.drag_drop .choose_file img {
  position: absolute;
  margin: 0;
  top: 30%;
  cursor: pointer;
}
.drag_drop .MuiDropzoneArea-icon path {
  display: none !important;
}
.drag_drop .MuiDropzoneArea-icon {
  color: rgba(0, 0, 0, 0.87);
  width: 51px;
  height: 51px;
  position: absolute;
  top: 25%;
  left: 47%;
  z-index: 999999;
}
.drag_drop h5 {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
}
.drag_drop p {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #828282;
}
.drag_drop .MuiGrid-spacing-xs-8 {
  width: calc(100% + 64px);
  margin: -32px;
  position: absolute;
  bottom: 35px;
  left: 32px;
  width: 100%;
  text-align: left;
}
.drag_drop .MuiGrid-spacing-xs-8 > .MuiGrid-item {
  padding: 32px;
  position: relative;
  left: 15px;
  padding: 20px;
  max-width: 10.333333% !important;
  margin-right: 15px;
}
.drag_drop .MuiGrid-spacing-xs-8 > .MuiGrid-item img {
  height: 100%;
  position: absolute;
  top: -11px;
  left: 0;
  width: 100%;
}
.drag_drop .MuiDropzonePreviewList-removeButton {
  top: -8px;
  right: 30px;
  width: 40px;
  height: 20px;
  opacity: 0;
  position: absolute;
  transition: 0.5s ease;
}
.example_photo h6 {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
}
.example_photo {
  padding-top: 53px;
}
.usa_input {
  position: relative;
}
.usa_input img {
  position: absolute;
  left: 0;
  top: 1px;
  padding: 14px;
  border-right: 1px solid #d6d6d6;
}
.usa_input input {
  text-indent: 50px;
}
.pass_field {
  position: relative;
}

.pass_field img {
  position: absolute;
  top: 11px;
  right: 2%;
  cursor: pointer;
  width: 20px;
}

.form_contact .mb-3 {
  position: relative;
}
.form_contact {
  padding-top: 30px;
  padding-bottom: 80px;
  width: 50%;
}
.form_contact h5 {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
}
.form_contact p {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #828282;
  margin-bottom: 10px;
}
.form_contact p img {
  width: 14px;
  margin-right: 6px;
  position: relative;
  top: -2px;
}
.form_contact .input_field label {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-transform: capitalize;
  color: #333333;
  margin-bottom: 3px;
}
.form_contact .input_field input,
.form_contact select {
  height: 42px;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
}
.form_contact select {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-transform: capitalize;
  color: #828282;
  -webkit-appearance: none;
  /* border-color: #d6d6d6 !important; */
  box-shadow: none !important;
  outline: none !important;
  margin-top: 0 !important;
}

.form_contact .input_field label span {
  color: #eb5757;
}
.form_disable_button {
  background: #a7a7a7 !important;
  border-radius: 6px !important;
  height: 50px !important;
  width: 100% !important;
  font-family: Gotham Medium !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  text-transform: capitalize !important;
  margin-bottom: 50px !important;
  margin-top: 40px !important;
  color: #ffffff !important;
  border: none !important;
}

.form_contact_button {
  background: #000000 !important;
  border-radius: 6px !important;
  height: 50px !important;
  width: 100% !important;
  font-family: Gotham Medium !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  text-transform: capitalize !important;
  margin-bottom: 50px !important;
  margin-top: 40px !important;
  color: #ffffff !important;
  border: none !important;
}
.inputLabel {
  left: 12px;
  top: 18px;
  font-size: 14px !important;
  font-family: 'Baloo 2' !important;
}
.newDragDrop .dropzone {
  width: 100%;
  border: dashed;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  min-height: 250px;
  border-color: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: #fff;
}
.newDragDrop p {
  position: absolute;
  margin: 0;
  top: 47%;
  left: 27%;
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
.asking .error {
  text-align: left;
}
.z_index {
  z-index: -1;
}
.form_contact button:hover {
  background: #000000;
}
.newDragDrop .container {
  padding: 0;
}
.prewi_imge {
  display: inline-block;
}
@media screen and (max-width: 1440px) {
  .form_contact {
    width: 70%;
  }
  .example_photo img {
    height: 100%;
    object-fit: cover;
    width: 175px;
  }
  .newDragDrop .dropzone {
    min-height: 205px;
  }
  .newDragDrop p {
    left: 9%;
    font-size: 12px;
  }
}
@media screen and (max-width: 1149px) {
  .appraisel_banner,
  .additinal_box,
  .appraisal_radio,
  .drag_drop,
  .form_contact {
    text-align: left;
  }
}
@media screen and (max-width: 991px) {
  .form_contact {
    width: 100%;
  }
  .drop_box {
    width: 100%;
  }
  .example_photo img {
    width: 110px;
    height: 110px;
  }
  .newDragDrop .dropzone {
    min-height: 150px !important;
  }
  .newDragDrop p {
    left: 7%;
    font-size: 12px;
  }
  .drag_drop p {
    font-size: 12px;
    line-height: 19px;
  }
  .form_disable_button {
    margin-top: 16px;
  }
  .example_photo img {
    width: 110px;
    height: 110px;
  }
  .drag_drop p {
    font-size: 12px;
  }
}
@media screen and (max-width: 767px) {
  .usa_input .verifyOTP,
  .usa_input .sendOTP {
    font-size: 11px;
    padding: 10px 16px;
    height: 30px;
  }
  .usa_input .verifyOTP{
    right: -136px;
    margin-top: 15px;

  }
  .additinal_box form {
    width: 426px;
  }

  .choose_file .dropzone {
    min-height: 150px;
  }
  .drag_drop .MuiDropzoneArea-icon {
    top: 5%;
    left: 40%;
  }
  .drag_drop .choose_file img {
    top: 48%;
  }
  .drag_drop .dropzone p {
    top: 48%;
    left: 0;
    text-align: center;
  }
  .drag_drop .MuiGrid-spacing-xs-8 > .MuiGrid-item {
    margin-right: 8px;
  }
  .appraisel_banner {
    padding: 15px 0;
  }
  .example_photo img {
    width: 75px;
    height: 75px;
  }
  .newDragDrop .dropzone {
    min-height: 116px !important;
  }
  .drag_drop .choose_file img {
    top: 55%;

  }
  .drag_drop p {
    font-size: 12px;
  }
  .drag_drop p br {
    display: none;
  }
  .example_photo {
    padding-top: 76px;
  }
}
@media screen and (max-width: 575px) {
  .choose_file .MuiDropzoneArea-root {
    min-height: 160px;
    position: relative;
  }
  .example_photo img {
    width: 50%;
  }
  .usa_input .verifyOTP,
  .usa_input .sendOTP {
    width: 140px;
  }
  .usa_input .sendOTP {
    height: 40px;
  }
  .usa_input .verifyOTP {
    position: absolute;
    top: 0;
  }
  .drag_drop .dropzone p {
    left: 29%;
  }

  .choose_file .dropzone {
    min-height: 150px;
  }
  .drag_drop .choose_file img {
    top: 44%;
  }
}
.MuiButtonBase-root-MuiButton-root:hover {
  background-color: none !important;
}
@media screen and (max-width: 460px) {
  .appraisel_banner h1 {
    font-size: 30px;
    line-height: 37px;
  }
  .appraisel_banner p {
    font-size: 20px;
    line-height: 25px;
  }
  .additinal_box form {
    width: 100%;
  }
  .additinal_box {
    margin-top: 30px;
  }
  .asking_price,
  .asking .form-group {
    width: 100%;
  }
  .appraisal_radio label span {
    width: 98px;
    line-height: 15px;
    height: 45px;
    font-size: 13px;
  }

  .drag_drop .MuiGrid-spacing-xs-8 > .MuiGrid-item {
    max-width: 13.333333% !important;
  }
  .additinal_box p {
    font-size: 16px;
    line-height: 21px;
  }
  .appraisal_radio {
    padding-top: 16px;
  }
  .drag_drop .dropzone p {
    left: 25%;
  }
  .usa_input .sendOTP {
    width: 100px;
    right: -126px;
  }
  .usa_input .verifyOTP {
    width: 100px;
    right: -126px;
  }
}
.usa_input .sendOTP :hover {
  background: none;
}
.usa_input .sendOTP :disabled {
  color: #fff;
}
@media screen and (max-width: 399px) {
  .appraisal_radio label span {
    width: 80px;
  }
}
@media screen and (max-width: 375px) {
  .appraisal_radio label span {
    width: 80px;
    line-height: 7px;
    height: 40px;
    font-size: 13px;
    padding-left: 12px;
  }
  .drag_drop .dropzone p {
    left: 17%;
  }
}
@media screen and (max-width: 320px) {
  .appraisel_banner h1 {
    font-size: 26px;
    line-height: 32px;
  }
  .appraisel_banner p {
    font-size: 16px;
    line-height: 20px;
  }
  .appraisal_radio label span {
    width: 72px;
  }
  .drag_drop .dropzone p {
    left: 10%;
  }
  .additinal_box h2 {
    font-size: 22px;
    line-height: 40px;
  }
}
