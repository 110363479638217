
.forgot_pass label {
    float: left;
    font-size: 14px;
    color: #000;
}

.forgot_pass input {
    height: 43px;
    border: 1px solid #D6D6D6;
}
button.forgotbtn {
    display: block;
    width: 100%;
    color: #fff;
    background: #000;
    border: none;
    height: 50px;
    border-radius: 6px;
    margin-top: 40px;
    font-family: 'Baloo 2';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
}

@media screen and (max-width:767px) {
    .login_bcintent h1 {
        font-size: 28px;
    }

    .login_bcintent p {
        font-size: 16px;
        line-height: 24px;
    }

    section.forgot-form {
        padding-top: 35px;
    }

    .forgot_pass2 h2 {
        font-size: 30px;
        margin: 0px;
    }

    .forgot_pass2 p{
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 30px;
    }

    .forgot_pass h2{
        font-size: 30px;
        margin:0px;
    }
}