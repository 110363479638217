.finance_calculator {
  border: 1px solid #bdbdbd;
  border-radius: 6px;
  padding: 20px;
  /* margin-top: 60px; */
  margin-top: 30px;
  max-height: 720px;
  overflow: scroll;
}
.finance_calculator .MuiAccordionDetails-root {
  padding: 0 !important;
}
.finance_calculator::-webkit-scrollbar {
  width: 1px;
}

.finance_calculator::-webkit-scrollbar-track {
  border-radius: 0px;
}

.finance_calculator::-webkit-scrollbar-thumb {
  border-radius: 0px;
}
.finance_calculator .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 0px 0;
}
.finance_calculator .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: -8px 0;
}
.estimate {
  text-align: center;
  margin-top: 5px !important;
}
.finance_calculator h2 {
  font-family: 'Gotham medium';
  font-style: normal;
  font-weight: 350;
  font-size: 20px;
  line-height: 29px;
  color: #181818;
}
.finance_calculator h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  position: relative;
  margin-bottom: 3px;
}
.finance_calculator .MuiPaper-elevation {
  box-shadow: none;
}
.finance_calculator .Show_breakdown {
  width: 100% !important;
  margin-top: -36px !important;
  font-family: 'Gotham Medium' !important;
  font-style: normal !important;
  font-weight: 350 !important;
  font-size: 13px !important;
  line-height: 16px !important;
  color: #ed693c !important;
  display: block;
  text-align: center;
}
.finance_calculator .Show_breakdown img {
  margin-left: 10px;
}
.finance_calculator h3::before {
  content: '';
  background-color: #ef841a;
  width: 135px;
  height: 2px;
  position: absolute;
  left: 0;
  top: 15px;
}
.finance_calculator h3::after {
  content: '';
  background-color: #ef841a;
  width: 135px;
  height: 2px;
  position: absolute;
  right: 0;
  top: 15px;
}
.finance_calculator p {
  font-family: 'Gotham light';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 17px;
  color: #828282;
  margin: 24px auto 0px;
}
.finance_calculator .show_breakdown {
  text-align: center;
  margin-bottom: 27px;
}
.finance_calculator .Mui-expanded {
  min-height: 0 !important;
}
.finance_calculator .show_breakdown button {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #ef841a;
  background: none;
  border: none;
}
.finance_calculator .MuiCollapse-hidden {
  min-height: 0px !important;
}
.finance_calculator h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
}
.radio_buttons label span {
  font-family: Gotham regular;
  position: relative;
  display: inline-block;
  margin-right: 18px;
  padding: 13px 33px;
  font-weight: 325;
  font-size: 16px;
  line-height: 18px;
  color: #000;
  border: 1px solid #bdbdbd;
  border-radius: 100px;
  background: #fff;
  text-align: center;
  margin-bottom: 14px;
  scroll-behavior: inherit;
}
.radio_buttons label:nth-child(4) span {
  margin-right: 0;
}
.radio_buttons label:nth-child(7) span {
  margin-right: 0;
}

.radio_buttons label {
  position: relative;
  cursor: pointer;
}
.radio_buttons .form-group label {
  font-family: Gotham regular;
  font-weight: 325;
  font-size: 12px;
  line-height: 13px;
  color: #828282;
}
.radio_buttons label input {
  display: none;
}

.price_box_view.sales {
  margin-top: 7px !important;
}

.hide_breakdown img {
  transform: rotate(180deg) !important;
}
.radio_buttons label input:checked ~ span {
  border: 3px solid #ed693c;
  /* box-shadow: inset 2px 1px 1px #ed693c, inset -1px 1px 1px #ed693c, inset -1px -1px 1px #ed693c; */
}
.price_vicle2 {
  margin-top: 10px;
}

/* @media screen and (max-width: 1699px) {
  .price_vicle2 .price_box_view {
    width: 164px;
    height: 76px;
    border-radius: 12px;
    margin-right: 18px;
    margin-bottom: 20px;
    padding: 10px;
    display: inline-grid;
  }
  .radio_buttons label span {
    line-height: 10px;
    width: 73px;
    height: 40px;
  }
  .finance_calculator .Show_breakdown {
    margin-left: 50%;
    margin-top: -23px !important;
  }
  .finance_calculator h3::before,
  .finance_calculator h3::after {
    width: 100px;
  }
  .finance_calculator p {
    margin-top: 10px;
  }
  .finance_calculator h4 {
    margin-top: 0;
  }
} */
/* @media screen and (max-width: 1440px) {
  .price_vicle2 .price_box_view {
    width: 148px !important;
    height: 74px !important;
    display: inline-grid !important;
  }

  .radio_buttons label span {
    line-height: 9px;
    width: 65px;
    height: 40px;
    margin-bottom: 6px;
  }
  .finance_calculator p {
    font-size: 12px;
  }
  .price_box img {
    width: 30px;
  }
  .price h6 {
    font-size: 12px;
    line-height: 5px;
  }
  .need_reach p {
    font-size: 12px;
  }
  .need_reach button {
    width: 87px;
    height: 34px;
    font-size: 11px;
  }
} */
/* @media screen and (max-width: 1366px) {
  .price_vicle2 .price_box_view {
    width: 147px;
    height: 70px;
    display: inline-grid;
  }

  .finance_calculator h3::before {
    width: 80px;
    height: 2px;
    top: 17px;
  }
  .finance_calculator h3::after {
    width: 80px;
    height: 2px;
    top: 17px;
  }
  .finance_calculator p {
    font-size: 12px !important;
    margin-top: 0 !important;
  }
  .radio_buttons label span {
    line-height: 10px;
    width: 64px;
    height: 40px;
    margin-bottom: 10px;
  }
  .finance_calculator {
    border: 1px solid #bdbdbd;
    border-radius: 6px;
    padding: 20px;
    margin-top: 31px;
    border: 1px solid #bdbdbd;
    border-radius: 6px;
    padding: 20px;
    margin-top: 31px;
    max-height: 670px;
    overflow-y: scroll;
  }
  .finance_calculator .Show_breakdown {
    width: 100% !important;
    margin-top: -17px !important;
    font-family: 'Gotham Medium' !important;
    font-style: normal !important;
    font-weight: 350 !important;
    font-size: 16px !important;
    line-height: 16px !important;
    color: #ed693c !important;
    display: block;
    text-align: center;
    margin-left: 49%;
  }
} */
/* @media screen and (max-width: 1199px) {
  .finance_calculator h3::before,
  .finance_calculator h3::after {
    width: 90px;
  }
} */
/* @media screen and (max-width: 1024px) {
  .finance_box p {
    font-size: 14px;
  }
  .finance_calculator h3::before,
  .finance_calculator h3::after {
    width: 90px;
  }
} */
/* @media screen and (max-width: 991px) {
  .price_vicle2 .price_box_view {
    display: inline-grid;
    width: 214px;
    height: 60px;
  }
  .radio_buttons label span {
    line-height: 4px;
    width: 52px;
    height: 35px;
    margin-right: 6px;
    margin-bottom: 5px;
  }

  .finance_calculator h3::before,
  .finance_calculator h3::after {
    width: 50px;
  }
  .like_view .slick-next {
    right: -70px;
  }
  .like_view .slick-prev {
    left: -70px;
  }
  .finance_calculator .Show_breakdown {
    margin-left: 9%;
  }
  .finance_calculator {
    max-height: 635px;
  }
} */
/* @media only screen and (max-width: 768px) {
  .like_view .slick-next {
    right: -20px;
  }
  .like_view .slick-prev {
    left: -20px;
  }
} */
/* @media only screen and (max-width: 767px) {
  .finance_calculator h3::before,
  .finance_calculator h3::after {
    width: 160px;
  }
  .radio_buttons label span {
    line-height: 18px;
    width: 95px;
    height: 50px;
    margin-right: 28px;
    margin-bottom: 13px;
  }
  .like_view .slick-next {
    right: -70px;
  }
  .like_view .slick-prev {
    left: -70px;
  }
  .finance_calculator {
    max-height: inherit;
  }
  .finance_calculator .Show_breakdown {
    margin-left: 80%;
  }
  .finance_calculator p {
    text-align: center;
  }
} */
/* @media screen and (max-width: 575px) {
  .finance_calculator h3::before,
  .finance_calculator h3::after {
    width: 100px;
  }
  .like_view .slick-next {
    right: -9px;
  }
  .like_view .slick-prev {
    left: -9px;
  }
} */
/* @media screen and (min-width: 426px) and (max-width: 539px) {
  .price_vicle2 .price_box_view {
    width: 190px;
  }
  .radio_buttons label span {
    width: 84px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 425px) {
  .pre_owned li {
    margin-right: 20px;
  }
  .price_vicle2 .price_box_view {
    display: inline-grid;
    width: 47%;
  }
  .price_box_view h1 {
    line-height: 12px;
    margin-bottom: 0;
  }
  .radio_buttons label span {
    line-height: 10px;
    width: 75px;
    height: 40px;
    margin-right: 10px;
    margin-bottom: 13px;
  }
  .radio_buttons label:nth-child(4) span {
    margin-right: 21px;
  }
  .finance_calculator .Show_breakdown {
    margin-left: 45%;
  }
}
@media only screen and (max-width: 375px) {
  .finance_calculator h3::before,
  .finance_calculator h3::after {
    width: 70px;
  }
  .radio_buttons label span {
    width: 65px;
    margin-bottom: 6px;
    margin-right: 6px;
  }
  .offer_btn button {
    width: 170px;
  }
}
@media only screen and (max-width: 375px) {
  .offer_btn button {
    width: 146px;
    font-size: 12px;
  }
  .finance_calculator h3::before,
  .finance_calculator h3::after {
    width: 50px;
  }
}
@media only screen and (max-width: 320px) {
  .price_vicle2 .price_box_view {
    width: 100%;
  }
  .finance_calculator .Show_breakdown {
    margin-left: 20%;
  }
  .radio_buttons label span {
    width: 50px;
    margin-bottom: 5px;
    margin-right: 6px;
    height: 30px;
    line-height: 1px;
    text-align: center;
  }
} */
/* .finance_calculator {
  border: 1px solid #bdbdbd;
  border-radius: 6px;
  padding: 20px;
  margin-top: 40px;
  max-height: 540px;
  overflow: scroll;
} */
