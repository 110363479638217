.deatil_modal_buttons {
  margin-top: 32px;
  text-align: center;
}
.popup-image {
  cursor: pointer;
}
.deatil_modal_buttons_sucess button {
  background-color: #f15153;
  border: none;
  border-radius: 100px;
  color: #fff !important;
  font-family: Gotham Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: 33px;
  width: 110px;
  left: 0;
  line-height: 19px;
  padding: 0 15px;
  text-transform: capitalize;
  top: 0;
  margin-top: 6px;
  display: inline-block;
  float: inherit !important;
  opacity: 1;
}
.deatil_modal_buttons_sucess button:hover {
  background-color: #f15156;
  opacity: 1 !important;
}
.deatil_modal_buttons .confirm {
  background-color: #3ebb78;
  border: none;
  border-radius: 100px;
  color: #fff;
  font-family: Gotham Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: 33px;
  width: 110px;
  left: 0;
  line-height: 19px;
  padding: 0 15px;
  text-transform: capitalize;
  top: 0;
  margin-right: 15px;
  display: inline-block;
}
.deatil_modal_buttons .confirm:hover {
  background-color: #3ebb78;
}
.deatil_modal_buttons .cancel {
  background-color: #f15156;
  border: none;
  border-radius: 100px;
  color: #fff;
  font-family: Gotham Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: 33px;
  width: 110px;
  left: 0;
  line-height: 19px;
  padding: 0 15px;
  text-transform: capitalize;
  top: 0;
  margin-right: 0px;
  display: inline-block;
}
.deatil_modal_buttons .close {
  background-color: #f15156;
  border: none;
  border-radius: 100px;
  color: #fff;
  font-family: Gotham Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: 42px;
  width: 142px;
  left: 0;
  line-height: 19px;
  padding: 0 15px;
  text-transform: capitalize;
  top: 0;
  margin-right: 15px;
  margin-top: 25px;
  display: inline-block;
}
.deatil_modal_buttons .cancel:hover {
  background-color: #f15156;
}
.deatil_modal_buttons .close:hover {
  background-color: #f15156;
}
.detail_modal_container {
  text-align: center;
  border: none !important;
  box-shadow: none !important;
  width: 300px !important;
  height: 290px !important;
  border-radius: 5px !important;
}

.modal_conatiner {
  width: 400px !important;
  height: 326px !important;
  text-align: center;
  border-radius: 10px !important;
}

.modal_sucess_pending {
  background-color: #000000;
  color: #ffffff;
  border-radius: 5px 5px 0 0;
  padding: 50px 15px;
  text-align: center;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.status_success {
  background-color: #41ba77;
  color: #ffffff;
  border-radius: 5px 5px 0 0;
  padding: 30px 15px;
  text-align: center;
  margin-bottom: 35px;
  text-align: center;
  height: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.status_success img {
  width: 63px;
  height: 63px;
}
.status_failure {
  background-color: #41ba77;
  color: #ffffff;
  border-radius: 5px 5px 0 0;
  color: #fff;
  padding: 30px 15px;
  height: 165px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal_sucess_pending p {
  margin: 0;
}
.status_failure img {
  width: 63px;
  height: 63px;
}
.modal_sucess_pending .modal_title {
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  font-family: Gotham regular;
  color: #ffffff;
}
.modal_sucess_pending .modal_use_details {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 48px;
  font-family: Gotham regular;
  color: #ffffff;
}
.modal_sucess_pending p {
  font-family: Gotham regular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.modal_container strong {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}
.modal_message {
  font-family: Gotham regular !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  margin: 0 !important;
  padding-top: 5px !important;
  padding: 0px 15px;
}
@media only screen and (max-width: 575px) {
  .deatil_modal_buttons .cancel {
    margin-right: 0;
  }
  .deatil_modal_buttons .confirm,
  .deatil_modal_buttons .cancel {
    width: 115px;
  }

  .status_success {
    padding: 25px 30px;
  }
  .status_success img {
    width: 35%;
  }
  .modal_conatiner {
    width: 90% !important;
  }
}
