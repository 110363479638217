.vertical_top img {
  height: 400px;
  max-width: 100%;
  margin: auto;
}
.inventory img {
  height: inherit !important;
  max-width: inherit !important;
  margin: inherit !important;
  margin: 0 10px !important;
}
.vertical_top .slick-dots {
  bottom: -18px;
  height: 25px;
  padding: 0 96px;

  /* border-bottom: 2px solid #726f6f; */
}
.vertical_top .slick-dots li {
  width: 100%;
  height: 2px;
  position: relative;
  margin: 0;
}
.vertical_top ul::before {
  /* content: ''; */
  width: 100%;
  height: 2px;
  background-color: #c9c3c3;
  position: absolute;
  left: 0;
  top: 28%;
}
.vertical_top .slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  content: '';
  text-align: center;
  opacity: 0.25;
  background-color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.vertical_top .slick-prev:before,
.vertical_top .slick-next:before {
  font-size: 30px;
  line-height: 1;
  opacity: 0.75;
  color: #a9a2a2 !important;
  /* background-image: url('../../assets/images/icons/rightArrow.svg');
    background-repeat: no-repeat; */
}

.vertical_top .slick-dots li.slick-active button:before {
  opacity: 0.75;
  background-color: #282626;
}
.slick-prev,
.slick-next {
  top: 50%;
}
.vertical_top .slick-prev {
  left: 15px;
  z-index: 9;
}
.vertical_top .slick-next {
  right: 10px;
}
.slick-dots {
  display: flex !important;
}
.slider_hide {
  display: none;
}

.marg_top_20_show_mob {
  position: static;
  padding-bottom: 20px;
}

.vertical_top {
  position: sticky;
  top: 100px;
}

@media only screen and (max-width: 1440px) {
  .vertical_top .slick-dots {
    padding: 0 70px;
  }
}
@media only screen and (max-width: 1438px) {
}
@media only screen and (max-width: 1366px) {
  .vertical_top .slick-dots {
    padding: 0 36px;
  }
}

@media only screen and (max-width: 1280px) {
  .make_end_offer input {
    width: 66%;
  }
  .enter_amount {
    width: 34%;
  }
  /* .vertical_top img {
    height: 413px;
  } */

  /* .text{
    display: none;
  } */
}
@media only screen and (max-width: 1024px) {
  .make_end_offer input {
    width: 63%;
  }
  .enter_amount {
    width: 37%;
  }
  .vertical_top .slick-dots {
    padding: 0 25px;
  }
}

@media only screen and (max-width: 991px) {
  .vertical_top .slick-dots {
    padding: 0 0px;
  }
}

@media only screen and (max-width: 480px) {
  .vertical_top img {
    height: 395px;
  }
}
@media only screen and (max-width: 375px) {
  .vertical_top img {
    height: 345px;
  }
}
@media only screen and (max-width: 320px) {
  .vertical_top img {
    height: 290px;
  }
}
