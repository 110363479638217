.fashion_slider {
  position: relative;
}
.fashion_slider:focus-visible {
  outline: none;
}
.fashion_slider img {
  width: 100%;
}
.fashion_slider_box .slick-prev {
  left: -15px;
  z-index: 9;
  background: #fff;
  border-radius: 100%;
  width: 28px;
  height: 28px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
}
.fashion_slider_box .slick-next {
  right: -15px;
}
.fashion_slider_box .slick-next {
  z-index: 9;
  background: #fff;
  border-radius: 100%;
  width: 28px;
  height: 28px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
}
.fashion_slider_box .slick-slide > div {
  margin: 0 10px;
}
.vertical_slider .slick-list {
  height: 300px !important;
}
.vertical_slider .slick-track {
  height: 100% !important;
}
.fashion_slider_box .slick-prev:before {
  position: absolute;
  content: '';
  background-image: url('../../assets/images/icons/rightArrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg);
  width: 48px;
  height: 48px;
  top: -10px;
  left: -9px;
  font-size: 41px;
  background-size: 12%;
}

.fashion_slider_box .slick-next:before {
  position: absolute;
  content: '';
  background-image: url('../../assets/images/icons/rightArrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 48px;
  width: 48px;
  top: -10px;
  left: -9px;
  font-size: 41px;
  background-size: 12%;
}
.transaction_detail {
  text-align: left;
  padding-top: 10px;
}
.transaction_detail h3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
  font-family: Gotham Medium;
  margin-bottom: 2px;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.transaction_detail p {
  font-family: Gotham Regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 5px;
}
.transaction_detail span {
  font-family: Gotham Medium;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #76b483;
  display: block;
}

.fashion_slider_box .slick-prev,
.slick-next {
  top: 39%;
}
/* .fashion_slider_box .slick-slide {
  height: 270px;
} */
.fashion_slider_box .slick-track {
  margin-left: inherit;
}
@media screen and (max-width: 991px) {
  .fashion_slider_box .slick-slide > div {
    margin: 0 8px;
  }
  .fashion_slider_box .slick-list {
    margin: 0 -5px;
  }
}

@media screen and (max-width: 767px) {
  /* .fashion_slider img {
    height: 162px;
  } */

  .transaction_detail {
    padding-left: 5px;
  }

  .transaction_detail h3 {
    font-size: 13px;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transaction_detail p {
    font-size: 12px;
    margin: 5px 0px 8px 0px;
  }
}
