.inventory_Detail {
  text-align: left;
}

.Popularity {
  /* display: flex;
  justify-content: end; */
  display: inline-block;
  float: right;
}

.Popularity .filter {
  background: #ffffff;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
}

.Popularity .filter:first-child {
  width: auto;
  height: 48px;
  padding: 0 16px !important;
}

.Popularity .filter:nth-child(2) {
  width: 86px;
  height: 48px;
  margin: 0 10px;
}

.Popularity .filter:last-child {
  /* width: 86px; */
  width: auto;
  height: 36px;
  margin-right: 0;
}

.filter span {
  font-family: Gotham regular;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #111111;
  text-transform: capitalize;
  padding-right: 0 !important;
  padding-left: 3px;
  padding-top: 13px;
}

.filter span:last-child {
  font-family: Gotham regular;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #000;
}

.custom_select {
  position: relative;
  padding: 0 !important;
}

.fil_brand {
  position: relative;
}

.fil_brand
  .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  padding-left: 5px;
}

.fil_brand select {
  position: absolute;
  top: 0;
}

.inventory_tab .css-1gsv261 {
  border: none;
}

.inventory_tab .css-1aquho2-MuiTabs-indicator {
  display: none;
}

.inventory_tab button {
  width: 132.75px;
  min-height: inherit;
  height: 36px;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  font-family: 'Baloo 2';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}

.inventory_tab button:first-child {
  border-right: 0;
  border-radius: 4px 0 0 4px;
}

.inventory_tab button:nth-child(2) {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.inventory_tab button:nth-child(3) {
  border-left: 0;
  border-radius: 0 4px 4px 0;
}

.inventory_tab .Mui-selected {
  background: #000000;
  color: #ffffff !important;
  border: 1px solid #000000;
  border-radius: 4px !important;
}

.inventory_share {
  background-image: url('../../../src/assets/images/work.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 40px 30px;
  color: #ffff;
  border-radius: 15px;
  margin: 50px 0;
}

.media_box ul {
  padding: 0;
  text-align: center;
  margin-bottom: 0;
}

.media_box ul li {
  list-style-type: none;
  display: inline-block;
  position: relative;
  text-align: center;
  margin-right: 120px;
}

.media_box ul li:last-child {
  margin-right: inherit;
}

.media_box ul li img[alt='arrow'] {
  position: absolute;
  right: -75px;
  top: 34%;
}

.media_box ul li p {
  font-family: 'Baloo 2';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 0;
  margin-top: 16px;
  color: #000000;
}

.inventory_share .heading_box h6 {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;

  color: #ffffff;
}

.inventory_share .heading_box h3 {
  font-family: 'Gotham Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 50px;
  /* identical to box height, or 139% */

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
}

.luxury_watches .fil_brand {
  /* display: flex;
  align-items: end;
  justify-content: end; */
  display: inline-flex;
  float: right;
  padding-top: 10px;
}

.fil_brand .css-1br08yh-MuiFormControl-root {
  margin-top: 0 !important;
}

.fil_brand .css-5euwsq {
  margin: 12px 8px 8px !important;
}
.fil_brand .css-5euwsq:last-child {
  margin-right: 0 !important;
}

.luxury_watches .fil_brand select {
  border: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #111111;
  width: auto;
  text-transform: uppercase;
}

.luxury_watches .fil_brand select option {
  background: #ffffff;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
  outline: none;
}

.luxury_watches {
  margin: 20px 0;
  margin-bottom: 0px;
}

.fil_brand .form-control:focus {
  box-shadow: none;
}

.lax_watch h2 {
  color: #181818;
  font-family: Gotham Medium;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0;
  text-transform: capitalize;
  margin-top: 17px;
}

.inventory_Detail .luxury_card {
  padding: 0 5px;
  min-height: 20vh;
}

.luxury_card h2 {
  text-align: left;
  margin-top: 76px;
  margin-bottom: 17px;
}

.luxury_card .card {
  background-color: var(--white_background);
  border: none;
  border-radius: 6px;
  padding: 0px;
  transition: 0.5s ease-out;
  margin-bottom: 30px;
}

/* .luxury_card .card:hover{
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
} */

#menu-BRAND .MuiMenu-list,
#menu-Model .MuiMenu-list,
#menu-CASE-SIZE .MuiMenu-list {
  height: 267px;
  overflow-x: hidden;
  overflow-y: scroll;
}

#menu-BRAND .MuiMenu-list li,
#menu-Model .MuiMenu-list li,
#menu-CASE-SIZE .MuiMenu-list li {
  font-size: 12px;
  font-style: normal !important;
}

.MuiMenu-list::-webkit-scrollbar {
  width: 5px;
}

/* #menu-BRAND .MuiPaper-roundeds{
  left: 1057px !important;
} */

/* .MuiMenu-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 0px;
} */

.MuiMenu-list::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px #ccc;
}

.luxury_card .card a:hover {
  text-decoration: none;
}

.luxury_card .card .card_img {
  background: transparent;
  width: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5px 5px 0px 0px;
  cursor: pointer;
  margin-bottom: 9px;
  border: 1px solid #f5f5f5;
  height: 270px;
}

.luxury_card .col-lg-3 {
  position: relative;
}

.luxury_card .card .card_img .watch_img {
  max-width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

/* .luxury_card .card .card_img .bookmark_img {
  position: absolute;
  top: 12px;
  right: 10px;
  cursor: pointer;
  z-index: 99;
} */
.luxury_card .bookmark_img {
  position: absolute;
  top: 20px;
  right: 17px;
  cursor: pointer;
  width: 32px;
  height: 25px;
}

.star_icon {
  position: absolute;
  width: 20px;
  top: 20px;
  left: 30px;
  cursor: pointer;
}

.luxury_card .card .card_body {
  padding: 5px 0px;
}

.luxury_card .card .card_body h5 {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 12px;
  color: #181818;
  text-decoration-line: underline;
  text-transform: uppercase;
  text-underline-offset: 2px;
  margin-bottom: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.luxury_card .card .card_body h6 {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 14px;
  color: #333333;
}

.luxury_card .card .card_body p {
  font-family: 'Gotham Light';
  font-style: normal;
  font-weight: 325;
  font-size: 11px;
  line-height: 13px;
  color: #7b7b7b;
  margin-bottom: 10px;
}

.luxury_card .card .card_body span {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  font-size: 15px;
  line-height: 17px;
  color: #111111;
}

.inventory_Detail .css-19kzrtu {
  padding: 0px !important;
  margin-top: 9px;
}

.inventory_Detail .css-waq1ca {
  border: none;
}

.luxury_card button {
  border: 1px solid #828282;
  border-radius: 10px;
  width: 322px;
  height: 50px;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #4f4f4f;
  background-color: #fff;
  margin-bottom: 75px;
}

.inventory_Detail .css-ttwr4n {
  display: none !important;
}

.inventory_Detail .css-waq1ca {
  padding: 15px;
}

.popular_select {
  width: auto;
  float: right;
  position: relative;
  top: -7px;
  border: none;
  right: 20px;
  padding: 0;
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

.popular_select:focus {
  box-shadow: none;
}

.popular_select::-ms-expand {
  display: none !important;
}

.popular_select option {
  padding: 15px;
}

.popular_select option {
  border: 0;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.fil_brand .MuiOutlinedInput-notchedOutline {
  border: none;
}

.fil_brand .MuiSelect-select {
  font-family: Gotham regular;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #111111;
  text-transform: uppercase;
}

.custom_select .MuiOutlinedInput-notchedOutline {
  border: none;
}

.custom_select .MuiSelect-select {
  font-family: Gotham regular;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 9px;
  color: #111111;
  text-transform: capitalize;
  padding-right: 0 !important;
  padding-left: 3px;
  padding-top: 13px;
}

.custom_select svg {
  display: none;
}

.all_filter_mob_show {
  display: none;
}

.pagination_div ul {
  float: right;
  margin-bottom: 40px;
}

.inventory_mob_hide {
  padding-right: 0;
}

.sel_all_mob .MuiInputBase-colorPrimary {
  width: 70%;
}

.no_data {
  text-align: center !important;
  margin: 30px;
  /* width: 100%; */
}

.inventory_Detail .col-lg-3 {
  padding-left: 10px;
  padding-right: 10px;
}

/* .inventory_Detail .col-lg-3:nth-of-type(odd){
     padding-right: 8px;
}
.inventory_Detail .col-lg-3:nth-of-type(even){
  padding-right: 8px;
} */

.customize_box .css-1iji0d4 {
  padding: 0 !important;
}

.customize_box .Mui-expanded {
  margin: 0 !important;
}

.customize_box .Mui-expanded .css-u7qq7e {
  padding: 0 !important;
  max-height: 200px !important;
  overflow: scroll;
  overflow-x: hidden;
  min-height: auto;
}

.customize_box .Mui-expanded .MuiTypography-body1 {
  font-weight: 600 !important;
}

.customize_box .Mui-expanded .css-9l3uo3 {
  line-height: 26px !important;
}
.customize_box .Mui-expanded .MuiCollapse-vertical,
.customize_box .MuiAccordion-region {
  margin-bottom: 10px !important;
  max-height: 210px !important;
  overflow: scroll;
  overflow-x: hidden;
  min-height: auto;
}

@media screen and (max-width: 1699px) {
  .luxury_card .card .card_img {
    height: 200px;
  }

  .inventory_share .heading_box h6 {
    font-size: 22px;
    line-height: 28px;
  }

  .inventory_share .heading_box h3 {
    font-size: 34px;
    line-height: 48px;
  }

  .luxury_card .card .card_body h5 {
    line-height: 20px;
  }
}

@media screen and (min-width: 1441px) and (max-width: 1699px) {
  .luxury_card .card .card_img {
    height: 270px;
  }
}

@media screen and (max-width: 1440px) {
  .luxury_card .bookmark_img {
    top: 4%;
  }

  .star_icon {
    top: 4%;
    left: 30px;
  }

  .luxury_card .card .card_img {
    height: 200px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1199px) {
  .media_box ul li {
    margin-right: 60px;
  }

  .media_box ul li img[alt='arrow'] {
    right: -40px;
  }
}

@media screen and (max-width: 991px) {
  .inventory_share .heading_box h6 {
    font-size: 20px;
    line-height: 20px;
  }

  .inventory_share .heading_box h3 {
    font-size: 26px;
    line-height: 40px;
  }

  .media_box ul li {
    margin-right: 40px;
  }

  .media_box ul li img[alt='arrow'] {
    right: -30px;
    width: 30%;
  }

  .inventory_tab button {
    width: 80px;
  }

  .luxury_card .card .card_img .watch_img {
    width: 100% !important;
    height: 100% !important;
    /* object-fit: cover; */
  }

  .luxury_card .card .card_img {
    height: 335px;
  }

  .customize_your_search h3 {
    font-family: 'Baloo 2';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #111111;
    margin-bottom: 0;
    padding-top: 10px;
  }

  .customize_your_search button {
    background: #ffffff;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    width: 100%;
    font-family: 'Baloo 2';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    color: #bfbfc7;
    height: 48px;
    justify-content: flex-start;
    padding-left: 10px;
    text-transform: capitalize;
  }

  .customize_box {
    width: 450px !important;
    padding: 30px;
  }

  .customize_box .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    box-shadow: none;
  }

  .customize_box .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root,
  .customize_box .css-15v22id-MuiAccordionDetails-root {
    padding: 0;
  }

  /* .customize_box .css-ahj2mt-MuiTypography-root {

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    color: #4f4f4f;
  } */
  .customize_box .css-dtzna4-MuiInputBase-root .MuiInputBase-input {
    padding: 14px 8px 8px 45px;
  }
}

@media screen and (max-width: 767px) {
  .media_box ul li p {
    font-size: 14px;
    line-height: 14px;
    margin-top: 5px;
  }
  .custom_select .MuiSelect-select {
    font-size: 12px;
    line-height: 15px;
  }
  .filter span {
    font-size: 12px;
  }
  .search_mob {
    display: none;
  }

  .inventory_share .heading_box h6 {
    font-size: 13px;
    line-height: 18px;
  }

  .inventory_share .heading_box h3 {
    font-size: 16px;
    line-height: 18px;
  }

  .media_box ul li {
    margin-right: 30px;
  }

  .media_box img[alt='share'] {
    width: 24px;
    height: 24px;
  }

  .media_box ul li img[alt='arrow'] {
    right: -19px;
    top: 10px;
  }

  .inventory_share {
    padding: 25px 15px;
    margin: 20px 0;
    background-image: url('../../../src/assets/images/work1.png');
  }

  .inventory_mob_hide {
    display: none;
  }

  .all_filter_mob_show {
    display: block;
  }

  .Popularity .filter {
    width: 100% !important;
    text-align: left;
  }

  .customize_your_search h3 {
    font-family: 'Gotham Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    color: #111111;
  }

  .customize_your_search button {
    background: #ffffff;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    width: 100%;
    font-family: 'Baloo 2';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    color: #bfbfc7;
    height: 48px;
    justify-content: flex-start;
    padding-left: 10px;
    text-transform: capitalize;
  }

  .customize_box {
    width: 450px !important;
    padding: 30px;
  }

  .customize_box .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    box-shadow: none;
  }

  .customize_box .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root,
  .customize_box .css-15v22id-MuiAccordionDetails-root {
    padding: 0;
  }

  .customize_box .css-ahj2mt-MuiTypography-root {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #4f4f4f;
  }

  .customize_box .css-dtzna4-MuiInputBase-root .MuiInputBase-input {
    padding: 14px 8px 8px 45px;
  }

  .lax_watch h2 {
    font-family: 'Gotham Bold';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    color: #242833;
    margin-bottom: 0;
  }

  .luxury_card .card .card_img .bookmark_img {
    top: 40px;
    right: 16px;
  }

  .luxury_card .card .card_img {
    height: 245px;
  }

  .padding_right {
    padding-right: 12px;
  }

  .padding_left {
    padding-left: 12px;
  }

  .luxury_card .card {
    margin-bottom: 15px;
  }

  .luxury_watches {
    margin: 8px 0;
    margin-bottom: 0px;
  }
}

.luxury_card .sold_cart {
  opacity: 0.7;
  pointer-events: none;
}

.sold_cart .centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: Red;
  font-weight: bolder;
  border: 3px solid red;
  border-left: none;
  border-right: none;
  font-size: xx-large;
}

@media screen and (max-width: 575px) {
  .sel_all_mob .MuiInputBase-colorPrimary {
    width: 50%;
  }

  .modal_content {
    font-size: 18px !important;
    line-height: 18px !important;
  }
  .Popularity {
    width: 100%;
  }
  .luxury_card .card .card_img {
    height: 262.5px;
  }
}

@media screen and (max-width: 425px) {
  .luxury_card .card .card_img {
    height: 187.5px;
  }

  .inventory_share {
    padding: 25px 5px;
    background-position: inherit;
  }

  .luxury_card .card .card_img .bookmark_img {
    top: 20px;
  }

  .inventory_Detail .css-waq1ca {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .luxury_card button {
    margin-bottom: 50px;
  }

  .customize_box {
    width: 350px !important;
  }

  /* .inventory_share .col-12 {
    padding: 0 !important;
  } */
  .inventory_share .heading_box h3 {
    font-size: 16px;
    line-height: 24px;
    width: 145px;
    text-align: left;
  }

  /* .custom_select_sort .MuiSelect-select{
       position: relative;
top: 2px;
      padding-top: 0;
  } */

  .css-1ytbok2 {
    width: 340px !important;
  }

  .luxury_card .card .card_body h5 {
    line-height: 20px;
  }

  /* .Popularity .filter:last-child{
    padding: 0 5px!important;
  }
  .Popularity .filter:first-child {
    padding: 0 5px !important;
} */
}

.luxury_card .card .card_body h3 {
  font-family: Gotham regular !important;
  font-weight: 400 !important;
  color: red !important;
  font-size: 11px !important;
  line-height: 14px !important;
  color: #7b7b7b !important;
  margin-bottom: 1px;
}

@media screen and (max-width: 399px) {
  .media_box ul li {
    margin-right: 22px;
  }

  .media_box ul li img[alt='arrow'] {
    right: -12px;
  }

  .Popularity .filter:first-child {
    padding: 0 5px !important;
    height: 40px;
  }

  .customize_your_search button {
    height: 40px;
  }
  .padding_right {
    padding-right: 5px;
  }

  .padding_left {
    padding-left: 5px;
  }

  .inventory_share .col-12 {
    padding-left: 5px !important;
  }

  .luxury_card .card .card_img {
    height: 165px;
  }
}

@media screen and (max-width: 375px) {
  .media_box ul li {
    margin-right: 14px;
  }

  .luxury_card button {
    width: 100%;
  }

  .luxury_card .card .card_img {
    height: 162.5px;
  }
}

@media screen and (max-width: 320px) {
  .customize_your_search h3 {
    font-size: 14px;
  }

  .customize_box {
    width: 290px !important;
  }

  .css-1ytbok2 {
    width: 300px !important;
  }
  .luxury_card .card .card_img {
    height: 135px;
  }
}

@media screen and(-webkit-min-device-pixel-ratio:0) {
  .luxury_watches .fil_brand select {
    appearance: none;
  }
}
