p.bg-text {
  position: absolute;
  top: 41%;
  left: 0;
  width: 100%;
  text-align: center;
  border: 1px solid #ccc;
  padding: 30px 0;
  background-color: #00000075;
  color: #fff;
  /* opacity: 60; */
  font-weight: bold;
  font-size: 20px;
  border-radius: 5px;
}
.graph_box {
  position: absolute !important;
  left: 50%;
  top: 650px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #4f4f4f;
  box-shadow: 240;
  border-radius: 6px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #4f4f4f;
  border-radius: 12px;
  width: 280px;
  height: 140px;
  display: flex;
  padding: 30px;
  z-index: 9;
}
.graph_container {
  text-align: center;
}
.graph_modal_text {
  font-family: 'Gotham regular' !important;
  font-style: normal !important;
  font-weight: 325 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  text-align: center !important;
  color: #000000 !important;
}

@media only screen and (max-width: 320px) {
  .graph_box {
    width: 220px;
  }
}

@media only screen and (max-width: 767px) {
  .graph_box {
    top: 590px;
  }
}
