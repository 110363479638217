.sec_contact_area {
  padding-top: 40px;
  padding-bottom: 80px;
}

.contact_left {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px;
}

.contact_left h3 {
  color: #000;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 20px;
  font-family: Gotham Bold;
}
.contact_form input {
  border: 1px solid #bdbdbd;
  border-radius: 6px;
  height: 40px;
  width: 100%;
  color: #828282;
  font-weight: 400;
  font-size: 16px;
  font-family: Gotham light;
}

.contact_form textarea {
  border: 1px solid #bdbdbd;
  border-radius: 6px;
}
button.submit_btn {
  background: #000;
  width: auto;
  height: 36px;
  border-radius: 100px;
  color: #fff;
  border: none;
  line-height: 31px;
  font-weight: 500;
  margin-top: 15px;
  font-size: 12px;
  padding: 0px 35px;
}

.contact_right h3 {
  color: #000;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 20px;
  font-family: Gotham Bold;
}

.contact_right {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 30px;
  height: 100%;
}
.our_info {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0dddd;
  padding-bottom: 35px;
}
.our_mail {
  width: calc(100% - 75px);
  text-align: left;
}
.ourmail_icon {
  margin-right: 20px;
}
.our_mail span {
  display: block;
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: #282828;
}
.our_mail a {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #4f4f4f;
}

.our_mail a:hover {
  text-decoration: none !important;
}

form.contact_form label {
  color: #333;
  font-family: Gotham light;
}

@media only screen and (max-width: 1440px) {
  .ourmail_icon img {
    width: 28px;
    height: 28px;
  }
  .ourmail_icon {
    margin-right: 10px;
  }

  .our_mail span {
    line-height: 15px;
  }

  .contact_right {
    padding: 30px 14px;
  }
}

@media only screen and (max-width: 1440px) {
  form.contact_form label {
    text-align: left;
    display: block;
  }

  .contact_right h3 {
    text-align: left;
  }

  .our_mail span {
    text-align: left;
  }

  button.submit_btn {
    float: left;
  }
  .our_mail a {
    font-size: 13px;
}
}

@media only screen and (max-width: 767px) {
  .contact_left {
    margin-bottom: 30px;
    padding: 30px 20px;
  }
  .our_mail {
    width: auto;
  }
  form.contact_form label {
    font-size: 12px;
  }
  .contact_left h3, .contact_right h3 {
    text-align: left;
    font-size: 18px;
  }
  .contact_form input{
    font-size: 12px;
  }
}


@media only screen and (min-width: 768px) and (max-width: 991px)  {
   
  .our_mail span{
    font-size: 14px;
    line-height: 10px !important;
  }
  .ourmail_icon img {
    width: 22px;
    height: 22px;
}
.ourmail_icon {
  margin-right: 7px;
}

.contact_right h3{
  font-size: 22px;
}
.our_mail a{
  font-size: 10px !important;
}

.our_info{
  padding-bottom: 20px;
}
.contact_left{
  padding: 25px 15px;
}
.contact_right {
  padding: 25px 15px;
}

}


@media screen and (min-width:768px){
  .contact_left h3, .contact_right h3{
    font-size: 18px;
  }
  form.contact_form label{
    font-size: 14px;
  }
  .contact_form input{
    font-size: 12px;
  }
  button.submit_btn{
    background: #000;
    width: auto;
    height: 36px;
    border-radius: 0;
    color: #fff;
    border: none;
    line-height: 31px;
    font-weight: 500;
    margin-top: 15px;
    font-size: 12px;
    padding: 0px 44px;
  }
  .our_mail span{
    font-size: 13px;
  }
  .our_mail a{
    font-size: 11px;
  }
  .sec_contact_area{
    min-height: 60vh;
  }
}