.thumbnail_img {
  width: 100%;
  height: 100%;
}
.thumbnail_slider .slick-slide { 
  /* width: 120px !important; */
  /* height: 120px !important; */
  cursor: pointer;
  }
/* .thumbnail_slider_main .slick-slide{
  height: 600px;
} */
.thumbnail_slider_main .slick-slide div{
 outline: none !important;
}
  .thumbnail_slider .slick-next:before ,.thumbnail_slider .slick-prev:before{
    background-size: 40%;
  }

  .thumbnail_slider .slick-slide > div {
  margin-right: 8px;
  }
  @media only screen and (max-width: 425px){
    
  }
 