.footer {
  background-color: var(--background_light_grey);
  padding: 40px 0px 0px;
  text-align: left;
}

.footer h4 {
  font-family: 'Gotham semi Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #181818;
}

.logo_img {
  width: 158px;
}

.logo_img img {
  max-width: 100%;
}

.footer ul li {
  list-style-type: none;
  margin-bottom: 13px;
}

.footer ul .foot_link {
  font-size: 13px;
  line-height: 22px;
  color: #333333;
  font-family: 'Gotham regular';
  font-style: normal;
  font-weight: 325;
}

/* .footer ul li .foot_link:hover {
  text-decoration: none;
} */

.border-footer {
  border: 1px solid var(--border-grey-color);
}

.copyright p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--list_color);
}

.copyright ul li {
  list-style-type: none;
  display: inline-block;
  margin: 0px 10px;
}

.footer_mob {
  background-image: url('../../assets/images/foot_mob.png');
  background-position: center;
  padding: 55px 0px;
  background-repeat: no-repeat;
  background-size: cover;
}

.footer_mob h3 {
  font-weight: 800;
  font-size: 30px;
  line-height: 47px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: var(--white_text_Color);
}

.footer_mob p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--white_text_Color);
}

.footer_mob p span {
  font-weight: bold;
}

.footer_mob .address_list li {
  list-style-type: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--white_text_Color);
}

.footer_mob_bottom {
  background-color: var(--black_background_color);
  padding: 0px 0px 13px;
}

.footer_mob_bottom p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--white_text_Color);
}
.footer_mob_bottom ul li {
  list-style-type: none;
  display: inline-block;
  margin: 0px 10px;
}

.contact_list li {
  display: inherit;
  word-break: break-all;
}
.footer_mob_bottom .mob_logo {
  max-width: 100%;
  margin: 10px 0px 0px;
}
.social_icon {
  text-align: right;
}
.copyright {
  background-color: #f5f5f7;
  border-top: 1px solid #d6d6d6;
  padding: 12px 70px;
  padding-bottom: 0;
}
.paddin_right_105 {
  padding-left: 222px;
}
.position_absolute {
  right: 50px;
  position: absolute;
}
.copyright:first-child {
  text-align: left;
}

.copyright ul img {
  width: 32px;
}
/* @media screen and (max-width: 1366px) {
  img.footer_logo {
    width: 70%;
  }
} */
@media screen and (max-width: 1024px) {
  .position_absolute {
    right: 50px;
  }
}
@media screen and (max-width: 991px) {
  img.footer_logo {
    width: 10%;
    margin-bottom: 20px;
  }
  .paddin_right_105 {
    padding-left: 15px;
  }
  .copyright {
    padding: 12px 45px;
  }
  .position_absolute {
    right: 24px;
  }
}
@media screen and (max-width: 767px) {
  .footer_mob_bottom img[alt='icons.Logo'] {
    width: 100%;
  }
  .footer_mob_bottom {
    padding: 15px 0 !important;
  }
  .footer_mob {
    color: #fff;
  }
  .footer_mob li {
    list-style-type: none;
  }
  .copyright {
    text-align: center;
  }
  .social_icon {
    text-align: center;
  }
  .footer_logo {
    width: 100px;
    margin-bottom: 13px;
  }
  .footer {
    padding-top: 15px;
    padding-bottom: 0px;
  }
  .position_absolute {
    position: inherit;
    right: 0;
  }
  .copyright:first-child {
    text-align: center;
  }
  .footer ul li{
    margin-bottom: 3px;
  }
  .footer h4{
    line-height: 17px;
  }
}

@media screen and (max-width: 575px) {
  .position_absolute {
    margin-top: 0px;
  }
  .foot_link img {
    width: 14.16px;
  }
  .foot_link img[alt='locatin'] {
    width: 12.74px;
  }
}


@media screen and (min-width:768px) {
  .logo1-img1 {
    margin-left: 10%;
  }
}