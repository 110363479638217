.MuiTableCell-root {
  padding: 5px 10px !important;
  border: none !important;
  white-space: nowrap !important;
}
.table_blur {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  filter: blur(4px);
  -webkit-filter: blur(4px);
}
.moser_head table tbody tr td.MuiTableCell-root {
  padding: 5px 0px !important;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .moser_head table {
    /* table-layout: fixed;
    width: max-content !important; */
  }
}

@media screen and (max-width: 460px) {
  .moser_head table {
    /* table-layout: fixed;
    width: max-content !important; */
  }
}
