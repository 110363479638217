.sec_mywatch_area {
  padding: 40px 0px;
}
.sidebar ul {
  padding: 0px;
}

.sidebar li {
  padding: 10px 0 10px 10px;
  border-bottom: 1px solid #e6e8ed;
  list-style: none;
}

li.active a {
  color: #000;
}

.my_account_head {
  text-align: left;
}

.my_account_head h2 {
  font-size: 14px;
  line-height: 17px;
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  color: #242833;
}

.my_account_head p {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #333;
  font-family: 'Gotham light';
  margin: 15px 0px;
}

.myaccount_area {
  padding-left: 48px;
}

.personal_detail {
  margin-top: 30px;
}

.personal_detail h3 {
  font-size: 14px;
  line-height: 17px;
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  text-align: left;
}
.img__box {
  text-align: left;
  margin-top: 40px;
}

.send_code {
  width: 96px !important;
  height: 32px !important;
  left: auto !important;
  border-radius: 5px !important;
  background: #000 !important;
  border: none;
  color: #fff !important;
  font-size: 12px !important;
  position: absolute !important;
  right: -2px !important;
  top: 26px !important;
  padding: 0px !important;
}
.verify_code {
  width: 96px !important;
  height: 31px !important;
  left: auto !important;
  border-radius: 5px !important;
  background: #000 !important;
  border: none;
  color: #fff !important;
  font-size: 12px !important;
  position: absolute !important;
  right: 0 !important;
  top: 26px !important;
  padding: 0px !important;
}
.verify_code_success {
  width: 96px !important;
  height: 31px !important;
  left: auto !important;
  border-radius: 5px !important;
  background: #41ba77 !important;
  border: none;
  color: #fff !important;
  font-size: 12px !important;
  position: absolute !important;
  right: 0 !important;
  top: 26px !important;
  padding: 0px !important;
}

form.personal_detail_form label {
  font-size: 12px;
  text-align: left;
  display: block;
}
form.personal_detail_form .form-control {
  font-size: 12px;
}
.img__box input[type='file'] {
  font-size: 12px;
  width: 30px;
  height: 30px;
  opacity: 0;
  position: relative;
  z-index: 1020;
}
.verify_icon {
  margin-top: 5px;
  text-align: left;
  position: absolute;
  top: 0;
  right: 0px;
}

.img_inn {
  margin-bottom: 40px;
  position: relative;
}

button.my_accountsave,
button.my_accountsave_show {
  display: block;
  height: 34px;
  background: #000;
  color: #fff;
  border: none;
  border-radius: 25px;
  font-size: 12px;
  line-height: 33px;
  float: left;
  padding: 0px 30px;
}

.img__box .img_inn img {
  width: 148px;
  height: 148px;
  border-radius: 50%;
}
.app {
  width: 30px !important;
  height: 30px !important;
  border-radius: 100px !important;
  cursor: pointer;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%);
  position: absolute;
  left: 16%;
}
.app:hover {
  background: rgb(185, 184, 184);
  cursor: pointer;
}
.app img {
  width: 15px !important;
  height: 15px !important;
  border-radius: 0 !important;
  position: relative;
  bottom: 30px;
  left: 8px;
}
.personal_detail {
  margin-top: 30px;
  padding-bottom: 40px;
}

form.personal_detail_form {
  padding-bottom: 30px;
  border-bottom: 2px solid #e6e8ed;
}

form.change__pass {
  margin-top: 30px;
}
form.change__pass label {
  display: block;
  text-align: left;
  font-size: 12px;
  text-transform: uppercase;
}
form.change__pass input {
  font-size: 12px;
}

form.change__pass h3 {
  margin-bottom: 35px;
}

label.verified-label img {
  width: 18px;
  height: 18px;
}
label.verified-label {
  text-transform: uppercase;
  font-size: 14px;
  margin: 15px 0;
}
.my_accountsave_show {
  display: none !important;
}
@media screen and (max-width: 767px) {
  .my_accountsave {
    display: none !important;
  }
  .send_code {
    top: 23px !important;
  }
  .verify_icon {
    margin-top: 10px;
}
  .my_accountsave_show {
    display: block !important;
    margin-top: 10px;
  }
  .myaccount_area {
    padding-left: 0px;
    padding-top: 30px;
  }
  .sec_mywatch_area .col-12.mobv {
    padding: 0px;
  }

  .my_account_head h2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }

  .my_account_head p {
    margin: 5px 0px 5px;
    font-size: 12px;
    line-height: 21px;
  }

  .personal_detail h3 {
    font-size: 14px;
    margin-bottom: 20px !important;
  }
  .personal_detail {
    margin-top: 15px;
  }

  button.top_sp.my_accountsave {
    margin-top: 10px;
  }
  .img__box .img_inn img {
    width: 100px;
    height: 100px;
  }
  .img_inn {
    margin-bottom: 20px;
  }
  form.personal_detail_form label,
  form.change__pass label {
    font-size: 10px;
  }
  form.personal_detail_form {
    padding-bottom: 10px;
  }
  form.change__pass {
    margin-top: 20px;
  }
}
