.inner_banner {
  background-image: linear-gradient(to right, #fe9d32, #ffd157);
  height: 500px;
  text-align: left;
  display: flex;
  align-items: center;
}

.inner_text h3 {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  color: #ffffff;
  margin-bottom: 18px;
  text-transform: capitalize;
  width: 50%;
}
.inner_text p {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 0;
  width: 50%;
}
.about h4,
.discovered h4,
.new_way h4,
.believes h4 {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 42px;
  color: #000000;
  margin-bottom: 15px;
}
.new_way h4 {
  text-align: left;
  margin-bottom: 30px;
}
.about p,
.discovered p {
  font-size: 16px;
  line-height: 29px;
  color: #333;
  padding: 0;
}
.believes {
  background: #fafafa;
  padding: 30px 0;
}
.believes ul,
.new_way_text ul {
  padding: 0;
  margin: 0;
}
.believes ul li,
.new_way_text ul li {
  list-style-type: none;
  color: #333;
  font-size: 16px;
  line-height: 26px;
  display: flex;
  margin-bottom: 5px;
}
.believes ul li img,
.new_way_text ul li img {
  display: inline-block;
  width: 18px;
  height: 25px;
  margin-right: 10px;
}

.commitment {
  margin-top: 115px;
}
section.new_way {
  padding: 60px 0;
}
section.discovered {
  padding: 60px 0;
}
.discovered p {
  text-align: center;
}
.new_way_text img {
  width: 100%;
}

.banner_content h3 {
  font-family: 'Gotham Light';
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  padding-bottom: 10px;
  margin: 0px;
}
.banner_content h2 {
  font-size: 50px;
  color: #fff;
  font-family: 'Gotham Regular';
  padding-bottom: 20px;
  line-height: normal;
  margin: 0px;
}
.about_top {
  background-color: #000;
  color: #fff;
  padding: 60px 0px;
}
.banner_content p {
  font-size: 20px;
  font-family: 'Gotham regular';
  margin: 0px;
}
.sec_post {
  padding: 80px 0px 50px;
}
.post_head {
  text-align: center;
}
.post_head h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  font-family: 'Gotham Medium';
  color: #000;
  margin: 0;
  padding-bottom: 12px;
}
.post_head p {
  font-size: 16px;
  color: #000;
  font-family: 'Gotham regular';
  margin-bottom: 40px;
}
.about_center_img {
  text-align: center;
  /* height: 350px;
  width: 100%;
  overflow: hidden; */
}
.about_center_img img {
  /* padding: 0px 20px;
  object-fit: cover;
  max-width: 100%; */
  height: 500px;
}
.about2_img img{
  height: 495px;
}
.right_about {
  text-align: right;
}

span.about_icon {
  width: 50px;
  height: 50px;
  background: #202046;
  display: inline-flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-bottom: 15px;
}

.right_about h4 {
  font-size: 15px;
  font-weight: 700;
  font-family: 'Gotham Medium';
  color: #000;
}
.left_about h4 {
  font-size: 15px;
  font-weight: 700;
  font-family: 'Gotham Medium';
  color: #000;
}
.left_about p {
  font-size: 12px;
  color: #3e3e3e;
  margin: 0px;
}
.left_about {
  text-align: left;
  margin-bottom: 8px;
  overflow: hidden;
}
.right_success.left_about {
  text-align: left;
  overflow: hidden; 
  margin-bottom: 25px;
height: 150px;
 
}
.left_text_about .left_about{
  margin-bottom: 25px;
  height: 150px;
}
.right_about p {
  font-size: 12px;
  color: #3e3e3e;
  margin: 0px;
}
.right_about{
  margin-bottom: 25px;
  overflow: hidden;
  height: 150px;
  padding-top: 12px;
}
.botmm_spce {
  margin-bottom: 62px;
}

.yourself_area {
  padding: 20px 0px 60px;
}
.about_bottom h6 {
  font-weight: 400;
  font-size: 16px;
  color: #000;
  padding-bottom: 20px;
}

.about_bottom h3 {
  font-weight: 700;
  font-size: 27px;
  font-family: 'Gotham Medium';
  color: #000;
}
.about_bottom p {
  font-weight: 400;
  font-size: 13px;
  color: #000;
  padding-top: 10px;
  margin-bottom: 20px;
}
.about_bottom button {
  background: #000;
  color: #fff;
  font-size: 14px;
  border-radius: 12px;
  border: none;
  width: 150px;
  height: 40px;
  line-height: 40px;
}
.sell_btn a {
  background: #000000;
  border: 1px solid #000;
  font-family: Gotham Medium;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #fff;
  padding: 7px 22px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 15px;
  height: 36px;
}


@media screen and (min-width: 768px) {
  .mob_block {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .right_about p,
  .left_about p {
    font-size: 14px;
  }
}
@media screen and (max-width: 991px) {
  .right_about p,
  .left_about p {
    font-size: 13px;
  }
}
@media screen and (max-width: 767px) {
  .mob_block {
    display: block;
  }
  .mob_none {
    display: none;
  }
  .post_head h3 {
    font-size: 22px;
    padding:0px 15px 15px;
  }
  .post_head p {
    font-size: 14px;
  }
  .post_head p {
    margin-bottom: 20px;
  }
  .about_center_img img {
    margin-bottom: 30px;
  }
  .right_about {
    text-align: center;
  }
  .left_about {
    text-align: center;
  }
  .botmm_spce {
    margin-bottom: 10px;
  }

  .banner_content h3 {
    font-size: 16px;
    padding-bottom: 15px;
  }
  .banner_content h2 {
    font-size: 18px;
  }
  .banner_content p {
    font-size: 14px;
    line-height: 135.3%;
  }
  .banner_content {
    padding: 0px 20px;
  }
  .about_top {
    padding: 38px 0px;
  }
  .sec_post {
    padding: 20px 0px 0px;
  }
  .yourself_area {
    padding: 20px 0px 40px;
  }
  .about_bottom h3 {
    font-size: 22px;
  }
  .about_bottom h6 {
    padding-bottom: 12px;
    font-size: 14px;
  }
  .about_center_img{
    height: 390px;
  }
  .about_bottom p{
    padding: 0px 35px;
  }
  .mob_about{
    padding: 0px 10px;
    overflow-x: hidden;
  }
  .about_content p{
    padding-top: 0px !important;
  }
}

@media screen and (max-width: 1440px) {
  .about h4,
  .discovered h4,
  .new_way h4,
  .believes h4 {
    font-size: 36px;
    line-height: 34px;
  }
}
@media screen and (max-width: 1024px) {
  .believes ul li,
  .new_way_text ul li {
    text-align: left;
  }
}
@media screen and (max-width: 991px) {
  .about h4,
  .discovered h4,
  .new_way h4,
  .believes h4 {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .believes ul li,
  .new_way_text ul li {
    font-size: 14px;
    line-height: 24px;
  }
  .about_center_img img {
    width: 100%;
    height: auto;
  }
  .left_about h4,
  .right_about h4 {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .new_way_text {
    margin-top: 40px;
  }
  .about p,
  .discovered p {
    font-size: 14px;
    line-height: 27px;
    color: #333;
    padding: 0;
  }
  .believes {
    margin-top: 40px;
  }
  .commitment {
    margin-top: 60px;
  }
  section.new_way {
    padding-top: 16px;
  }
  .inner_text p {
    line-height: 22px;
  }
  span.about_icon{
    height: 40px;
    width: 40px;
    margin:0px;
    margin-bottom: 15px;
  }
  span.about_icon img{
    width: 60%;
  }
  .watch_about_img{
    height: 210px;
    overflow: hidden;
    margin:0px 0px 20px;
  }
  .watch_about_img img{
    max-width: 100%;
    height: 100%;
  }
}


@media screen and (min-width:768px){
  .banner_content h3{
    font-size: 20px;
  }
  .banner_content h2{
    font-size: 27px;
  }
  .banner_content p{
    font-size: 15px;
  }
  .about_top{
    padding: 40px 0px;
  }
  .post_head h3{
    font-size: 27px;
  }
  .post_head p{
    font-size: 12px;
  }
}



/*new mobile*/
.about_content h4{
  font-size: 14px;
  line-height: 13px;
  font-weight: 700;
  font-family: 'Gotham Medium';
  color: #000;
}
.about_content p{
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  font-family: 'Gotham Regular';
  color: #000;
}
