.customize_box .css-c84lw9 {
  width: 100% !important;
  margin-left: 0;
  height: 50px;
  background: #ececec;
  border-radius: 4px;
  margin-top: 14px;
}
.customize_box .css-dtzna4-MuiInputBase-root {
  width: 100% !important;
}
.customize_box .css-13k305h img {
  width: 100%;
  color: #ccc;
}
.customize_box h2 {
  text-transform: none;
  font-family: Gotham Medium;
}
.customize_box .MuiAccordion-gutters {
  box-shadow: none !important ;
}
.customize_box p {
  font-family: Gotham light;
  font-style: normal !important ;
  font-weight: 400 !important ;
  font-size: 14px !important ;
  line-height: 22px !important ;
  /* text-transform: uppercase !important ; */
  color: #4f4f4f !important ;
}
.customize_box input {
  padding: 14px 8px 8px 45px !important ;
}
