/* .autocomplete .suggestions {
  padding: 0 !important;
  max-height: 200px;
  min-height: auto;
  overflow: scroll;
  overflow-x: auto;

  width: 227px;
  margin-top: 16px;
  border: 1px solid #ccc;
  padding-left: 15px !important;
  padding-top: 10px !important;
  border-radius: 5px;
  margin-bottom: 0;

}
.autocomplete .suggestions li {
  list-style-type: none;
  margin-bottom: 5px;
  color: #333;
  font-family: Gotham light;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  cursor: pointer;
  text-transform: capitalize;
} */


.autocomplete .suggestions::-webkit-scrollbar {
  width: 2px;
}

.autocomplete .suggestions::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
  border-radius: 2px;
}

.autocomplete .suggestions::-webkit-scrollbar-thumb {
  border-radius: 1px;
  -webkit-box-shadow: inset 0 0 6px rgba(165, 163, 163, 0.5); 
}
