.drop_slider {
  display: none;
}
.margin_top64 {
  margin-top: 2px !important;
}
.margin_boottom_40 {
  margin-bottom: 0px !important;
}
.just_droped .star_icon {
  position: absolute;
  width: 20px;
  top: 5%;
  left: 35px;
  cursor: pointer;
}
.forgot_password a {
  font-weight: 400;
  font-size: 16px;
  line-height: 50px;
  color: #000 !important;
  text-decoration: underline;
}
.no_featured {
  text-align: center !important;
  margin: 30px 0;
  width: 100%;
}
.sold_cart {
  opacity: 0.7;
  pointer-events: none;
}

.sold_cart .centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: Red;
  font-weight: bolder;
  border: 3px solid red;
  border-left: none;
  border-right: none;
  font-size: xx-large;
}

@media screen and (max-width: 767px) {
  .just_drop_mob_hide {
    display: none;
  }
  .drop_slider {
    display: block;
  }
}
