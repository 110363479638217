.favorites .card {
  border: none;
}
.favorites .col-lg-3 {
  position: relative;
  margin-bottom: 16px;
  padding-right: 8px;
}
.favorites .card .card_img {
  align-items: center;
  background: transparent;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 270px;
  justify-content: center;
  margin-bottom: 9px;
  overflow: hidden;
  padding: 30px;
  position: relative;
  width: 100%;
}
.favorites .card .card_img .watch_img {
  border-radius: 10px;
  height: 100%;

  /* object-fit: cover; */
}
.favorites img[alt='Bookmark'] {
  cursor: pointer;
  height: 23px;
  position: absolute;
  right: 18px;
  top: 3%;
  width: 28px;
}
.favorites .card .card_body h5 {
  font-family: Gotham Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  color: #181818;
  line-height: 12px;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  margin-bottom: 5px;
  text-underline-offset: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
.favorites .card a:hover {
  text-decoration: none !important;
}
.favorites .card .card_body p {
  color: #7b7b7b;
  font-family: Gotham Light;
  font-size: 11px;
  font-style: normal;
  font-weight: 325;
  line-height: 13px;
  margin-bottom: 10px;
}
.favorites .card .card_body h3 {
  color: #7b7b7b;
  font-family: Gotham regular;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 1px;
}
.favorites .card .card_body span {
  color: #111;
  font-family: Gotham Medium;
  font-size: 15px;
  font-style: normal;
  font-weight: 350;
  line-height: 17px;
}
.favorites {
  min-height: 100vh;
  padding: 50px 0;
}
.no_favourites {
  margin: 30px 0;
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 1440px) {
  .favorites .card .card_img {
    height: 269.5px;
  }
}
@media screen and (max-width: 1366px) {
  .favorites .card .card_img {
    height: 245.5px;
  }
}
@media screen and (max-width: 1025px) {
  .favorites .card .card_img {
    height: 224.5px;
  }
}

@media screen and (max-width: 991px) {
  .favorites .card .card_img {
    height: 164.5px;
  }
}
@media screen and (max-width: 767px) {
  .favorites .card .card_img {
    height: 265px;
  }
}

@media screen and (max-width: 575px) {
  .favorites .row {
    padding: 0 15px;
  }
}
@media screen and (max-width: 425px) {
  .favorites .card .card_img {
    height: 189.5px;
  }
}
@media screen and (max-width: 375px) {
  .favorites .card .card_img {
    height: 164.5px;
  }
}
