/* .container {
  max-width: 1170px;
} */
.disabled-btn :disabled {
  color: red;
}
.product_view.product_view {
  text-align: left;
}
.slider_view {
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 27px;
}
.slider_view_border {
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 52px;
}
.slider_view img {
  /* padding: 2px; */
}
/* .product_view .card_img {
  width: 668px;
  height: 668px;
} */
/* .moser {
  padding-left: 30px;
} */
.inventory_box {
  margin: 16px 0;
  margin-bottom: 50px;
}
.inventory {
  display: flex;
  align-items: center;
  align-items: baseline;
  margin-bottom: 0;
  padding-top: 12px;
}
.scrol_dilog p {
  font-size: 14px;
}
.MuiSkeleton-rectangular {
  width: 100% !important;
}
.btn2.btn_yellow {
  width: 100%;
  height: 40px;
  background-color: #000;
  text-transform: uppercase;
  font-family: 'Baloo 2';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 0;
}
.btn2.btn_yellow:hover {
  background-color: #000 !important;
}
.inventory a {
  font-family: 'Gotham regular';
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 14px;
  color: #0f58e3;
}
.inventory h6 {
  font-family: 'Gotham regular';
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 14px;
  color: #181818;
  margin-bottom: 0px;
  position: relative;
  top: 1px;
  text-transform: capitalize;
}
.inventory img {
  margin: 0 10.5px;
  position: relative;
  top: 2px;
}
.ProductViewSlider {
  /* padding-right: 25px;
  padding-left: 15px; */
}
/* .marg_top_20_show_mob {
  display: none;
} */
.moser h3 {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  font-size: 21px;
  line-height: 22px;
  color: #181818;
  margin-bottom: 16px;
  text-transform: capitalize;
}
.moser h6 {
  font-family: 'Gotham regular';
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 13px;
  color: #4f4f4f;
}
.slider_view .mob_hide {
  /* width: 100%;
  height: 400px; */
  width: 100%;
  height: 515px;
  object-fit: cover;
  border-radius: 10px;
}
.moser h4 {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 16px;
  color: #181818;
  margin-top: 16px;
  margin-bottom: 13px;
}
.moser h4 span {
  font-family: 'Gotham regular';
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #4f4f4f;
  position: relative;
  top: -6px;
}
.moser h5 {
  font-family: 'Gotham regular';
  font-style: normal;
  font-weight: 325;
  font-size: 12px;
  line-height: 14px;
  color: #ff2e00;
}

.pre_owned {
  display: flex;
  padding-left: 0px;
  align-items: baseline;
  margin: 15px 0;
}
.pre_owned img {
  width: 15px;
  margin-right: 5px;
}
.tall_btn {
  background: #000000;
  width: 100%;
  height: 50px;
  font-family: 'Baloo 2';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
  border: none;
}

.pre_owned li {
  font-family: 'Gotham regular';
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  list-style-type: none !important;
  position: relative;
  margin-right: 10px;
}

/* .pre_owned li:after {
  position: absolute !important;
  content: '' !important;
  color: #000;
  border: 1px solid #000 !important;
  height: 3px;
  width: 3px;
  border-radius: 100px;
  background: #000;
  left: -12px;
  top: 5px;
} */
.offer_btn {
  display: block;
  width: 100%;
}
.buy_now_again {
  text-align: center;
  width: 100%;
  margin-right: 0px;
}
.moser {
  overflow-x: hidden;
}

.payment .lorem {
  font-size: 16px;
  line-height: 16px;
  color: #525151;
  font-family: Gotham regular;
  margin: 20px 0;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  width: 100%;
}

.payment p img {
  width: 24px;
  margin-right: 5px;
}
.buy_now_again p {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 0;
  padding: 11px 0;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid #828282;
  color: #000000;
  border-bottom: none;
}
.buy_now_again .form-group {
  margin-bottom: 0;
}
.buy_now_again .form-group input {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #000000;
  padding: 0;
  border: none;
  border: 1px solid #828282;
  border-bottom: none;
  border-radius: 0;
}

.buy_now_again .form-group input::placeholder {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #000000;
  padding: 0;
  margin-bottom: 1px;
  border: none;
}
.offer_btn button {
  width: 100%;
  height: 40px;
  /* background: #828282; */
  background-color: #ef841a;
  text-transform: uppercase;
  font-family: 'Baloo 2';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 0;
}
.offer_btn button:hover {
  background-color: #ef841a;
}
.offer_btn .btn1 {
  background-color: green;
}
.offer_btn .btn1:hover {
  background-color: green;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
/* .btn1.make_offer {
  background-color: #008000;
  color: #fff !important;
} */
.offer_btn .btn2:hover {
  background: #828282;
}
.price_history {
  text-align: left;
  margin-top: 40px;
}
.price_history h3 {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  /* font-size: 24px;
  line-height: 29px; */
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  /* margin-bottom: 30px; */
  margin-bottom: 20px;
  text-transform: capitalize;
}
.price_box {
  display: flex;
  align-items: center;
}
.price {
  margin-left: 2px;
  padding-top: 20px;
}
.price h6 {
  font-family: 'Gotham regular';
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.price p {
  font-family: 'Gotham regular';
  font-style: normal;
  font-weight: 325;
  font-size: 12px;
  line-height: 17px;
  color: #4f4f4f;
}
.need_reach {
  text-align: right;
  padding-top: 5px;
}
.need_reach p {
  font-family: 'Gotham regular';
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.need_reach button {
  width: 108px;
  height: 36px;
  border: 1px solid #181818;
  background-color: #000;
  line-height: 14px;
  color: #fff;
  margin-left: 0px;
  margin-top: 15px;
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
}
.bid_recived {
  text-align: left;
  /* margin-top: 60px; */
  margin-top: 40px;
  position: relative;
}

.bid_recived .container {
  border-bottom: 1px solid #d6d6d6;
  min-height: 50px;
  max-height: 300px !important;
  overflow: scroll;
  overflow-x: hidden;
}

.bid_recived .container::-webkit-scrollbar {
  width: 2px;
}

/* .bid_recived .container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(156, 154, 154, 0.3); 
  border-radius: 10px;
} */

.bid_recived .container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(156, 154, 154, 0.3);
}

.bid_recived h3 {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 20px;
  color: #242833;
  margin-bottom: 40px;
}
.bid_recived .date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  position: relative;
}
.date .amount {
  position: absolute;
  right: 10%;
}
.date .red_text {
  position: absolute;
  color: red;
  right: 10%;
}
.date h6 {
  font-family: 'Gotham regular';
  font-style: normal;
  font-weight: 325;
  font-size: 12px;
  line-height: 22px;
  color: #343a40;
  text-transform: capitalize;
}
.date p {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #333333;
}
.date h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #333333;
}
.details {
  text-align: left;
  /* margin-top: 60px; */
  margin-top: 30px;
}

.details h3 {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 17px;
}
.details h5 {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 16px;
}
.details .basic_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 16px;
  position: relative;
  padding-bottom: 0px;
}
.details h6 {
  font-family: 'Gotham regular';
  font-style: normal;
  font-weight: 325;
  font-size: 12px;
  line-height: 24px;
  color: #4f4f4f;
}
.basic_info p {
  font-family: 'Gotham regular';
  font-style: normal;
  font-weight: 325;
  font-size: 12px;
  line-height: 22px;
  color: #828282;
  position: absolute;
  left: 45%;
  top: -4%;
}

.details .heading {
  margin-top: 30px;
}
.description h3 {
  font-family: 'Gotham regular';
  font-style: normal;
  font-weight: 325;
  font-size: 13px !important;
  line-height: 17px !important;
  color: #242833 !important;
}
.description p {
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #4f4f4f;
  margin-bottom: 25px;
}
.border_0 {
  border-bottom: 0 !important;
  /* margin-bottom: 75px !important; */
  margin-bottom: 30px !important;
}
.line_chart {
  margin-top: 48px;
  position: relative;
}
.line_chart_blur {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
.line_chart_blur .bg-text {
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  border: 3px solid #f1f1f1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}
.line_chart ul {
  padding: 0;
  padding-left: 12px;
  margin-bottom: 8px;
}
.line_chart ul li {
  list-style-type: none;
  display: inline-block;
  font-family: 'Gotham regular';
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
  margin-right: 30px;
}

.line_chart .chart_date {
  font-family: Gotham light;
  font-size: 14px;
  line-height: 14px;
  color: #37c1bc;
  text-align: center;
  margin-bottom: 0;
}
.line_chart .price {
  position: absolute;
  left: -5%;
  top: 39%;
  transform: rotate(-90deg);
}
.payment ul {
  margin-top: 39px;
  padding: 0;
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 25px;
}
.payment ul li {
  list-style-type: none;
  font-family: 'Gotham regular';
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
  margin-bottom: 11px;
  text-transform: inherit;
}
.payment ul li p {
  margin-bottom: 0;
}
.payment ul li img {
  margin-right: 11px;
  width: 24px;
}
.breakdown_content {
  margin: 10px 0;
}
.breakdown_content .break_first_content p {
  font-weight: 325;
  font-family: Gotham regular;
  font-size: 14px;
  line-height: 24px;
  color: #000;
  margin-bottom: 0;
  text-align: left;
  margin-top: 0;
}
.breakdown_content .break_first_content span {
  font-weight: 325;
  font-family: Gotham regular;
  font-size: 14px;
  line-height: 24px;
  color: #000;
  display: block;
  text-align: right;
}
.border-bottom-div1 {
  border-bottom: 2px solid #e6e8ed;
  padding: 0 0 10px;
  margin-bottom: 20px;
}
.breakdown_content .break_other_content p {
  font-weight: 350;
  font-family: Gotham medium;
  font-size: 14px;
  line-height: 24px;
  color: #000;
  margin-bottom: 5px;
  text-align: left;
  margin-top: 0;
}

.breakdown_content .break_other_content span {
  font-weight: 350;
  font-family: Gotham light;
  font-size: 14px;
  line-height: 24px;
  color: #000;
  display: block;
}
.price_vicle2 .price_box_view {
  width: 47%;
  height: 60px;
  border: 1px solid #bdbdbd;
  border-radius: 12px;
  margin-right: 18px;
  display: inline-block;
  margin-bottom: 10px;
  padding: 10px;
  vertical-align: top;
}
.price_box_view h1 {
  font-family: 'Gotham regular';
  font-style: normal;
  font-weight: 325;
  font-size: 12px;
  line-height: 13px;
  color: #828282;
}
.price_box_view h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
}
.price_box_view input {
  width: 100%;
  border: none;
  outline: none;
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 325;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
}
.month_text {
  width: 95px;
  height: 50px;
  border: 1px solid #bdbdbd;
  border-radius: 100px;
  text-align: center;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
  padding-top: 10px;
  background-color: #fff;
  cursor: pointer;
}
.month .select_month {
  background: #555555;
  color: #fff;
}
.price_vicle2 p {
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #828282;
  text-align: left;
  margin-top: 0;
}
.row_price {
  display: flex;
  justify-content: space-between;
}
.drawer_box {
  width: 445px !important;
  padding: 30px;
  height: 600px !important;
}

.drawer_box label {
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  color: #333333;
  position: relative;
  top: 15px;
  left: 15px;
  z-index: 9;
}
.drawer_box button {
  background: #000000;
  border-radius: 6px;
  width: 100%;
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  height: 50px;
  border: none;
  margin-top: 10px;
  margin-bottom: 20px;
}
.drawer_box p {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  color: #9da0a6;
  text-align: center;
}
.drawer_box .css-1pnmrwp-MuiTypography-root {
  font-family: 'Baloo 2';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 30px;
  color: #bdbdbd;
  border-right: 1px solid #e0e0e0;
  padding-right: 10px;
}
.drawer_box .mistake_box {
  display: flex;
  align-items: baseline;
}
.drawer_box .mistake_box img {
  margin-left: 30px;
  margin-right: 13px;
  position: relative;
  top: 4px;
}
.drawer_box h2 {
  font-family: 'Baloo 2';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #111111;
  text-transform: initial;
}
.drawer_box .MuiOutlinedInput-root {
  width: 100%;
}

.close_btn {
  position: absolute;
  right: 34px;
  cursor: pointer;
}
.amountNotLogin {
  position: absolute !important;
  right: 10%;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
.dateNotLogin {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
.login_btn {
  height: 36px;
  margin-top: 18px;
  background: #000000;
  border-radius: 100px;
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  border: none;
  color: #ffffff;
  padding: 0px 25px;
}
.login_box {
  position: absolute !important;
  left: 50%;
  top: 310px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 240;
  border-radius: 6px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #4f4f4f;
  border-radius: 12px;
  width: 280px;
  height: 140px;
  display: flex;
  z-index: 9;
}
.login_container {
  padding: 20px 15px 10px 15px;

  text-align: center;
}
.modal_text {
  font-family: 'Gotham regular' !important;
  font-style: normal !important;
  font-weight: 325 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  text-align: center !important;
  color: #000000 !important;
}
.need_reach_show {
  display: none;
}
.pre_owned li:last-child {
  margin-right: 0;
}
.Cal_mob_show {
  display: none;
}
.payment p {
  font-family: Gotham regular;
  display: inline-block;
  font-weight: 325;
  font-size: 14px;
  line-height: 14px;
  color: #000;
  margin-bottom: 8px;
}
.cell_phone .sendOTP {
  position: absolute;
  top: 12px;
  right: 0;
  width: 100px;
  height: 54px;
  border-radius: 0 4px 4px 0;
  color: #fff !important;
}
.cell_phone .sendOTP:hover,
.cell_phone .verifyOTP:hover {
  background-color: #000000;
}
.cell_phone .verifyOTP {
  position: absolute;
  top: 12px;
  right: 0;
  width: 130px !important;
  height: 54px !important;
  border-radius: 0 4px 4px 0;
  color: #fff !important;
}
.marg_top_20 {
  display: none;
}
@media screen and (max-width: 1699px) {
  .pre_owned li {
    font-size: 12px;
    margin-right: 6px;
  }
  /* .offer_btn button {
    width: 190px;
  } */
  .moser {
    padding-left: 15px;
  }
  .need_reach {
    text-align: center;
    width: 140px;
    position: relative;
    top: 13px;
  }
  .need_reach button {
    margin-left: 0;
  }
  .payment .lorem {
    font-size: 13px;
  }
  .payment p {
    font-size: 13px;
  }

  .vertical_slider .slick-next {
    right: 67px !important;
  }
  .radio_buttons label span {
    background: transparent;
  }
  .radio_buttons label span em {
    position: absolute;
    margin: AUTO;
    top: 0px;
    bottom: 0px;
    max-width: 100%;
    height: 10px;
    font-style: normal;
    right: 0px;
    left: 0px;
  }
  .finance_calculator .Show_breakdown {
    margin-top: -23px !important;
  }
  .finance_calculator h3::before,
  .finance_calculator h3::after {
    width: 100px;
  }
  .finance_calculator h4 {
    margin: 5px 0px 11px;
    font-size: 13px;
  }
}
@media screen and (max-width: 1440px) {
  .finance_calculator {
    border: 1px solid #bdbdbd;
    border-radius: 6px;
    padding: 15px 10px;
    margin-top: 40px;
    /* max-height: 537px; */
    max-height: 660px;

    overflow: scroll;
  }
  .radio_buttons label span {
    line-height: 9px;
    width: 65px;
    margin-bottom: 6px;
  }
  .price_box_view h1 {
    font-size: 10px;
    margin-bottom: 0;
  }
  .price_box_view input {
    font-size: 13px;
    line-height: 14px;
  }
  .price_box img {
    width: 30px;
  }
  .price h6 {
    font-size: 10px;
    line-height: 5px;
  }
  .need_reach p {
    font-size: 12px;
  }
  .need_reach button {
    margin-top: 0;
    font-size: 10px;
  }
  .moser {
    padding-left: 0;
  }
  .pre_owned li {
    font-size: 11px;
    margin-right: 6px;
  }
  /* .offer_btn button {
    width: 178px;
  } */
  .payment p {
    font-size: 11px;
  }
  .pre_owned img {
    width: 15px;
    margin-right: 0;
  }
  .moser h4 {
    margin-top: 1px;
  }
  .payment .lorem {
    font-size: 12px;
  }
  .slider_view .mob_hide {
    /* height: 300px; */
  }
}
@media screen and (max-width: 1366px) {
  .finance_calculator h3::before {
    width: 80px;
    height: 2px;
    top: 17px;
  }
  .finance_calculator h3::after {
    width: 80px;
    height: 2px;
    top: 17px;
  }
  .radio_buttons label span {
    line-height: 10px;
    width: 64px;
    margin-bottom: 10px;
  }

  .finance_calculator .Show_breakdown {
    width: 100% !important;
    margin-top: -17px !important;
    font-family: 'Gotham Medium' !important;
    font-style: normal !important;
    font-weight: 350 !important;
    font-size: 13px !important;
    line-height: 16px !important;
    color: #ed693c !important;
    display: block;
    text-align: center;
  }
  /* .offer_btn button {
    width: 179px;
  } */
  .price {
    padding-top: 26px;
  }
  .month_text {
    width: 85px;
  }

  .moser {
    padding-left: 0px;
  }
  .pre_owned li {
    margin-right: 10px;
  }

  .price_box img {
    max-width: 28px;
    width: auto;
    height: auto;
    margin-right: 5px;
  }
  .need_reach {
    text-align: center;
    width: 127px;
    position: relative;
    top: 20px;
  }
  .price h6 {
    margin-bottom: 5px;
  }
  .need_reach button {
    width: 79px !important;
    height: 26px !important;
    font-size: 9px !important;
    line-height: 23px !important;
  }
  .radio_buttons label span {
    margin-right: 18px;
    line-height: 9px;
    width: 70px;
    margin-bottom: 8px;
    padding: 12px 15px;
  }
  .price_box_view h1 {
    font-family: 'Gotham regular';
    font-style: normal;
    font-weight: 325;
    font-size: 9px;
    line-height: 13px;
    color: #828282;
  }
}
@media screen and (max-width: 1199px) {
  .price_vicle2 .price_box_view {
    width: 150px;
    height: 70px;
    display: inline-grid;
  }
  .finance_calculator h3::before,
  .finance_calculator h3::after {
    width: 14%;
  }
  .finance_calculator {
    max-height: 390px;
  }
}
@media screen and (max-width: 1024px) {
  .month_text {
    width: 70px;
  }
  .price {
    margin-left: 12px;
    padding-top: 27px;
  }
  .finance_box p {
    font-size: 14px;
  }

  .vertical_slider .slick-next {
    right: 50px !important;
  }
  .finance_calculator {
    max-height: 465px;
  }
}
@media only screen and (max-width: 991px) {
  .payment .col-md-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .slider_view .mob_hide {
    height: 285px;
  }
  .payment p {
    font-size: 14px;
  }
  .pre_owned {
    flex-direction: column;
  }
  .need_reach {
    width: inherit;
    top: 20px;
  }
  .slider_view {
    padding-bottom: 20px;
  }
  .moser h4 {
    margin-bottom: 10px;
  }
  .price_history {
    margin-top: 30px;
  }
  .line_chart {
    margin-top: 30px;
  }
  .line_chart .price {
    left: -5%;
    top: 43%;
  }
  .moser {
    padding-left: 0px;
    margin-top: 0px;
  }
  .moser h3 {
    font-size: 26px;
    line-height: 30px;
  }
  .pre_owned li {
    font-size: 12px;
    line-height: 24px;
    margin-right: 9px;
  }
  .pre_owned li:after {
    left: -5px;
  }

  .slider_view_border {
    padding-bottom: 30px;
  }
  .need_reach {
    display: none;
    top: 0;
  }
  .need_reach_show {
    display: block;
    text-align: left;
  }
  .radio_buttons label span {
    line-height: 4px;
    width: 52px;
    height: 35px;
    margin-right: 6px;
    margin-bottom: 5px;
  }

  .like_view .slick-next {
    right: -70px;
  }
  .like_view .slick-prev {
    left: -70px;
  }
  .finance_calculator {
    max-height: 285px;
    margin-top: 10px;
  }
  .vertical_slider .slick-next {
    right: 18px !important;
  }
  .finance_calculator .css-1iji0d4 {
    min-height: 30px !important;
  }
}
@media only screen and (max-width: 900px) {
  .slider_view {
    padding-bottom: 20px;
  }
  .ProductViewSlider {
    padding: 0 0px;
  }
}
@media only screen and (max-width: 768px) {
  .month_text {
    width: 116px;
  }
  .pre_owned {
    margin: 10px 0px;
  }
  .payment ul {
    margin-top: 20px;
  }
  .like_view .slick-next {
    right: -20px;
  }
  .like_view .slick-prev {
    left: -20px;
  }
  .price_history h3 {
    margin-bottom: 0;
  }
  .bid_recived {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .mob_hide {
    display: none;
  }
  .details .heading {
    margin-top: 20px;
    margin-bottom: 9px;
    padding-bottom: 8px;
  }
  .details h5 {
    margin-top: 0px;
    margin-bottom: 9px;
    padding-bottom: 8px;
  }
  .pre_owned {
    flex-direction: inherit;
  }
  .like_view h2 {
    font-size: 15px !important;
  }
  .marg_top_20 {
    margin-bottom: 20px;
    display: none;
  }
  .marg_top_20_show_mob {
    display: block;
    position: relative;
    /* margin-bottom: 50px; */
  }
  .need_reach {
    text-align: left;
    margin-top: 0;
    width: inherit;
  }
  .inventory_box {
    display: none;
  }
  .slider_view {
    margin-top: 25px;
    margin-bottom: 0;
    padding-bottom: 15px;
  }
  .need_reach button {
    width: 100% !important;
    border-radius: 0px !important;
    padding: 1px !important;
    margin: 6px 0px;
    height: 40px !important;
    font-size: 10px !important;
  }
  .moser h3 {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 5px;
  }
  .moser h6 {
    font-size: 13px;
    line-height: 12px;
  }
  .moser h4 {
    font-size: 24px;
    line-height: 38px;
  }
  .moser ul li {
    margin-right: 5px !important;
    font-size: 10px !important;
    line-height: 9px;
  }
  .payment p {
    font-size: 14px;
    width: 245px;
  }
  .payment .lorem {
    width: 100%;
    margin: 0px;
  }
  .price_history h3 {
    font-size: 15px;
    line-height: 29px;
  }
  .pre_owned li {
    margin-right: 50px;
  }
  .pre_owned li:after {
    left: -12px;
  }

  /* .price_box img {
    width: inherit;
    max-width: inherit;
  } */
  .price {
    padding-top: 0px;
    display: inline-block;
    vertical-align: top;
    margin-left: 2px;
  }
  .price h6 {
    font-size: 12px;
    line-height: 14px;
  }
  .price p {
    font-size: 11px;
    line-height: 16px;
    margin-top: 2px;
  }
  .line_chart ul {
    padding-left: 0px;
  }
  .line_chart ul li {
    font-size: 12px;
  }
  /* .line_chart .price {
    left: -2%;
    top: 6px;
    transform: inherit;
    font-size: 13px;
    line-height: 14px;
  } */
  /* .line_chart .chart_date {
    font-size: 13px;
    left: 2%;
    top: -14px;
  } */
  .bid_recived h3,
  .finance_calculator h2 {
    font-size: 15px;
    line-height: 29px;
  }
  .date h6 {
    font-size: 12px;
    line-height: 12px;
  }
  .date p {
    font-size: 13px;
    line-height: 22px;
  }
  .date h5 {
    font-size: 13px;
    line-height: 22px;
  }

  .bid_recived .date:last-child {
    border: none;
  }
  .details {
    margin-top: 30px;
  }
  .details h3 {
    font-size: 15px;
    line-height: 25px;
  }
  .details h5 {
    font-size: 13px;
    line-height: 16px;
  }
  .details h6 {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 3px;
  }
  .basic_info p {
    font-size: 12px;
    line-height: 24px;
    left: 40%;
  }
  .description h3 {
    font-size: 14px !important;
  }
  .border_0 {
    margin-bottom: 20px !important;
  }
  .description p {
    font-size: 11px;
  }
  /* .description p:last-child {
    margin-bottom: 60px;
  } */

  .slider_view_border {
    border-bottom: none;
    padding-bottom: 10px;
  }
  .moser h3 br {
    display: none;
  }
  .price_vicle2 .price_box_view {
    display: inline-grid;
    width: 47%;
    height: 60px;
  }
  .month_text {
    width: 88px;
  }

  .price_history {
    width: 100%;
    padding: 0 16px;
  }
  .Cal_mob_hide {
    display: none;
  }
  .Cal_mob_show {
    display: block;
    text-align: left;
  }

  .radio_buttons label span {
    line-height: 18px;
    width: 95px;
    height: 50px;
    margin-right: 28px;
    margin-bottom: 13px;
  }
  .like_view .slick-next {
    right: -70px;
  }
  .like_view .slick-prev {
    left: -70px;
  }
  .finance_calculator {
    max-height: 400px;
  }
  .finance_calculator p {
    text-align: center;
  }
  .like_view {
    margin-bottom: 30px;
  }
  .finance_calculator {
    margin-top: 35px;
    overflow-x: hidden;
  }
  .price_history {
    margin-top: 15px;
  }
  .login_box {
    position: absolute !important;
    left: 50%;
    top: 265px;
  }
  .price_box {
    display: inline-block;
    width: 50%;
    margin-top: 13px;
  }
  .login_btn {
    height: 30px;
    font-size: 10px;
    padding: 0px 20px;
  }
  .details .basic_info {
    margin-bottom: 6px;
  }
  .login_box,
  .graph_box {
    height: 135px !important;
  }
}
@media screen and (max-width: 575px) {
  .line_chart .price {
    left: -6%;
    top: 39%;
  }

  .month_text {
    width: 70px;
  }

  /* .offer_btn button {
    width: 170px;
  } */
  .drawer_box {
    width: 355px !important;
  }
  .drawer_box .mistake_box img {
    margin-left: 0;
  }
  .drawer_box p {
    text-align: left;
  }

  .like_view .slick-next {
    right: -9px;
  }
  .like_view .slick-prev {
    left: -9px;
  }
}

@media screen and (max-width: 499px) {
  .payment .col-md-6 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  /* .price_vicle2 .price_box_view {
    display: inline-grid !important;
    width: 180px !important;
    height: 60px !important;
  } */
  .radio_buttons label span {
    width: 80px;
    margin-right: 20px;
  }
  .pre_owned li {
    margin-right: 30px;
  }
}

@media screen and (min-width: 426px) and (max-width: 539px) {
  .price_vicle2 .price_box_view {
    width: 190px;
  }
  .radio_buttons label span {
    width: 84px;
    margin-right: 20px;
  }
}

@media screen and (min-width: 426px) and (max-width: 456px) {
  .pre_owned li {
    margin-right: 20px;
  }
  .price_vicle2 .price_box_view {
    width: 160px !important;
    height: 75px !important;
  }
  .radio_buttons label span {
    width: 70px;
    margin-right: 18px;
    height: 40px;
    line-height: 9px;
  }
}
@media screen and (max-width: 425px) {
  .line_chart ul li {
    color: #000000;
    margin-right: 20px;
  }
  .basic_info p {
    left: 50%;
  }
  .line_chart .price {
    left: -8%;
    top: 39%;
  }
  .price_box_view h1 {
    line-height: 12px;
    margin-bottom: 0;
  }
  .payment p {
    font-size: 11px;
    width: 160px;
  }
  /* .price_vicle2 .price_box_view {
    width: 160px !important;
  } */
  .radio_buttons label span {
    line-height: 10px;
    width: 75px;
    height: 40px;
    margin-right: 10px;
    margin-bottom: 13px;
  }
  .radio_buttons label:nth-child(4) span {
    margin-right: 21px;
  }
}
@media screen and (max-width: 414px) {
  .radio_buttons label span {
    width: 70px;
  }
  .login_box {
    left: 50%;
  }
}

@media screen and (max-width: 390px) {
  .radio_buttons label span {
    width: 66px;
  }

  .price_history h3 {
    margin-bottom: 0;
  }
  .price_box img {
    max-width: 20px;
  }
}
@media only screen and (max-width: 375px) {
  .finance_calculator h3::before,
  .finance_calculator h3::after {
    width: 70px;
  }
  .radio_buttons label span {
    width: 65px;
    margin-bottom: 6px;
    margin-right: 6px;
  }
  .finance_calculator h3::before,
  .finance_calculator h3::after {
    width: 50px;
  }
  .month_text {
    width: 60px;
  }
  .drawer_box {
    width: 347px !important;
  }
  .close_btn {
    right: 15px;
    top: 20px;
  }
  /* .pre_owned li {
    margin-right: 10px !important;
    font-size: 10px !important;
  } */

  .login_container p br {
    display: none;
  }
  .line_chart .price {
    left: -10%;
    top: 39%;
  }
  .payment p {
    font-size: 11px;
    width: 180px;
  }

  .payment .lorem {
    font-size: 11px;
  }
}

@media only screen and (max-width: 349px) {
  .price {
    margin-left: 9px;
  }
  .line_chart ul {
    padding-left: 54px;
  }
  .line_chart ul li {
    margin-right: 10px;
  }
  /* .ProductViewSlider .slick-slide {
    width: 210px !important;
  } */
  .month_text {
    width: 50px;
    margin-right: 15px;
  }
  .drawer_box {
    width: 310px !important;
  }

  .payment .col-md-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 320px) {
  .price_vicle2 .price_box_view {
    width: 100%;
  }
  .finance_calculator .Show_breakdown {
    margin-left: 20%;
  }
  .radio_buttons label span {
    width: 50px;
    margin-bottom: 5px;
    margin-right: 6px;
    height: 30px;
    line-height: 1px;
    text-align: center;
  }
  .pre_owned li {
    margin-right: 20px !important;
    font-size: 11px !important;
  }
  .line_chart ul {
    padding-left: 0px;
  }
  .payment .lorem {
    font-size: 10px;
  }
  .price_vicle2 .price_box_view {
    width: 100% !important;
  }
  .login_box {
    width: 220px;
  }
}

@media screen and (min-width: 1500px) {
  .radio_buttons label span {
    line-height: 16px;
    /* width: 93px; */
  }
}

@media screen and (min-width: 1200px) and (max-width: 1499px) {
  .radio_buttons label span {
    padding: 14px 16px;
    width: 75px;
  }
}
