.modal_buttons button {
  background-color: #181818;
  border: none;
  border-radius: 100px;
  color: #fff;
  font-family: Gotham Regular;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  height: 34px;
  left: 0;
  line-height: 14px;
  padding: 0 15px;
  text-transform: uppercase;
  top: 0;
  margin-right: 15px;
  margin-top: 10px;
  display: inline-block;
}
.modal_buttons button:hover {
  background-color: #181818;
}
.modal_container {
  text-align: center;
  border: none !important;
  box-shadow: none !important;
  border-radius: 10px !important;
}
.close_icon {
  cursor: pointer;
  float: right;
}
.close_icon button {
  background: #000;
  color: #fff;
  border-radius: 0 10px 0 0;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 42px;
  font-size: 30px;
}
.modal_heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #333;
  padding: 6px 6%;
}
.modal_content {
  color: #868686 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 21px !important;
  line-height: 22px !important;
  text-align: center !important;
  margin: 10px 0px;
}
.status_image {
  margin-bottom: 20px;
  margin-top: 20px;
  cursor: pointer;
}
.modal_container:focus,
.modal_container input:focus {
  outline: none;
}
@media screen and (max-width: 425px) {
  .modal_container {
    width: 320px !important;
    height: 220px !important;
  }
  .status_image {
    width: 15%;
  }
  .modal_content {
    font-size: 16px !important;
    margin: 10px 0px;
    line-height: 18px !important;
  }
}
@media screen and (max-width: 425px) {
  .modal_container {
    width: 300px !important;
    height: 200px !important;
  }
}
