h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-transform: capitalize;
  color: var(--secondary_head_color);
  font-family: 'Roboto', sans-serif;
}

/* .inspection-toparea {
  border-radius: 50px 50px 0px 0px;
  margin: 0 !important;
  padding-top: 34px;
  padding-bottom: 16px;

} */

h2 span {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--secondary_head_color);
  font-family: 'Roboto', sans-serif;
}

/*banner*/
.banner_landing {
  background-color: #f8f2e8;
  /* background-image: url('../../assets/images/banner/banner1.png'); */
  background-repeat: no-repeat;
  height: 450px;
  background-position: center;
  position: relative;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.05));
  box-shadow: 0px 8px 10px #f1f1f1; */
}

.banner_landing h1 {
  font-family: 'Gotham Medium';
  font-weight: 350;
  font-size: 32.9781px;
  line-height: 46px;
  text-transform: uppercase;
  margin: 10px 0px 7px 0px;
  color: #181818;
  letter-spacing: -0.02em;
}

.banner_landing h1 span {
  font-size: 35px;
  line-height: 33px;
  display: block;
  letter-spacing: -0.01em;
  padding-left: 3px;
}

.banner-watch {
  max-width: 100%;
  margin: auto;
}

.banner_form {
  text-align: center;
  /* border-radius: 16px;
  padding: 0px;
  margin: auto 0px; */
}

.banner_form .form_tab {
  margin-top: 9px;
}

.banner_landing p {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 0;
}

.banner_landing .form_span_para {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #666666;
  display: block;
}

.mob_banner {
  position: absolute;
  right: auto;
  overflow: hidden;
  top: 1%;
  left: 55%;
}

.mob_banner img {
  height: 340px;
}

.about_video video {
  margin: auto;
  text-align: center;
  width: 100%;
  object-fit: contain;
  height: 100%;
}

.about_video video:focus-visible {
  outline: none !important;
}

.space_section {
  margin-top: 50px;
  margin-bottom: 30px;
}

.purpose {
  background-image: url('../../assets/images/banner/map.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: #9a9488;
  height: 340px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  position: relative;
  overflow: hidden;
}

.purposecontain {
  text-align: center;
  position: absolute;
  right: 27%;
  z-index: 99;
  top: -37px;
}
.watch_right {
  float: right;
  padding-top: 68px;
}

.watch_right img {
  height: 350px;
}

.purpose button img {
  width: 5px;
  height: 8px;
  margin: -2px 3px;
}

.over_lay {
  background-color: #0000006e;
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
}

.purpose h2 {
  font-family: Gotham Medium;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 5px;
}

.purpose p {
  font-family: Gotham medium;
  font-weight: 350;
  opacity: 0.8;
  padding-left: 4px;
  font-size: 10px;
  line-height: 12px;
  color: #fff;
  opacity: 0.8;
  margin-bottom: 0px;
}

.purpose button {
  border: none;
  background: transparent;
  border-radius: 100px;
  font-family: 'Gotham medium';
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #ffc46a;
}

.purpose button:hover {
  text-decoration: underline;
}

.purpose_complicatedbackground {
  background: linear-gradient(180deg, #cbcbcb 0%, #ececec 74.62%);
}

.purpose_complicated {
  background-image: url('../../assets/images/banner/watch1.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 340px;
  display: flex;
  align-items: center;
  position: relative;
  text-align: center;
}

.purpose_complicated h2 {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 45px;
  text-transform: capitalize;
  color: #000;
  margin-bottom: 15px;
}

.purpose_complicated p {
  font-family: Imprint MT Shadow;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 18px;
  color: #000;
  margin-bottom: 10px;
  opacity: 0.8;
}

.purpose_complicated button {
  height: 28px;
  left: 0.19px;
  top: 0.12px;
  border: none;
  background: #181818;
  border-radius: 100px;
  font-family: 'Gotham light';
  font-weight: 400;
  font-size: 9px;
  line-height: 13px;
  color: #fff;
  padding: 0px 12px;
}

.top_section img {
  width: 100%;
  border-radius: 12px;
  object-fit: cover;
}

.top_section p {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #181818;
  font-family: 'Gotham Medium';
  margin: 15px 0px 0px;
  text-transform: uppercase;
}

.form_tab .nav-tabs {
  background: rgb(255 255 255 / 68%);
  border-radius: 6px;
  height: 42px;
}

.form_tab .nav-tabs li {
  width: 50%;
}

.form_tab .nav-tabs li button {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  color: var(--primary_head_color) !important;
  border: none;
  height: 42px;
  width: 100%;
  background-color: transparent !important;
}

.form_tab .nav-tabs li:nth-child(1) button {
  border-radius: 6px 0px 6px 6px;
}

.form_tab .nav-tabs li:nth-child(2) button {
  border-radius: 6px 6px 6px 0px;
}

.form_tab .nav-tabs li .nav-link.active {
  background: var(--white_background) !important;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-weight: 500;
}

.form_tab .form_data .form-control {
  background: var(--white_background);
  border: 1px solid #000000;
  border-radius: 4px;
  height: 32px;
  width: 280px;
  -webkit-appearance: none;
}

.form_tab .form_data .form-control svg {
  display: none;
}

.form_tab .form_data .MuiOutlinedInput-notchedOutline {
  display: none;
}

.form_tab .form_data .MuiNativeSelect-select:focus {
  background-color: var(--white_background);
}

.form_tab .form_data .MuiNativeSelect-select {
  border-radius: 6px;
  font-family: 'Baloo 2', cursive !important;
  padding: 9px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--primary_para_color);
  text-transform: capitalize;
}

.form_tab .form_data .MuiSelect-select.MuiSelect-outlined {
  font-family: 'Gotham normal light';
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 11px;
  align-items: center;
  text-transform: capitalize;
  color: #828282;
  text-align: left;
  padding-top: 10px;
}

.select_down {
  position: relative;
}

.select_down:after {
  position: absolute;
  content: '';
  background-image: url('../../assets/images/icons/down.png');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right;
  height: 24px;
  width: 24px;
  top: 5px;
  right: 20px;
}

.button_form,
.button_form:hover,
.button_form:hover:active,
.button_form:focus {
  font-family: 'Gotham Regular';
  font-weight: 400;
  font-size: 11.6594px;
  line-height: 11px;
  text-align: center;
  color: #fff;
  border: none;
  margin: auto;
  background: #181818;
  border-radius: 83.2812px;
  padding: 14px 28px;

  /* height: 36px;
  background: #181818;
  border-radius: 100px;
  font-family: 'Gotham Regular';
  font-style: normal;
  font-weight: 350;
  font-size: 10px;
  line-height: 13px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  margin: auto;
  padding: 0px 20px; */
}

/* 
.button_form:hover,
.button_form:hover:active,
.button_form:focus {
  box-shadow: none !important;
  background: none !important;
  color: #fcad6c !important;
} */

.button_form:hover,
.button_form:hover span {
  text-decoration: none;
}

.button_form img {
  height: 20px;
  width: 20px;
  margin-top: -3px;
}

/*brands*/
.brand_section .col-2 {
  flex: 0 0 20%;
  max-width: 20%;
}

.brand_img {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  cursor: pointer;
}

.brand_img img {
  max-width: 70%;
}

.brand_img.ap_img img {
  max-width: 17%;
}

/*about*/
.about_video {
  height: 400px;
  overflow: hidden;
  text-align: center;
}

.about_video img {
  max-width: 100%;
  width: auto;
  margin: auto;
  width: 100%;
  object-fit: cover;
}

.about_video video,
.condition_section video {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
}

.about_video video,
.condition_section video {
  width: 100%;
}

/*our friends*/
.friend_img {
  height: 110px;
  display: block;
  text-align: center;
  margin-bottom: 25px;
  overflow: hidden;
}

.friend_img img {
  max-width: 100%;
  width: auto;
  height: 100%;
}

/*faq*/
.faq_section {
  position: relative;
}

.faq_text {
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-transform: capitalize;
}

.faq_img {
  position: absolute;
  bottom: -100px;
}

.faq_img img {
  width: 400px;
}

.accordion_faq .MuiPaper-root.MuiAccordion-root,
.accordion_faq .MuiTypography-root {
  border: none;
  box-shadow: none;
  font-family: 'Baloo 2', cursive !important;
  text-align: left;
  letter-spacing: inherit;
}

/* .accordion_faq .MuiCollapse-root{
    border-bottom: 1px solid #E6E8ED;
} */
.accordion_faq .MuiCollapse-wrapper {
  margin: 30px 0px 0px;
}

.accordion_faq .MuiAccordionSummary-content {
  margin: 0px;
}

.accordion_faq .MuiButtonBase-root.MuiAccordionSummary-root {
  border-bottom: none;
  margin: 0px;
  padding: 6px 0px 0px 50px;
  min-height: 39px;
}

.accordion_faq .MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded {
  border-bottom: 1px solid #e6e8ed;
}

.accordion_faq .MuiPaper-root.MuiAccordion-root:before {
  display: none;
}

.accordion_faq .MuiAccordion-root.Mui-expanded {
  margin: 0px;
}

.accordion_faq .MuiAccordionSummary-root.Mui-expanded {
  min-height: 20px;
}

.accordion_faq .MuiAccordionSummary-content.Mui-expanded {
  margin: 0px 0px;
}

.accordion_faq .MuiAccordionDetails-root .MuiTypography-root {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #828282;
}

.accordion_faq
  .MuiPaper-root.MuiAccordion-root:last-child
  .MuiButtonBase-root.MuiAccordionSummary-root {
  border-bottom: none !important;
}

.accordion_faq .MuiAccordionSummary-expandIcon {
  padding: 5px;
}

.accordion_faq .MuiAccordionDetails-root {
  background: #f9f9f9;
  margin: 0px 0px 10px;
}

.accordion_faq .MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded {
  position: relative;
}

.accordion_faq .MuiButtonBase-root.MuiAccordionSummary-root:before {
  background-image: url('../../assets/images/icons/arrowdown.png');
  background-size: auto;
  position: absolute;
  content: '';
  z-index: 99;
  left: 0px;
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-position: 50%;
}

.accordion_faq .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded:after {
  background-image: url('../../assets/images/icons/upblack.png');
  background-size: auto;
  position: absolute;
  content: '';
  z-index: 99;
  left: 0px;
  height: 20px;
  width: 20px;
  top: 40px;
  /* background-color: #f9f9f9; */
  background-repeat: no-repeat;
  background-position: 50%;
}

.just_droped {
  margin-top: 20px !important;
  text-align: left;
}

.just_droped h2 {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  text-transform: capitalize;
  margin-bottom: 7px;
  color: #181818;
}

.just_drop_mob_hide .col-lg-3:nth-child(1) {
  padding-right: 8px;
}

.just_drop_mob_hide .col-lg-3:nth-child(2) {
  padding-right: 8px;
}

.just_drop_mob_hide .col-lg-3:nth-child(3) {
  padding-right: 8px;
}

.just_drop_mob_hide .col-lg-3:nth-child(4) {
  padding-right: 8px;
}

.just_drop_mob_hide .col-lg-3:nth-child(4) img[alt='Bookmark'] {
  right: 30px !important;
}

.just_drop_mob_hide {
  padding-right: 5px;
}

.brand_section h2 {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  text-transform: capitalize;
  margin-bottom: 14px;
  color: #181818;
}

.hub_heading {
  font-family: 'Gotham Light';
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 99.8%;
  margin-bottom: 18px;
  color: #525151;
  text-align: left;
}

.just_droped .card {
  background-color: var(--white_background);
  border: none;
  border-radius: 6px;
  padding: 0px;
  transition: 0.5s ease-out;
}

/* .just_droped .card:hover{
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
} */
.just_droped .card a:hover {
  text-decoration: none;
}

.just_droped .card .card_img {
  background: transparent;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  height: 270px;
  margin-bottom: 9px;
  overflow: hidden;
  border: 1px solid #f5f5f5;
}

.just_droped .card .card_img .watch_img {
  height: 100%;
  /* border-radius: 10px; */
}

.just_droped .card .card_img .bookmark_img {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 99;
}

.just_droped .card .card_body {
  padding: 5px 0px;
}

.just_droped .card .card_body h5 {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  font-size: 13px;
  line-height: 11px;
  color: #181818;
  text-decoration-line: underline;
  text-transform: uppercase;
  text-underline-offset: 2px;
  margin-bottom: 2px;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 1px;
}

.just_droped .card .card_body h6 {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 14px;
  color: #333333;
}

.just_droped .card .card_body h3 {
  font-family: Gotham regular;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #7b7b7b;
  margin-bottom: 1px;
}

.just_droped .card .card_body p {
  font-family: 'Gotham Light';
  font-style: normal;
  font-weight: 325;
  font-size: 11px;
  line-height: 13px;
  color: #7b7b7b;
  margin-bottom: 10px;
}

.just_droped .card .card_body span {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 16px;
  color: #111111;
}

.inventory_btn {
  text-align: center !important;
  margin-top: 31px;
  margin-bottom: 18px;
}

.inventory_btn button {
  height: 34px;
  padding: 0px 15px;
  left: 0px;
  top: 0px;
  border: none;
  border-radius: 100px;
  background-color: #181818;
  color: #fff;
  font-family: 'Gotham Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  text-transform: uppercase;
}

.friend_section {
  margin-bottom: 45px;
  padding-bottom: 0 !important;
  margin-top: 10px;
  margin-right: 15px;
}

.friend_section h2 {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;

  color: #181818;
}

.find_answer {
  display: block;
  text-transform: inherit;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.8px;
  color: #555555;
  margin-bottom: 5px;
  text-align: left;
}

h2.ask_question {
  text-align: left;
  margin-bottom: 20px;
}

.record_heading {
  margin-bottom: 20px !important;
}

.about_section h2 {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  align-items: center;
  text-transform: capitalize;
  color: #181818;
  margin-bottom: 7px !important;
}

.about_section span {
  font-family: 'Gotham Light';
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 100.8%;
  margin-bottom: 14px;
  color: #333333;
  text-align: left;
}
.condition_section h2 {
  margin-bottom: 15px !important;
}
.brand_section p {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 325;
  font-size: 7px;
  line-height: 10px;
  color: #181818;
  display: none;
}

.brand_img .rolex {
  max-width: 35% !important;
  object-fit: cover;
}

.brand_img .breguest {
  max-width: 52% !important;
}

.about_show {
  display: none !important;
}

.about_section {
  margin-top: 15px;
}
.condition_section {
  margin-top: 20px;
}

.margin_top_85 {
  margin-top: 40px;
}

.best_del {
  margin-top: 15px;
}

/*new*/
/* .fashion_slider{
  height: 250px;
} */
.fashion_slider img {
  overflow: hidden;
  border: 1px solid #f6f6f6;
  padding: 30px;
}

.watch_banner1 {
  background-color: #b8dffc;
  background-repeat: no-repeat;
  height: 350px;
  background-position: center;
  position: relative;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.05));
  box-shadow: 0px 8px 10px #f1f1f1; */
}

.watch_banner1 h2 {
  font-family: 'Gotham Medium';
  font-weight: 700;
  font-size: 23px;
  line-height: 26px;
  text-transform: uppercase;
  margin-bottom: 7px;
  color: #000;
  letter-spacing: -0.01em;
}

.watch_banner1 h2 span {
  font-family: 'Gotham Medium';
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  display: block;
  letter-spacing: -0.01em;
  padding-left: 3px;
  color: #000;
}

.watch_banner1 .button_form1,
.watch_banner1 .button_form1:hover:active,
.watch_banner1 .button_form1:focus {
  font-family: 'Gotham Medium';
  font-weight: 700;
  font-size: 13px;
  line-height: 10px;
  text-align: center;
  color: #0094ff;
  background-color: transparent;
  border: none;
}

.watch_banner1 .button_form1 span:hover,
.watch_banner1 .button_form1:hover {
  text-decoration: underline;
}
.watch_banner1 .button_form1 img {
  height: 20px;
  width: 20px;
  margin-top: -3px;
}
.watch_banner1 .banner_form1 {
  text-align: left;
}

@media screen and (max-width: 1699px) {
  /* .brand_img {
    width: 100%;
  }

  .brand_img .ap_icon {
    max-width: 10%;
  } */

  .paddin_right_105 {
    padding-left: 93px;
  }
}
@media screen and (max-width: 1440px) {
  .just_droped .card .card_body h6 {
    font-size: 12px;
    line-height: 12px;
  }
}

@media screen and (max-width: 1399px) {
  .paddin_right_105 {
    padding-left: 63px;
  }
}

@media screen and (max-width: 1199px) {
  .brand_img {
    width: 175px;
  }

  .brand_img .parmigiani {
    max-width: 80% !important;
  }

  .brand_img img {
    max-width: 87% !important;
  }

  .brand_img img[alt='rolex'] {
    max-width: 50%;
  }

  .brand_img .ap_icon {
    max-width: 30% !important;
  }

  .margin_boottom_40 {
    margin-bottom: 0 !important;
  }

  .paddin_right_105 {
    padding-left: 41px;
  }
}

@media screen and (max-width: 1024px) {
  .brand_img .ap_icon {
    max-width: 24% !important;
  }

  .brand_img .rolex {
    max-width: 40% !important;
  }

  .copyright {
    padding: 12px 30px;
  }
}

@media screen and (min-width: 570px) and (max-width: 650px) {
  .purpose_complicatedbackground {
    background: transparent;
  }

  /* .banner_landing {
    height: 800px !important;
  } */
}

@media screen and (max-width: 1440px) {
  .just_droped .card .card_body h5 {
    line-height: 20px;
  }
}

@media screen and (max-width: 1280px) {
  .banner_form {
    padding-top: 35px;
  }
}

@media screen and (max-width: 1024px) {
  .purpose .container,
  .purpose_complicated .container {
    text-align: left;
    z-index: 9;
  }
}

@media screen and (max-width: 991px) {
  .inspection-toparea {
    padding: 45px 0px;
  }

  .banner_landing p {
    font-size: 18px;
    line-height: 24px;
  }

  .banner_landing .form_span_para {
    font-size: 16px;
    line-height: 20px;
  }

  .brand_img {
    width: 130px;
    height: 90px;
  }

  .about_video {
    height: inherit;
  }

  .brand_img .rolex {
    max-width: 60% !important;
  }

  .just_droped .card {
    margin-bottom: 30px;
  }

  .brand_img .ap_icon {
    max-width: 30% !important;
  }

  .just_droped .card .card_img {
    height: 165px;
    width: 165px;
  }

  .just_droped .card .card_img .watch_img {
    height: 100%;
    width: 100%;
  }

  .over_lay {
    display: block;
  }

  .just_droped img[alt='Bookmark'] {
    right: 10px;
  }

  .just_drop_mob_hide .col-lg-3:nth-child(4) img[alt='Bookmark'] {
    right: 10px !important;
  }

  .just_droped .star_icon {
    top: 4%;
    left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .paddin_right_105 {
    padding-left: 15px;
  }

  .copyright {
    padding-bottom: 0;
  }

  .banner_form {
    margin-top: -24px !important;
  }
}

@media screen and (max-width: 767px) {
  .banner_landing {
    height: 340px;
  }

  .watch_banner1 {
    background-color: #b8dffc;
    height: auto;
    padding-top: 15px;
  }

  .watch_banner1 h2 {
    font-size: 16px;
    line-height: 17px;
    margin-bottom: 0px;
  }

  .watch_banner1 h2 span {
    font-size: 14px;
    line-height: 16px;
    padding-left: 5px;
  }

  .watch_banner1 .button_form1,
  .watch_banner1 .button_form1:hover,
  .watch_banner1 .button_form1:hover:active,
  .watch_banner1 .button_form1:focus {
    font-size: 9px;
  }

  /* .button_form{
    height: 36px;
    background: #181818;
    border-radius: 100px;
    font-family: 'Gotham Regular';
    font-style: normal;
    font-weight: 350;
    font-size: 10px;
    line-height: 13px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    border: none;
    margin: auto;
    padding: 0px 20px; 
  } */
  .purpose_complicatedbackground {
    background: transparent;
  }

  .purpose_mob {
    margin: auto;
    overflow: hidden;
    height: 225px;
  }

  .friend_section {
    margin-top: 18px;
    overflow-x: hidden;
    padding-left: 10px;
  }

  .mob_banner {
    position: relative;
    left: auto;
    top: auto;
    margin: auto;
  }

  .mob_banner img {
    height: 240px;
    margin: auto;
    text-align: center;
  }

  .watch_banner1 .banner_form1 {
    text-align: center;
  }

  .fashion_slider img {
    /* height: 189px; */
    overflow: hidden;
    object-fit: inherit;
    padding: 0px;
  }

  .banner_landing h1 span {
    font-size: 20px;
    line-height: 20px;
    padding-left: 5px;
  }

  .inspection-toparea {
    padding: 26px 0px;
  }

  .inspection-toparea {
    height: auto;
    border-radius: 20px 20px 0px 0px;
  }

  .brand_img .breguest {
    max-width: 80% !important;
  }

  .about_hide {
    display: none !important;
  }

  .about_show {
    display: block !important;
    margin-bottom: 8px !important;
  }

  .banner_landing h1 {
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    margin: auto;
    margin: 15px 0px 2px 0px;
    color: #000;
  }

  .banner_landing p {
    font-size: 18px;
    line-height: 22px;
  }

  .button_form,
  .button_form:hover,
  .button_form:hover:active,
  .button_form:focus {
    font-size: 10px;
    padding: 9px 22px;
    margin-top: 7px;
  }

  .banner_landing .form_span_para {
    font-size: 13px;
    line-height: 13px;
    text-transform: capitalize;
    color: #666666 !important;
    font-family: Gotham Bold;
  }

  /* 
  .banner_landing {
    background-image: url('../../assets/images/banner/mobile.png');
    height: 380px;
    padding: 0px 0px 10px 0px;
    background-position: bottom;
    background-size: 210px;
    align-items: initial !important;
    width: 100%;
    margin: auto;
  } */

  h2,
  .faq_text {
    font-size: 18px;
    line-height: 28px;
  }

  h2 span {
    font-size: 14px;
    line-height: 100.8%;
  }

  .about_video {
    height: inherit;
    padding-left: 3px;
    padding-right: 5px;
  }

  .about_video img {
    width: 100%;
    height: inherit;
  }

  .friend_img {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    height: 100%;
    width: 100%;
    align-items: center;
  }

  .friend_img img {
    max-width: inherit;
    height: initial;
    width: initial;
  }

  .banner_landing p,
  .banner_landing .form_span_para {
    color: var(--primary_head_color);
  }

  .form_bg {
    border-radius: 6px;
    padding: 15px 15px;
    position: absolute;
    top: 20%;
    z-index: 99;
    left: 0;
    right: 0;
    width: 295px;
    margin: auto;
  }

  .faq_img {
    position: relative;
    bottom: auto;
  }

  .accordion_faq .MuiButtonBase-root.MuiAccordionSummary-root {
    padding: 0px 0px 0px 50px;
    border: none;
  }

  .accordion_faq .MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded:last-child {
    border: none;
  }

  .accordion_faq .MuiAccordionSummary-content p {
    font-size: 14px;
    line-height: 24px;
  }

  .accordion_faq .MuiAccordionDetails-root .MuiTypography-root {
    font-size: 14px;
    line-height: 18px;
  }

  .accordion_faq .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded:after {
    top: 48px;
  }

  .accordion_faq .MuiCollapse-wrapper {
    margin: 19px 0px 0px;
  }

  .same_width_img {
    width: 50px !important;
  }

  .brand_img {
    margin-bottom: 12px;
    border-radius: 100%;
    width: 95px;
    height: 95px;
    background-color: #eff0f4;
    border: none;
  }

  .accordion_faq .MuiAccordionSummary-content.Mui-expanded,
  .MuiAccordionSummary-content.Mui-expanded,
  .accordion_faq .MuiAccordionSummary-content {
    margin: 12px 0px;
  }

  .faq_img img {
    width: auto;
    max-width: 100%;
  }

  .just_droped {
    margin-top: 0px !important;
  }

  .just_droped h2,
  .brand_section h2,
  .about_section h2,
  .friend_section h2 {
    margin-bottom: 0;
    font-size: 16px;
  }

  .brand_section h2 {
    margin-bottom: 20px;
    padding: 0 !important;
  }

  .brand_section p {
    color: #181818c4;
    display: none;
  }

  .form_tab .form_data .form-control {
    height: 42px;
    width: 270px;
  }

  .form_tab .form_data .MuiSelect-select.MuiSelect-outlined {
    padding-top: 11px;
    font-size: 15px;
    line-height: 18px;
  }

  .select_down:after {
    height: 10px;
    width: 15px;
    top: 16px;
    right: 20px;
  }

  .hub_heading {
    margin-bottom: 20px;
    font-size: 13px !important;
    line-height: 21px !important;
    font-weight: 400;
    margin-top: 0px;
  }

  .top_section p {
    font-size: 9px;
  }

  .banner_form {
    text-align: center;
    margin: auto;
  }

  section.topSection {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 35px;
  }

  .brand_section {
    border-bottom: 1px solid #ebebeb;
    padding: 15px 0px 10px !important;
  }

  .about_section {
    margin-top: 10px;
  }

  .best_del {
    border-bottom: 1px solid #ebebeb;
    margin-top: 17px;
  }

  .margin_top_85 {
    margin-top: 18px;
  }

  .inventory_btn {
    margin-bottom: 30px;
  }

  .purpose,
  .purpose_complicated {
    margin: 0px 0;
  }

  .purpose {
    background-color: #9a9488;
    background-image: none;
    margin-bottom: 0px;
    position: relative;
    height: auto;
    overflow: hidden;
  }

  .purposecontain {
    padding: 12px 0px 0px;
    text-align: center;
    position: relative;
    right: auto;
    top: auto;
  }

  .purposecontain h2 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 2px;
  }

  .purposecontain p {
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    font-family: Gotham Regular;
    padding: 0px 27px;
    margin-bottom: 0px;
  }

  .purpose_mob img {
    max-width: 100%;
    height: 250px;
  }

  .purpose_complicated {
    background-image: url('../../assets/images/banner/mob_watch.png');
    align-items: flex-start;
    padding: 20px 0px 0px;
    height: 345px;
    background-size: 100% 96%;
  }

  .purpose_complicated h2 {
    font-size: 16px;
    line-height: 20px;
  }

  .purpose_complicated p {
    font-size: 15px;
    line-height: 10px;
    margin-bottom: 15px;
  }

  .purpose_complicated button,
  .purpose button {
    height: 29px;
    font-size: 10px;
    line-height: 11px;
    padding: 0px 22px;
  }

  .top_section {
    width: 57px;
    height: 57px;
    margin: auto;
  }

  .top_section img {
    width: 100%;
    border-radius: 100px;
    height: 100%;
  }

  .home_content .topSection .col {
    width: 20%;
  }

  .home_content .topSection .col {
    padding-right: 0px;
  }

  .home_content .topSection .col:last-child {
    padding-right: 15px;
  }

  .inventory_btn button {
    height: 34px;
    width: 104px;
    font-size: 11px;
  }

  .user_dropdown span {
    color: #fff;
  }

  .condition_section {
    margin-top: 10px !important;
  }

  .about_video video {
    height: 230px;
    width: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 575px) {
  .purpose_complicatedbackground {
    background: transparent;
  }

  .form_bg_mob {
    background-size: cover;
    height: 289px;
    margin: 16% 0px 0px;
  }

  .form_bg {
    top: 18%;
  }

  img.footer_logo {
    width: 25%;
  }

  .brand_section .col-md-2 {
    padding-right: 5px;
    padding-left: 5px;
  }

  .brand_section .row {
    padding-right: 8px;
    padding-left: 8px;
  }

  .copyright {
    padding-bottom: 12px;
    padding-top: 5px;
  }

  .copyright p {
    margin-bottom: 10px;
  }

  .inventory_btn {
    margin-bottom: 20px;
    margin-top: 15px;
  }

  .btn_mob_feature {
    margin-bottom: 10px;
  }

  .videio_full_width {
    padding: 0;
  }

  .banner_form .MuiInputBase-colorPrimary,
  .form_tab .MuiInputBase-colorPrimary {
    width: 270px;
  }

  section.topSection {
    margin-top: -10px !important;
    margin-bottom: 0px;
  }

  .form_data .css-483lug,
  .form_data .css-1pnlsr {
    width: 263px !important;
  }

  .form_data .MuiTextField-root {
    width: 260px !important;
  }

  .banner_landing .form_span_para {
    font-size: 16px;
    font-family: 'Gotham Bold';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 13px;
    text-transform: capitalize;
    color: #666666 !important;
  }

  .brand_img .breguest {
    max-width: 87% !important;
  }

  .brand_img .ap_icon {
    max-width: 30% !important;
  }

  .just_droped {
    padding: 0;
  }

  .just_droped h2,
  .brand_section h2,
  .about_section h2,
  .hub_heading {
    padding: 0 15px;
  }
  .friend_section h2 {
    padding: 0 5px;
  }

  .friend_section .container {
    padding: 0;
  }
}

@media screen and (max-width: 499px) {
  .purpose_complicatedbackground {
    background: transparent;
  }

  body {
    width: 100%;
    overflow-x: hidden;
  }

  .form_bg_mob {
    margin: 18% 0px 0px;
  }

  .star_icon {
    left: 30px !important;
  }

  .brand_img {
    width: 70px;
    margin-bottom: 10px;
    height: 70px;
  }

  .banner_form {
    margin-top: 0px !important;
    padding-top: 15px !important;
  }
  .fashion_slider img {
    /* height: 138px;
    margin: auto; */
  }
}

@media screen and (max-width: 425px) {
  .fashion_slider img {
    /* height: 136px;
    margin: auto;
    width: 136px; */
  }
  .about_video {
    height: inherit;
  }

  .margin_top_85 {
    margin-bottom: 25px;
  }

  .top_section p {
    font-size: 6px;
    margin-top: 5px;
  }

  .form_data .css-cdugbn-MuiAutocomplete-root,
  .form_data .css-skl8kx-MuiAutocomplete-root {
    width: 263px !important;
  }

  .form_data .MuiTextField-root {
    width: 260px !important;
  }
}

@media screen and (max-width: 399px) {
  .brand_img {
    margin-bottom: 10px;
    width: 65px;
    height: 65px;
  }
}

@media screen and (max-width: 375px) {
  .form_bg_mob {
    margin: 24% 0px 0px;
  }

  .brand_img {
    width: 60px;
    margin-bottom: 5px;
    height: 60px;
  }
}

@media screen and (max-width: 320px) {
  .form_bg {
    top: 21%;
  }

  .accordion_faq .MuiAccordionSummary-content p {
    font-size: 12px;
    line-height: 20px;
  }

  .form_bg_mob {
    margin: 28% 0px 0px;

    height: 200px;
  }

  .brand_img {
    width: 56px;
    height: 56px;
  }

  .purpose p,
  .purpose_complicated p {
    width: 88%;
  }
}

@media screen and (min-width: 1300px) {
}

@media screen and (min-width: 1400px) {
  /* .mob_banner {
    left: 52%;
  } */
  .purpose_complicated {
    padding-left: 5%;
  }
}

@media screen and (min-width: 1500px) {
  .home_content .container {
    margin: 0 auto;
    padding: 0 32.5px;
    max-width: 1280px !important;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1439px) {
  .about_video {
    height: 350px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1439px) {
  .home_content .container {
    margin: 0 auto;
    padding: 0 32.5px;
    max-width: 1040px !important;
  }

  .just_droped .card .card_img {
    height: 230px;
  }
}

@media screen and (min-width: 768px) {
  .home_content .topSection .col {
    padding-right: 0px;
  }

  .home_content .topSection .col:last-child {
    padding-right: 15px;
  }

  .header ul .links {
    font-size: 14px;
  }

  .top_section p {
    font-size: 14px;
  }

  .purpose_complicated h2 {
    font-size: 18px;
    line-height: 28px;
  }
  .purpose_complicated p {
    font-size: 11px;
    line-height: 17px;
  }
  .purpose .container {
    position: relative;
  }
}

@media screen and (min-width: 1600px) {
  .banner_form1 {
    padding-left: 12%;
  }
}

/* @media screen and (min-width:1100px) and (max-width:1439px) {
  .purpose{
    background-position: 13%;
  }
} */

@media screen and (min-width: 800px) and (max-width: 900px) {
  .banner_landing {
    height: 350px;
  }
  /* 
  .mob_banner img {
    height: 400px;
  } */
}

/* @media screen and (min-width:1000px) and (max-width:1100px) {

  .mob_banner img {
    height: auto;
  }
} */

@media screen and (min-width: 768px) and (max-width: 900px) {
  .banner_landing {
    margin-top: -43px;
    height: 350px;
    background-size: 100% 75%;
    padding-bottom: 8%;
  }
  .banner_landing h1 {
    font-size: 22px;
    line-height: 33px;
  }
  .purpose,
  .watch_banner1,
  .purpose_complicated {
    height: 220px;
  }
  .purposecontain {
    right: 28%;
  }
  .purpose h2 {
    font-size: 15px;
    line-height: 23px;
  }
  .watch_banner1 {
    height: 300px;
  }
  .mob_banner img {
    height: 280px;
  }
  .space_section {
    margin-top: 0px;
  }
  .button_form,
  .button_form:hover,
  .button_form:hover:active,
  .button_form:focus {
    padding: 11px 22px;
  }
}

@media screen and (max-width: 768px) {
  .purpose h2 {
    font-size: 15px;
    line-height: 17px;
  }
  .banner_landing {
    margin-top: 0;
  }
}

.plyr {
  width: 100% !important;
  background: #fff !important;
}
.plyr__video-wrapper {
  width: 60% !important;
}
