.just_droped_slider {
  position: relative;
}

.just_droped_slider .drop_img {
  /* background-color: #f5f5f5; */
  border-radius: 6px;
  height: 100%;
}
.just_droped_slider a {
  text-align: left;
  margin-top: 16px;
  display: block;
}
.just_droped_slider a h5 {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 12px;
  color: #181818;
  text-decoration-line: underline;
  text-transform: uppercase;
  text-underline-offset: 2px;
  margin-bottom: 0px;
}
.just_droped_slider a h6 {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 14px;
  color: #333333;
}
.just_droped_slider a p {
  font-family: 'Gotham Light';
  font-style: normal;
  font-weight: 325;
  font-size: 11px;
  line-height: 13px;
  color: #7b7b7b;
  margin-bottom: 10px;
}
.just_droped_slider a h4 {
  font-family: Gotham regular;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #222222;
}
.just_droped_slider span {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 350;
  font-size: 15px;
  line-height: 17px;
  color: #111111;
}
.just_droped_slider h3 {
  font-family: Gotham regular;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #7b7b7b;
  margin-bottom: 0px;
}
.just_droped_slider .slick-list {
  margin: 0 0px;
}
@media screen and (max-width: 767px) {
  /* .just_droped_slider .bookmark_img {
    position: absolute !important;
    top: 36px !important;
    right: 34px !important;
  } */

  .drop_fix_div {
    width: 100%;
  }
  .just_droped_slider .slick-slide {
    position: relative;
    padding: 0 0px !important;
  }
  .just_droped_slider a h5 {
    line-height: 20px;
    margin-bottom: 0px;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .just_droped_slider h3 {
    margin-bottom: 0px;
  }
  .just_droped_slider img[alt='Bookmark'] {
    top: 4% !important;
    right: 10% !important;
  }
  .just_droped_slider .slick-slide > div {
    margin: 0 8px;
  }
  .image_div_drop {
    border: 1px solid #f5f5f5;
    border-radius: 10px;
    height: 200px;
    text-align: center;
    margin: auto;
    overflow: hidden;
  }
}
@media screen and (max-width: 575px) {
  .just_droped_slider .slick-slide > div {
    margin: 0 0px;
  }

  .just_droped_slider .slick-slide {
    padding: 0 10px !important;
  }
}
@media screen and (max-width: 480px) {
  .just_droped .star_icon {
    top: 4% !important;
    left: 25px !important;
  }
  .just_droped img[alt='Bookmark'] {
    top: 4% !important;
    right: 25px !important;
  }
  .image_div_drop {
    height: 138px;
  }
  .just_droped_slider .drop_img {
    height: 100%;
    margin: auto;
  }
}

@media screen and (max-width: 375px) {
  .just_droped_slider a h6 {
    font-size: 10px;
  }
}
@media screen and (max-width: 320px) {
  .just_droped_slider a h6 {
    font-size: 9px;
  }
}
