.header {
  background-color: var(--white_background) !important;
  box-shadow: none !important;
  border: none;
  transition: all 0.5s ease-in-out !important;
  height: 75px;
  padding-top: 10px;
  margin-bottom: 0px;
  background: #ffffff;
  border-bottom: 1px solid #f5f5f5;
  /* filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.05)); */
}
.profile_icon {
  vertical-align: middle;
  margin-left: 3px;
  border-style: none;
  width: 18px;
  height: 18px;
  margin-bottom: 5px;
}
.padding_70 {
  padding: 0 70px;
}
.header input::-webkit-input-placeholder {
  color: #000000 !important;
}
.header_scroll {
  width: 100%;
  top: 0px;
  transition: all 0.5s ease-in-out !important;
  position: fixed !important;
  z-index: 999;
  /* filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.05)); */
  /* border-bottom: 1px solid #ccc; */
  /* padding-top: 20px !important; */
}
.logo_head {
  /* height: 40px; */
  margin-top: -4px;
  cursor: pointer;
  width: auto;
}
.header ul .links {
  font-family: 'Gotham Light';
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #181818;
}
.header ul .selected_link {
  font-family: 'Gotham Medium';
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  padding-bottom: 6.64px;
  border-bottom: 3px solid rgb(24, 24, 24);
  color: #181818;
}
.header ul .links:hover {
  text-decoration: none;
}
.header ul .selected_link:hover {
  text-decoration: none;
  padding-bottom: 6.64px;
  border-bottom: 3px solid rgb(24, 24, 24);
}
.header ul li {
  display: inline-block;
  margin: 0px 50px 0px 0px;
}
.header ul li:last-child {
  margin: 0px 12px 0px 0px;
}
.user_profile {
  border-radius: 50%;
}
.button_watch,
.button_watch:hover {
  background-color: var(--blue_button);
  border-radius: 100px;
  width: 150px;
  height: 42px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  border: none;
  color: var(--white_text_Color);
}
.user_dropdown {
  background-color: transparent;
  text-align: center;
  padding: 0px;
  border: none;
  /* margin-left: -40px; */
}
.user_dropdown img {
  height: 20px;
  width: 20px;
  margin-left: 10px;
}

.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root {
  font-family: Gotham light;
  padding: 7px 28px 7px 17px;
  font-size: 12px;
}

.header input,
.header input::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary_head_color);
  opacity: 1;
}
.search_img {
  height: 25.71px;
}
.search_img_click {
  height: 12.86px !important;
}
img[alt='user'] {
  position: relative;
  top: -2px;
}
.search_mob {
  margin: 0 !important;
  width: 308px !important;
}
.search_mob input {
  font-family: 'Gotham normal';
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  position: relative;
  top: 1px;
  left: 5px;
}
.seach_icon {
  position: relative;
}
.menu_selected{
  background-color:rgba(0, 0, 0, 0.04) !important;
}
@media screen and (min-width: 900px) and (max-width: 1200px) {
  .header .MuiInputBase-input {
    width: 15ch !important;
    padding: 9px 8px 8px 42px !important;
    text-overflow: ellipsis;
  }
  .header ul li {
    display: inline-block;
    margin: 0px 20px !important;
  }
}

@media screen and (min-width: 900px) and (max-width: 991px) {
  .search_mob {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  #primary-search-account-menu .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    top: 76px !important;
    box-shadow: 10px 10px 20px rgb(0 0 0 / 80%);
    border-radius: 5px;
  }
  .container_head{
    margin: 0 auto;
    padding: 0 32.5px;
    max-width: 1040px !important;
  }
  .user_dropdown img{
    margin-right: 30px;
  }
  .logo_head{
    margin-left: 3%;
  }
}
@media screen and (max-width: 1366px) {
  .search_img {
    height: 20.71px;
  }
}
@media screen and (max-width: 1040px) {
  .padding_70 {
    padding: 0 15px;
  }
}
@media screen and (max-width: 991px) {
  .padding_70 {
    padding: 0 30px;
  }
}
@media screen and (max-width: 767px) {
  .header {
    border-bottom: 1px solid #e0e0e0;
    padding: 0px 0px;
    height: 60px;
    /* top: 20px; */
  }
  .padding_70 {
    padding: 0 18px;
  }
  .mob_menu .links {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #fff;
    text-transform: capitalize;
  }
  .mob_menu .selected_link {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: var(--primary_head_color);
    text-transform: capitalize;
  }
  .mob_menu .links:hover {
    text-decoration: none;
  }
  .mob_menu .selected_link:hover {
    text-decoration: none;
  }
  .logo_head {
    width: 90px;
  }
 
  .mob_menu .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    background-color: transparent !important;
    box-shadow: none !important;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    left: 0px !important;
    right: 0px;
    top: 60px !important;
    height: 100%;
    transition: ease all 0.5s;
    visibility: visible;
    overflow-x: hidden;
    border-radius: 0px !important;
    background-color: #ffffffc2 !important;
  }
  .MuiModal-root.MuiPopover-root.mob_menu ul {
    background: #000;
    height: 49%;
  }
  .user_dropdown {
    text-align: right;
  }
  .MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root {
    font-size: 16px;
    min-height: 35px;
    padding: 7px 20px;
  }
  #primary-search-account-menu .MuiPaper-root.MuiPaper-elevation1 {
    margin-top: 42px;
    left: 0px !important;
    width: 100%;
    box-shadow: none;
    right: 0;
    max-width: 100%;
    padding: 0px 0px 3px !important;
    background: white !important;
    z-index: 9;
    margin: 42px auto 40px;
    width: 93%;
  }
  #primary-search-account-menu .MuiPaper-root.MuiPaper-elevation1 ul {
    background: var(--white_background);
    border-radius: 5px;
  }
}
