p {
  margin-bottom: 0;
}
.moser_head {
  border-left: 2px solid #d1d1d1;
  /* height: 526px;

  overflow-x: scroll; */
}
/* .marg_top_20_show_mob {
  position: sticky;
  top: 0;
} */
.moser_head::-webkit-scrollbar {
  width: 1px;
}

.cell_phone .sendOTP {
  position: absolute;
  top: 11px;
  right: 0;
  width: 130px;
  height: 56px;
  border-radius: 0 4px 4px 0;
  color: #fff !important;
  font-family: Gotham Medium;
}
.inventory {
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
  padding-top: 0;
}
.drawer_box button {
  background: #000000;
  border-radius: 6px;
  width: 100%;
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  height: 50px;
  border: none;
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: Gotham Medium;
}
.disabled-btn[disabled] {
  background: #ccc;
  border-radius: 6px;
  border: 1px solid #999;
  width: 100%;
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  height: 50px;
  margin-top: 14px;
  margin-bottom: 20px;
  font-family: Gotham Medium;
  cursor: not-allowed;
}
.cell_phone .sendOTP:hover,
.cell_phone .verifyOTP:hover {
  background-color: #000000;
}
.cell_phone .verifyOTP {
  position: absolute;
  top: 11px;
  right: 0;
  width: 100px;
  height: 56px !important; 
  border-radius: 0 4px 4px 0;
}
.cell_phone .verify-sucess {
  position: absolute;
  background: #41ba77;
  top: 12px;
  right: 0;
  width: 130px;
  height: 54px;
  border-radius: 0 4px 4px 0;
}
.marg_top_20 {
  display: none;
}
.moser_head::-webkit-scrollbar-track {
  border-radius: 0px;
}
.graph_blur {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  pointer-events: none;
}
.submit_offer {
  width: 100%;
  height: 40px;
  background-color: #000;
  text-transform: uppercase;
  font-family: 'Baloo 2';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 0;
}
.submit_offer:hover {
  background-color: #000;
}
.row.radio_button label {
  min-width: 100%;
  display: block;
}
.row.radio_button span {
  width: 100%;
  display: block;
}
.radio_button label span {
  padding: 11px 15px !important;
}
.radio_button label span {
  font-family: Gotham regular;
  position: relative;
  display: inline-block;
  margin-right: 18px;
  padding: 10px 50px;
  font-weight: 325;
  font-size: 16px;
  line-height: 18px;
  color: #000;
  border: 1px solid #bdbdbd;
  border-radius: 10px;
  background: #fff;
  text-align: center;
  scroll-behavior: inherit;
}
.radio_button input {
  display: none;
}

.radio_button label input:checked ~ span {
  background-color: #74d1cd;
  border-color: #74d1cd;
}
.moser_head h3 {
  color: #181818;
  font-family: Gotham Medium;
  font-size: 18px;
  font-style: normal;
  font-weight: 350;
  line-height: 18px;
  margin-bottom: 5px;
  text-transform: uppercase;
  text-align: left;
}
.market_value {
  display: flex;
  justify-content: space-between;
  border-top: 2px solid #f5f5f5;
  border-bottom: 2px solid #f5f5f5;
  padding: 2px 0;
  margin-bottom: 5px;
}
.market_value h5 {
  color: #000;
  font-family: Gotham regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 325;
  line-height: 14px;
  margin-bottom: 0;
  padding-top: 7px;
}
.market_value span {
  font-family: 'Gotham regular';
  font-style: normal;
  font-size: 11px;
  line-height: 11px;
  text-align: center;
  text-decoration-line: underline;
  color: #55aaa5;
  cursor: pointer;
  font-weight: 600;
}
.market_price,
.market_price_drop {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #f5f5f5;
  padding-bottom: 5px;
  margin-bottom: 5px;
  position: relative;
}
.market_price span {
  width: auto;
  height: 25px;
  background: #3bbb7c;
  border-radius: 5px;
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  position: relative;
  padding: 8px 12px;
  margin-left: 30px;
  display: inline-block;
}
.market_price_drop span {
  width: auto;
  height: 25px;
  background: #f70202;
  border-radius: 5px;
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  position: relative;
  padding: 8px 12px;
  margin-left: 30px;
  display: inline-block;
}
.detal_accordion .MuiPaper-elevation1:last-child {
  border-bottom: 1px solid #ccc !important;
  border-radius: 0 !important;
}
.detal_accordion .MuiPaper-elevation1:last-child .MuiAccordionDetails-root p {
  padding-bottom: 15px;
}
.market_price span::before {
  content: '';
  position: absolute;
  top: 30%;
  left: -15px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 0 solid #3bbb7c;
  border-bottom: 8px solid #3bbb7c;
  clear: both;
}
.market_price_drop span::before {
  content: '';
  position: absolute;
  left: -20px;
  top: 40%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 7px solid #ef070b;
  border-bottom: 0px solid #f70202;
}
.market_price p,
.market_price_drop p {
  color: #181818;
  font-family: Gotham Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-right: 18px;
  padding-top: 3px;
}
.market_price p .circle,
.market_price_drop p .circle {
  width: 10px;
  height: 10px;
  background-color: #3bbb7c;
  border-radius: 100px;
  display: block;
  position: absolute;
  right: -18px;
  top: 7px;
}
.market_ofer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.market_ofer p {
  font-family: 'Gotham light';
  font-size: 12px;
  line-height: 15px;
  color: #a19393;
  text-transform: uppercase;
}
.market_price h3,
.market_price_drop h3 {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 16px;
}
.moser_head thead {
  /* background: linear-gradient(90deg, #d1d1d1 0%, #bebebe 100%) !important; */
  background-color: #d1d1d1;
}
.vertical_top :focus-visible {
  outline: none;
}
.data_real {
  /* background: linear-gradient(90deg, #d1d1d1 0%, #bebebe 100%); */
  background-color: #d1d1d1;
  padding: 8px 15px;
  font-size: 12px;
  line-height: 13px;
  color: #fff;
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: 700;
  margin: 40px 0;
  text-align: left;
  margin-bottom: 15px;
}
.my_bid_lower {
  padding: 5px 0;
  font-family: 'Gotham light';
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  text-decoration-line: underline;
  color: #33ada8;
  margin-bottom: 0;
  margin-top: 20px;
}

.radio_button label span {
  font-family: Gotham regular;
  position: relative;
  display: inline-block;
  padding: 10px 80px;
  font-weight: 325;
  font-size: 16px;
  line-height: 18px;
  color: #41b979;
  border: 1px solid #bdbdbd;
  border-radius: 10px;
  background: #fff;
  text-align: center;
  scroll-behavior: inherit;
  border: 1px solid #41b979;
  cursor: pointer;
  height: 40px;
}
.radio_button label span:hover {
  background-color: #41b979;
  color: #fff;
}
.radio_button label input:checked ~ span {
  background-color: #41b979;
  color: #fff;
}
.row.radio_button {
  padding-left: 15px;
  padding-right: 5px;
}
.radio_button .col-md-4 {
  padding-left: 0;
  margin-bottom: 3px;
  padding-right: 10px;
}
.submit_offer_div {
  cursor: pointer;
}
.submit_offer_div button {
  background: #000000;
  border-radius: 8px;
  width: 100%;
  font-family: 'Gotham light';
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  text-transform: uppercase;
  color: #ffffff !important;
  height: 40px;
  margin-bottom: 40px;
  margin-top: 0;
}
.form-group.make_end_offer {
  margin-bottom: 10px;
}
.submit_offer_div button:hover {
  background: #000000;
}
#about_watch {
  font-family: 'Gotham Medium';
  font-size: 14px;
  line-height: 13px;
  text-transform: uppercase;
  color: #000000;
  border-bottom: 1px solid #ccc;
  margin-bottom: 0;
  padding-bottom: 10px;
  margin-top: 30px;
}
.detal_accordion .MuiPaper-elevation {
  box-shadow: none !important;
  margin-top: 0 !important;
}
.detal_accordion .MuiAccordionSummary-root {
  padding: 0 !important;
  /* min-height: 30px !important; */
}
.MuiAccordion-gutters::before {
  opacity: inherit !important;
}
.MuiAccordionSummary-contentGutters {
  margin-bottom: 10px !important;
}
.detal_accordion p {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 350;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #000000;
}
.MuiAccordionDetails-root {
  padding: 0 !important;
}
.MuiAccordionDetails-root p {
  font-size: 11px;
  color: #727070;
  font-family: Gotham regular;
  text-align: left;
  text-transform: capitalize;
}
.payment_icons {
  display: flex;
  margin: 25px 0;
}

.payment_icons .text1 {
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 11px;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 5px;
  text-align: left;
}
.payment_icons .text2 {
  font-family: 'Gotham regular';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 10px;
  color: hsla(0, 0%, 49.4%, 0.7);
  text-align: left;
}

.payment_icons img {
  margin-right: 20px;
}
.like_view {
  margin-bottom: 40px;
  margin-top: 40px;
}
.moser_head th {
  white-space: nowrap;
  font-size: 12px;
}
.moser_head tr {
  border-bottom: 1px solid #ccc;
}
.moser_head tbody tr {
  font-size: 10px;
}

.vertical_top .slick-next:before {
  content: '→';
  background-image: url('../../assets/images/icons/slideArrow.svg');
  background-repeat: no-repeat;
  color: transparent;
  font-size: 40px;
  color: transparent !important;
  background-size: 30%;
}
.vertical_top .slick-prev:before {
  content: '←';
  background-image: url('../../assets/images/icons/slideArrow2.svg');
  background-repeat: no-repeat;
  font-size: 40px;
  color: transparent !important;
  background-size: 30%;
}

.moser_head table {
  border-collapse: collapse;
}
.make_end_offer input {
  background: #ffffff;
  border: 1px solid #43b97c;
  border-radius: 0px 8px 8px 0px;
  height: 40px;
  width: 70%;
  float: right;
}
/* .moser_head tbody th,
.moser_head tbody td {
  font-size: 10px !important;
} */
.enter_amount {
  padding: 5px 13px;
  background: #43b97c;
  border-radius: 8px 0px 0px 8px;
  height: 40px;
  font-family: Gotham Medium;
  font-style: normal;
  font-weight: 350;
  font-size: 13px;
  line-height: 17px;
  /* text-align: right; */
  text-transform: uppercase;
  color: #ffffff;
  position: relative;
  width: 30%;
  text-align: left;
}

span.dollar {
  position: absolute;
  right: 10px;
  top: 12px;
  font-size: 16px;
  line-height: 19px;
}
.usd_span {
  font-size: 12px;
}

.border-bottom-div1 {
  border-bottom: 1px solid #d1d1d1;
  position: relative;
  top: -10px;
}
.detal_accordion {
  margin-bottom: 35px;
}
.the_detail .MuiTypography-body1 {
  font-family: Gotham medium;
  font-weight: 325;
  font-size: 12px;
  line-height: 20px;
  color: #4f4f4f;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 0;
  overflow: hidden;
  vertical-align: top;
  padding-bottom: 0 !important;
}
.the_detail p {
  font-family: Gotham regular;
  font-weight: 325;
  font-size: 11px;
  line-height: 15px;
  color: #828282;
  width: 100%;
  display: block;
  word-break: break-all;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
}

.marg_top_20_show_mob .slick-next {
  right: -14px;
  z-index: 9;
  width: 30px;
  height: 30px;
}
.marg_top_20_show_mob .slick-prev {
  left: 0;
  z-index: 9;
  width: 30px;
  height: 30px;
}

.marg_top_20_show_mob .slick-next:before {
  background: url('../../assets/images/icons/right_arrow.svg');
  background-repeat: no-repeat;
  color: transparent;
  opacity: 1;
  font-size: 30px;
}

.marg_top_20_show_mob .slick-prev:before {
  background: url('../../assets/images/icons/left_arrow.svg');
  background-repeat: no-repeat;
  color: transparent;
  opacity: 1;
  font-size: 30px;
}
.marg_top_20_show_mob img {
  border-radius: 5px;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1440px) {
  .make_end_offer input {
    width: 68%;
  }
  .enter_amount {
    width: 32%;
  }
}
@media only screen and (max-width: 1280px) {
  .make_end_offer input {
    width: 66%;
  }
  .enter_amount {
    width: 34%;
  }
}
@media only screen and (max-width: 1024px) {
  .make_end_offer input {
    width: 63%;
  }
  .enter_amount {
    width: 37%;
  }
}
@media only screen and (max-width: 991px) {


  .market_price span {
    margin-top: 5px;
    left: -10px;
  }
  .make_end_offer input {
    width: 50%;
  }
  .enter_amount {
    width: 50%;
  }
  .my_bid_lower {
    font-size: 10px;
    line-height: 14px;
  }
  /* .radio_button label span {
    padding-left: 20% !important;
  } */
}
@media only screen and (max-width: 767px) {
  /* .radio_button label span {
    padding-left: 30% !important;
  } */
  .mob_pad_none {
    padding: 0;
  }
  span.dollar {
    font-size: 14px;
    line-height: 17px;
  }
  .market_price p {
    padding-top: 8px;
  }
  .market_price span::before {
    left: -20px;
  }
  .market_price p .circle {
    top: 8px;
  }
  .moser_head {
    border: none;
    /* margin-top: 20px; */
  }
  .make_end_offer input {
    width: 70%;
  }
  .enter_amount {
    width: 30%;
    padding-top: 10px;
  }
  .market_price span {
    margin-top: 0;
    margin-left: 40px;
    font-size: 12px;
    line-height: 11px;
    left: 10px;
  }
  /* .moser_head h3 {
    margin-top: 20px;
  } */
  .market_value h5 {
    font-size: 10px;
    line-height: 10px;
    font-weight: 600;
    line-height: 14px;
    margin-bottom: 0;
    padding-top: 3px;
  }
  .market_value span {
    font-size: 10px;
    line-height: 10px;
  }
  .market_price p {
    font-size: 14px;
    line-height: 13px;
  }
  .market_ofer p {
    font-size: 9.79496px;
    line-height: 12px;
  }
  .moser_head th {
    font-size: 10px;
    line-height: 10px;
  }
  .moser_head th {
    font-size: 11px !important;
    line-height: 10px !important;
  }
  .moser_head td {
    font-size: 11px !important;
    line-height: 13px !important;
  }
  .data_real {
    padding: 10px 15px;
    font-size: 10px;
    line-height: 10px;
    margin: 40px 0;
    text-align: left;
    margin-bottom: 15px;
  }
  .my_bid_lower {
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 0;
    text-align: left;
    margin-top: 30px;
  }
  .enter_amount p {
    font-size: 12px;
    line-height: 12px;
  }
  .enter_amount .usd_span {
    font-size: 10px;
    line-height: 10px;
    margin-top: 4px;
    display: block;
  }
  .submit_offer_div button {
    margin-bottom: 40px;
    margin-top: 0px;
  }

  #about_watch {
    margin-top: 0;
  }

  .vertical_top .slick-dots {
    padding: 0 0px;
  }
}
@media only screen and (max-width: 575px) {
 

}
@media only screen and (max-width: 470px) {
  .enter_amount {
    width: 35%;
  }
  .make_end_offer input {
    width: 65%;
  }
  /* .radio_button label span {
    padding-left: 25% !important;
  } */
}
@media only screen and (max-width: 425px) {
  .make_end_offer input {
    width: 60%;
  }
  .enter_amount {
    width: 40%;
  }

}
@media only screen and (max-width: 375px) {
  /* .radio_button label span {
    padding-left: 21% !important;
  } */
  .market_price span {
    left: 0px;
  }
  .make_end_offer input {
    width: 56%;
  }
  .enter_amount {
    width: 44%;
  }

}
@media only screen and (max-width: 320px) {
 
}
