.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.form-control:focus {
  box-shadow: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* @media screen and (min-width: 1441px) {
  .container {
    max-width: 1050px !important;
    padding: 0;
    text-align: left;
  }
}
@media screen and (min-width: 1600px) {
  .container {
    max-width: 1290px !important;
    padding: 0;
    text-align: left;
  }
} */
@media screen and (min-width: 1150px) {
  /* .container {
    max-width: 900px;
    padding: 0;
    text-align: left;
  } */
  .banner_landing .container {
    max-width: 1140px;
  }
  .brand_img {
    max-width: 100%;
    padding: 10px;
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1439px) {
  .container {
    margin: 0 auto;
    padding: 0 32.5px;
    max-width: 1040px !important;
    text-align: left;
  }
}

@media screen and (min-width: 1500px) {
  .container {
    margin: 0 auto;
    padding: 0 32.5px;
    max-width: 1280px !important;
    text-align: left;
  }
}
