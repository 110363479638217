.cropped_img img {
  width: 148px;
  height: 148px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.cropped_img_profile img {
  width: 148px;
  height: 148px;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.preview-container {
  display: flex;
  padding: 5px 15px;
}
.image-preview {
  font-family: 'Gotham Medium';
  padding: 10px 0px 5px 15px;
}
