.login_banner {
  background-color: #000;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  padding: 50px;
}
.send-OTP {
  width: 110px !important;
  height: 42px !important;
  left: auto !important;
  border-radius: 5px !important;
  background: #000 !important;
  border: none;
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  position: absolute !important;
  right: 0 !important;
  top: 30px !important;
  padding: 0px 3px !important;
}
.otp-verify-sucess {
  width: 105px !important;
  height: 42px !important;
  left: auto !important;
  border-radius: 5px !important;
  background: #41ba77 !important;
  border: none;
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  position: absolute !important;
  right: 0 !important;
  top: 30px !important;
  padding: 0px !important;
}
.send-OTP[disabled] {
  background: #ccc !important;
  cursor: not-allowed !important;
  color: #ffffff !important;
}
.login_form .login-btn[disabled] {
  background: #ccc !important;
  cursor: not-allowed !important;
  color: #ffffff !important;
}
.login_banner h1 {
  font-family: Gotham Bold;
  font-size: 36px;
  line-height: 43px;
  color: #fff;
}
.login_form h2 {
  font-family: Gotham Medium;
  font-size: 36px;
  line-height: 43px;
  color: #111;
  font-weight: 500;
  margin-bottom: 45px;
}
section.login_form input {
  border: 1px solid #d6d6d6;
  height: 43px;
  border-radius: 6px;
  background-color: #fff !important;
}
.login_form .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ccc;
  outline: 0 !important;
  box-shadow: none !important;
}
.login_banner p {
  font-family: Gotham light;
  font-size: 24px;
  line-height: 29px;
  color: #fff;
}
section.login_form {
  margin: 50px 0;
  margin-bottom: 100px;
}
.login_form form {
  text-align: left;
}
.login_form a {
  font-weight: 400;
  font-size: 16px;
  line-height: 50px;
  color: #000;
  text-decoration: underline;
}
.login_form .login-btn {
  background: #000 !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  text-align: center !important;
  color: #fff !important;
  padding: 13px 50px !important;
  border: 1px solid transparent !important;
  width: 100% !important;
  font-family: Gotham Medium !important;
  height: 50px !important;
}
.login_form .login-btn:hover {
  background: #000 !important;
}
.login_form .form-group label {
  font-family: Gotham light;
  font-size: 14px;
  line-height: 17px;
  /* text-transform: uppercase; */
  color: #111;
}
@media screen and (max-width: 1024px) {
  .login_banner {
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .login_banner h1 {
    font-size: 36px;
    line-height: 43px;
  }
  .login_banner p {
    font-size: 18px;
    line-height: 23px;
  }
  .login_form h2 {
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 499px) {
  .login_banner h1 {
    font-size: 30px;
    line-height: 36px;
  }
  .login_banner p {
    font-size: 16px;
    line-height: 21px;
  }
  .login_banner {
    padding: 30px;
  }
  .login_form h2 {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 375px) {
  .login_banner h1 {
    font-size: 26px;
    line-height: 30px;
  }
  .login_banner p {
    font-size: 14px;
    line-height: 19px;
  }
}
@media screen and (max-width: 320px) {
  .login_banner h1 {
    font-size: 22px;
    line-height: 25px;
  }
  .login_banner p {
    font-size: 12px;
    line-height: 17px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
}
